import React, { useMemo, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
import "./style.scss";

const TextEditor = () => {
  var Font = Quill.import('formats/font');
  Font.whitelist = ['mirza', 'roboto'];
  Quill.register(Font, true);
  const [content, setContent] = useState();

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ "font": Font.whitelist }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'color': [] }],
        [{ 'background': [] }],
        ["link", "image"]
      ],
      clipboard: {
        matchVisual: false
      }
    }
  }), [])


  const handleChange = (value) => {
    setContent(value);
  };

  const formats = [
    'header', 'font', 'background', 'color', 'code',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent', 'script', 'align', 'direction',
    'link', 'image'
  ]
  return (
    <div className="text-editor">
      {/* <CustomToolbar /> */}
      <ReactQuill
        theme="snow"
        value={content}
        onChange={handleChange}
        placeholder={"Enter Something..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};
export default TextEditor;
