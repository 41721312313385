import styled from "@emotion/styled";

export const TimeEstimateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: 100%;
`;

export const TimeTakenContainer = styled.div`
  font-size: 12px;
  margin-left: 5px;
`;
