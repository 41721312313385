import React, { useEffect, useState } from "react";
import "./style.scss";
import { titleFromParams } from "../../Utilities/helperFunction";
import Paper from "@material-ui/core/Paper";
import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import appContext from "../../appContext";
import currencyToSymbolMap from "currency-symbol-map/map";
import { CustomPaper } from "../../Utilities/Constant";

function CurrencyInput({
  mock,
  index,
  setMaintainValues,
  maintainValues,
  setValue,
  initialValues,
  setScrollDisable,
  choosencurrency,
  setChoosenCurrency,
}) {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const { setCustomStyles } = useContext(appContext);

  const handleNumber = (e, i, name) => {
    const reg = new RegExp("^(?!.*\\..*\\.)\\d*\\.?\\d*$");
    if (reg.test(e.target.value)) {
      setValue(initialValues[index - 1][i], e.target.value);
      setMaintainValues({
        ...maintainValues,
        [`${name}`]: `${e.target.value} ${choosencurrency}`,
      });
    } else {
      e.preventDefault();
    }
  };

  const getValues = () => {
    if (currencyOptions?.length) {
      return currencyOptions.find(
        (currency) => currency.value === choosencurrency
      );
    } else {
      return { label: "EUR", value: "EUR" };
    }
  };

  useEffect(() => {
    try {
      if (Object.entries(currencyToSymbolMap || {})?.length) {
        setCurrencyOptions(
          Object.entries(currencyToSymbolMap || {})?.map((currencyVal) => {
            return {
              label: `${currencyVal[0]} - ${currencyVal[1]}`,
              value: currencyVal[0],
            };
          })
        );
      }
    } catch (e) {
      setCurrencyOptions([{ label: "EUR", value: "EUR" }]);
      console.error(e.message || e);
    }
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div onClick={() => index} className={"input-container"}>
      <div className="input-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div
            dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }}
          />
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      {mock?.options?.map((moc, i) => {
        let name = "";
        if (
          initialValues &&
          initialValues[index - 1] &&
          initialValues[index - 1]?.length
        ) {
          name = initialValues[index - 1];
        }
        return (
          <div key={name + i} className="input-currency">
            <TextField
              id={name}
              className="cost-amount"
              label={moc?.label}
              placeholder={moc?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              value={maintainValues[name]?.split(" ")[0] ?? ""}
              name={name}
              onChange={(e) => handleNumber(e, i, name)}
              variant="standard"
            />
            <Autocomplete
              className="currency-dropdown"
              options={currencyOptions}
              getOptionLabel={(option) => option?.label || ""}
              value={getValues()}
              onChange={(event, newValue) => {
                setChoosenCurrency(newValue?.value);
                if (maintainValues[name]?.split(" ")[0]) {
                  setMaintainValues({
                    ...maintainValues,
                    [`${name}`]: `${maintainValues[name]?.split(" ")[0]} ${
                      newValue?.value
                    }`,
                  });
                }
                setScrollDisable(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Currency"
                  variant="standard"
                />
              )}
              getOptionSelected={(option, value) => {
                return option.label === value.label;
              }}
              PaperComponent={CustomPaper}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onFocus={() => setScrollDisable(true)}
              onBlur={() => setScrollDisable(false)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default CurrencyInput;
