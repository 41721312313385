import React, { useContext, useEffect, useState } from "react";
import appContext from "../../appContext";
import { getVariables } from "../../Utilities/helperFunction";
import "./style.scss";

const CalculativeFields = ({
  mock,
  index,
  initialValues,
  maintainValues,
  setMaintainValues,
}) => {
  const { setCustomStyles } = useContext(appContext);
  const [title, setTitle] = useState(mock.title);
  const [variables, setVariables] = useState([]);

  let name = "";
  if (initialValues && initialValues[index - 1]) {
    name = initialValues[index - 1];
  }

  const getvariableValue = (variable) => {
    const values = document.querySelector(`.${variable}`)?.value;
    return values ?? 0;
  };

  const handleCalculate = () => {
    let evalAnswer = {};
    let evaluation = mock.calculationRules || "";
    variables?.forEach((variable) => {
      if (variable !== "result") {
        evalAnswer[variable] = getvariableValue(variable);
        evaluation = evaluation?.replaceAll(
          `[@${variable}]`,
          evalAnswer[variable]
        );
      }
    });
    evaluation = evaluation.replaceAll(" ", "");
    let result = 0;
    if (document.getElementById("final-result")) {
      result = eval(evaluation);
      document.getElementById("final-result").innerHTML = result;
    }
    evalAnswer["result"] = result;
    evalAnswer["arithmetic_operation"] = evaluation;
    setMaintainValues({ ...maintainValues, [name]: evalAnswer });
  };

  const handleTitle = (title) => {
    const variableValue = getVariables(title);
    setVariables(variableValue);
    let newTitle = title;
    variableValue?.forEach((variable) => {
      if (variable !== "result") {
        newTitle = newTitle?.replaceAll(
          `[@${variable}]`,
          `<input type="number" class="variable ${variable}" placeholder="Type here..." name=${variable} />`
        );
      } else {
        newTitle = newTitle?.replaceAll(
          `[@result]`,
          `<span id="final-result">____</span>`
        );
      }
    });
    setTitle(newTitle);
  };

  useEffect(() => {
    handleTitle(title);
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="calculative-container" onClick={() => index}>
      <div className="calculative-title">
        <div className="title">
          <span className="questionNumber-container">
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div className="inner-html-container" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
      <div className="calculative-btn-container">
        <button type="button" onClick={handleCalculate}>
          Calculate
        </button>
      </div>
    </div>
  );
};

export default CalculativeFields;
