import React, { useEffect, useState, useContext } from "react";
import "./style.scss";
import { TextField } from "@material-ui/core";
import { getCustomDropownData } from "../../API/api";
import appContext from "../../appContext";

const SearchDropdown = ({
  options,
  onMouseEnter,
  onMouseLeave,
  onChange,
  defaultValue,
  mock,
  name,
}) => {
  const { setDropdownData, dropdownData } = useContext(appContext);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [updatedOptions, setUpdatedOptions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (value.length) {
      if (
        mock.dropdownApiData &&
        mock.dropdownApiData.isAdvancedSearch &&
        mock.dropdownApiData.searchKey
      ) {
        if (mock.dropdownApiData.searchAfterLength <= value.length) {
          const apiValues = { ...mock.dropdownApiData };
          apiValues["dropdown_endPoint"] += `${
            apiValues["dropdown_endPoint"].includes("?") ? "&" : "?"
          }${apiValues.searchKey}=${value}`;
          const newTimeoutId = setTimeout(
            () => debounceApiCall(apiValues),
            300 // Adjust the debounce delay as needed
          );
          setTimeoutId(newTimeoutId);
        } else {
          setFilteredOptions([]);
        }
      } else {
        if (value.length < 3) {
          // Filter options based on the input value
          setFilteredOptions(
            updatedOptions.filter((option) =>
              option.toLowerCase().startsWith(value.toLowerCase())
            )
          );
        } else {
          setFilteredOptions(
            updatedOptions.filter((option) =>
              option.toLowerCase().includes(value.toLowerCase())
            )
          );
        }
      }
    } else {
      setFilteredOptions([]);
    }
  };

  const debounceApiCall = async (apiValues) => {
    try {
      setLoading(true);
      const dropdownRes = await getCustomDropownData(apiValues);
      if (dropdownRes?.data?.dropdownData?.length) {
        setFilteredOptions(
          dropdownRes?.data?.dropdownData.map((data) => data?.label)
        );
        const cloneDropdown = { ...dropdownData };
        cloneDropdown[name] = dropdownRes?.data?.dropdownData;
        setDropdownData({ ...cloneDropdown });
      } else {
        setFilteredOptions([]);
      }
    } catch (e) {
      console.error("error in dropdown api", e);
    } finally {
      setLoading(false);
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    onChange(option);
    setFilteredOptions([]);
  };

  useEffect(() => {
    if (options?.length) {
      setUpdatedOptions(options.map((option) => option?.label));
    }
  }, [options]);

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, []);

  return (
    <div
      className="auto-complete-container"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TextField
        id="standard-basic"
        placeholder={`Type ${
          mock?.dropdownApiData?.searchAfterLength
            ? `min ${mock?.dropdownApiData?.searchAfterLength} char to start `
            : ""
        }to search...`}
        variant="standard"
        value={inputValue}
        onChange={handleInputChange}
      />
      {loading && <span class="loader"></span>}
      <div className="menu-items-container">
        <ul>
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchDropdown;
