import React, { useContext, useEffect } from "react";
import twitterButton from "../../Assets/twitterButton.svg";
import twitterIcon from "../../Assets/twitterIcon.svg";
import waymoreLinkedinIcon from "../../Assets/waymoreLinkedinIcon.svg";
import appContext from "../../appContext";
import { getTwitterOauthToken } from "../../API/api";
import { titleFromParams } from "../../Utilities/helperFunction";
import "./style.scss";

const TwitterLogin = ({
  mock,
  index,
  setMaintainValues,
  maintainValues,
  setValue,
}) => {
  const { twitterName, setCustomStyles, twitterDetails } =
    useContext(appContext);

  const handleTwitterLogin = () => {
    // Initialize the Twitter SDK
    getTwitterOauthToken(window.location.origin).then((res) => {
      if (res?.data?.data?.oauthToken) {
        const renderUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${res?.data?.data?.oauthToken}`;
        window.open(renderUrl, "_blank");
      }
    });
  };

  useEffect(() => {
    const twitterData = sessionStorage.getItem("twitterDetails");
    if (twitterData) {
      const twitterInfo = {
        ...JSON.parse(twitterData || "{}"),
        ...twitterDetails,
      };
      setValue(mock?.name, twitterInfo);
      setMaintainValues({
        ...maintainValues,
        [`${mock?.name}`]: twitterInfo,
      });
    }
  }, [twitterDetails]);

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="twitter-container">
      <div className="twitter-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div
            dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }}
          />
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      {twitterName ? (
        <div className="twitter-connected" onClick={handleTwitterLogin}>
          <img className="twitter-icon" src={twitterIcon} />
          <div>
            Connected as : <div>{twitterName}</div>
          </div>
          <img className="waymoreTwitter-Icon" src={waymoreLinkedinIcon} />
        </div>
      ) : (
        <img
          className="twitter-img"
          src={twitterButton}
          onClick={handleTwitterLogin}
        />
      )}
    </div>
  );
};

export default TwitterLogin;
