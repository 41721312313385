import React from "react";

const appState = {
    appointmentIdDetails: {},
    setAppointmentIdDetails: (appointmentIdDetails) => { },
    frontPageContent: {},
    setFrontPageContent: (frontPageContent) => { },
    loading: false,
    setLoading: (loading) => { },
    allContentData: {},
    setAllContentData: (allContentData) => { },
    quotation: {},
    setQuotation: (quotation) => { },
    validId: true,
    setValidId: () => { },
    landingPage: null,
    setLandingPage: () => { },
    costFormatArr: [],
    setCostFormatArr: () => { },
    finalQuotationCost: 0,
    setFinalQuotationCost: () => { },
    totalQuotationAmount: 0,
    setTotalQuotationAmount: () => { },
    currency: 'EUR',
    setCurrency: () => { },
    finalContent: "",
    setFinalContent: () => { },
    linkedInName: "",
    setLinkedInName: () => { },
    customStyles: {},
    setCustomStyles: () => { },
    otherOption: {},
    setOtherOption: () => { },
    dropdownData: {},
    setDropdownData: () => { },
    previewFormData: {},
    setPreviewFormData: () => { },
    previewMode: false,
    setPreviewMode: () => { },
    dependedHistory: {},
    setDependedHistory: () => { },
    twitterName: "",
    setTwitterName: () => { },
    twitterDetails: "",
    setTwitterDetails: () => { },
    facebookDetails: {},
    setFacebookDetails: () => { },
    googleDetails: {},
    setGoogleDetails: () => { },
    tiktokDetails: {},
    setTiktokDetails: () => { },
    openprojectDetails: {},
    setOpenprojectDetails: () => { }

}

export const appContext = React.createContext(appState);


export default appContext;