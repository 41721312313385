import React, { useContext, useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

import { titleFromParams } from "../../Utilities/helperFunction";
import appContext from "../../appContext";

import {
  CustomPaper,
  DayLightTimezone,
  StandardTimeZone,
} from "../../Utilities/Constant";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function TimeZone({
  setScrollDisable,
  mock,
  index,
  initialValues,
  maintainValues,
  setMaintainValues,
  setDaylightSavings,
  isdaylightSavings,
}) {
  const { setCustomStyles } = useContext(appContext);
  const [options, setOptions] = useState([]);

  let name = "";
  if (initialValues && initialValues[index - 1]) {
    name = initialValues[index - 1];
  }

  const getDefaultValue = () => {
    const timeZoneList = isdaylightSavings[name]
      ? DayLightTimezone
      : StandardTimeZone;
    if (maintainValues[name]) {
      return timeZoneList.find(
        (timezone) => timezone.label === maintainValues[name]
      );
    } else {
      const currentTimeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta"
          ? "Asia/Kolkata"
          : Intl.DateTimeFormat().resolvedOptions().timeZone;

      const timezone = timeZoneList.find((timezone) =>
        timezone.label.includes(currentTimeZone)
      );
      setMaintainValues({
        ...maintainValues,
        [`${name}`]: timezone.label,
      });
      return timezone;
    }
  };

  useEffect(() => {
    setOptions(
      isdaylightSavings[name] ? [...DayLightTimezone] : [...StandardTimeZone]
    );
    if (maintainValues[name]) {
      const timeZoneList = isdaylightSavings[name]
        ? DayLightTimezone
        : StandardTimeZone;
      const timezoneKey = maintainValues[name]?.split(" ")?.[1];
      setMaintainValues({
        ...maintainValues,
        [`${name}`]: timeZoneList?.find((timezone) =>
          timezone.label.includes(timezoneKey)
        )?.label,
      });
    }
  }, [isdaylightSavings[name]]);

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="dropdown-container">
      <div className="dropdown-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div
            dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }}
          />
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={isdaylightSavings[name]}
            onChange={(e) =>
              setDaylightSavings((prev) => ({
                ...prev,
                [name]: e.target.checked,
              }))
            }
          />
        }
        label="Day Light Savings"
      />
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option?.label || ""}
        onChange={(event, newValue) => {
          setMaintainValues({
            ...maintainValues,
            [`${name}`]: newValue?.label,
          });
          setScrollDisable(false);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Type or select an option"
            variant="standard"
          />
        )}
        defaultValue={getDefaultValue}
        value={
          options.find((option) => option.label === maintainValues[name]) ?? {}
        }
        getOptionSelected={(option, value) => {
          return option.label === value.label;
        }}
        PaperComponent={CustomPaper}
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        onFocus={() => setScrollDisable(true)}
        onBlur={() => setScrollDisable(false)}
      />
    </div>
  );
}

export default TimeZone;
