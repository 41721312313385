import React, { useContext, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Alert from '@mui/material/Alert';
import './style.scss'
import TextEditor from '../TextEditor';
import TextareaComponent from '../TextArea/TextArea';
import { debounce, paramsToObject, titleFromParams } from '../../Utilities/helperFunction';
import { uploadFile } from '../../API/api';
import appContext from '../../appContext';
import Fileupload from '../Fileupload';



function Input({ setScrollDisable, setExpertText, isValidEmail, mock, index, rightIcon, setValue, initialValues, maintainValues, setMaintainValues, isValidUrl, category, fileNames, setFileNames, setIsLoading }) {
  const [valid, setValid] = useState(true);
  const [validDecimal, setValidDecimal] = useState(true);
  const { setCustomStyles } = useContext(appContext)

  const debouncedChange = debounce((isDecimal) => {
    if (isDecimal) {
      setValidDecimal(false)
    } else {
      setValid(false);
    }
    setTimeout(() => { isDecimal ? setValidDecimal(true) : setValid(true) }, 3000)
  }, 200);

  const handleNumber = (e, i, name, isDecimal, decimalNumber) => {
    let reg = ""
    if (isDecimal) {
      reg = new RegExp(`^\\d*\\.?\\d{0,${decimalNumber || 2}}$`);
    } else {
      reg = new RegExp("^[0-9]*$");
    }
    if (reg.test(e.target.value)) {
      setValue(initialValues[index - 1][i], e.target.value)
      setMaintainValues({ ...maintainValues, [`${name}`]: e.target.value })
    } else {
      if (isDecimal && !isNaN(e.target.value)) {
        debouncedChange(true)
      } else {
        debouncedChange(false)
      }
      e.preventDefault()
    }
  }


  const handleFile = async (acceptedFiles, i, name) => {
    if (acceptedFiles?.length) {
      let formData = new FormData();
      setValue(name, undefined);
      const names = [];
      if (!Array.isArray(fileNames[index - 1])) {
        fileNames[index - 1] = []
      }
      acceptedFiles.forEach((x) => {
        names?.push(x?.name);
        formData.append('file', x);
        fileNames[index - 1][i] = [...names];
        setFileNames([...fileNames])
      })
      setIsLoading(true)
      const fileRes = await uploadFile(formData, atob(sessionStorage?.formUUID), sessionStorage?.principalId)
      if (fileRes?.url && fileRes?.url?.length) {
        setValue(name, fileRes.url);
        maintainValues[`${name}`] = fileRes.url;
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
        fileNames[index - 1][i] = [];
      }
    }
  }


  useEffect(() => {
    setCustomStyles(mock?.styles || {})
    const inputVal = paramsToObject()
    if (inputVal) {
      mock.options.map((x) => {
        if (inputVal[x?.name] && !maintainValues[x?.name]) {
          maintainValues[x?.name] = inputVal[x?.name];
        }
      })
      setMaintainValues({ ...maintainValues })
    }
    const linkedDetails = sessionStorage.getItem('linkedinDetails')
    const linkedinData = JSON.parse(linkedDetails || null);
    const maintainValuesCopy = { ...maintainValues }
    if (!maintainValues["submitter_email"] && linkedinData?.email) {
      setValue("submitter_email", linkedinData.email);
      maintainValuesCopy[`submitter_email`] = linkedinData.email
    }
    if (!maintainValues["submitter_firstName"] && linkedinData?.localizedFirstName) {
      setValue("submitter_firstName", linkedinData.localizedFirstName);
      maintainValuesCopy[`submitter_firstName`] = linkedinData.localizedFirstName
    }
    if (!maintainValues["submitter_lastName"] && linkedinData?.localizedLastName) {
      setValue("submitter_lastName", linkedinData.localizedLastName);
      maintainValuesCopy[`submitter_lastName`] = linkedinData.localizedLastName
    }
    setMaintainValues({ ...maintainValuesCopy })
  }, [])


  useEffect(() => {
    if (category) {
      let text = ""
      category?.map((cato, index) => {
        let subText = cato?.name?.trim()
        subText = subText[0] === "/" ? subText?.replace("/", "") : subText
        subText = index < category?.length - 1 ? (subText + ", ") : (subText)
        text += subText
      })
      setExpertText(text)
    }
  }, [category])

  return (
    <div onClick={() => (index)} className={(!valid || !isValidUrl || !validDecimal) ? "alert-animation" : 'input-container'}>
      <div className='input-title'>
        <h1 className='title'><span>{index}
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
            <path
              d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /></h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      {
        mock.options.map((moc, i) => {
          let name = ''
          if (initialValues && initialValues[index - 1] && initialValues[index - 1]?.length) {
            name = initialValues[index - 1][i]
          }
          return <div key={name + i} className='input'>
            {['number', 'decimal'].includes(moc?.type) ? <TextField
              id={name}
              label={moc?.label}
              placeholder={moc?.placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
              error={!valid}
              value={maintainValues[name] ?? ""}
              name={name}
              onChange={(e) => handleNumber(e, i, name, moc?.type === 'decimal', moc?.max_decimal)}
            />

              : moc.type === 'url' ?
                <TextField
                  id="standard"
                  label={moc?.label}
                  placeholder={moc?.placeholder}
                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name={name}
                  onChange={(e) => { setValue(name, e.target.value); setMaintainValues({ ...maintainValues, [`${name}`]: e?.target?.value }) }}
                  value={maintainValues[name] ?? ""}
                />
                : moc.type === "file" ?
                  <Fileupload fileNames={fileNames} handleFile={handleFile} name={name} i={i} index={index} />
                  : moc.type === 'textEditor' ?
                    <TextEditor
                      setExpertText={setExpertText}
                      isValidEmail={isValidEmail}
                      mock={mock}
                      index={index}
                      rightIcon={rightIcon}
                      setValue={setValue}
                      initialValues={initialValues}
                      maintainValues={maintainValues}
                      setMaintainValues={setMaintainValues}
                    /> :
                    moc.type === 'textArea' ?
                      <TextareaComponent
                        setScrollDisable={setScrollDisable}
                        name={name}
                        maintainValues={maintainValues}
                        setMaintainValues={setMaintainValues}
                        setValue={setValue}
                        placeholder={moc?.placeholder}
                      />
                      :
                      <TextField
                        id="standard"
                        label={moc?.label}
                        placeholder={moc?.placeholder}
                        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name={name}
                        onChange={(e) => { setValue(name, e?.target?.value); setMaintainValues({ ...maintainValues, [`${name}`]: e?.target?.value }) }}
                        value={maintainValues[name] ?? ""}
                      />

            }
          </div>
        })
      }
      <Alert className={isValidUrl ? 'alert' : 'alert warning'} severity="error">Looks like the URL is not valid</Alert>
      <Alert className={isValidEmail ? 'alert' : 'alert warning'} severity="error">Please enter valid email</Alert>
      <Alert className={valid ? 'alert' : 'alert warning'} severity="error">Number only please</Alert>
      <Alert className={validDecimal ? 'alert' : 'alert warning'} severity="error">Decimal digits more than this not allowed</Alert>
    </div>
  )
}

export default Input