import React, { useEffect, useState, useRef, useContext, memo } from 'react'
import appContext from '../../appContext'
import Input from '../../Components/Input'
import RadioButton from '../../Components/RadioButton'
import CheckBox from '../../Components/Checkbox'
import AutoComplete from '../../Components/AutoComplete'
import debounce from 'lodash.debounce'
import './style.scss'
import rightarrowIcon from '../../Assets/rightarrowIcon.svg'
import TickIcon from '../../Assets/tick.svg'
import { useForm } from "react-hook-form";
import Button from '../../Components/Button';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom'
import { getCountry, getCustomDropownData, getFormData, getFormDataSorted, getIndustryIdentifier, getInitialFormData, getOptionalFormData, getServicePrice, saveFormData, sendDataToEndPoint, uploadFile, validateEmail, validateNumber } from '../../API/api'
import { CircularProgress } from '@mui/material';
import CostFormat from '../../Components/CostFormat'
import { countries } from 'country-list-json';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import PhoneNumber from '../../Components/PhoneNumber'
import DateTimePicker from '../../Components/DateTimePicker'
import DropDown from '../../Components/DropDown'
import NextPage from '../NextPage'
import PaymentModal from '../PaymentModal'
import { inputFields, radioArr, skipRequired, thankyouPage } from '../../Utilities/Constant'
import { findNextLoopNumber, paramsToObject, titleFromParams, transformObjectToArray, updateDuplicateValues } from '../../Utilities/helperFunction';
import Backdrop from '@mui/material/Backdrop';
import AlertDialogSlide from '../../Components/PermissionDialog'
import MultiInput from '../../Components/MultiInput'
import { useInView } from "react-intersection-observer";
import AppointmentPopup from '../../Components/AppointmentPopup'
import Linkedin from '../../Components/Linkedin'
import CurrencyInput from '../../Components/CurrencyInput'
import CustomTimer from './CustomTimer'
import { WatermarkContainer } from '../../App'
import { useQuantity } from '../../appContext/QuantityContext'
import CustomTimePicker from '../../Components/CustomTimePicker'
import moment from 'moment'
import TimeZone from '../../Components/Timezone'
import dateformat from 'dateformat'
import CustomRating from '../../Components/CustomRating'
import TwitterLogin from '../../Components/Twitter'
import GoogleLogin from '../../Components/GoogleLogin'
import OpenProject from '../../Components/OpenProject'
import FacebookLogin from '../../Components/FacebookLogin'
import CalculativeFields from '../../Components/CalculativeFields'


function QuoteForm() {
    const { ref, inView } = useInView({
        threshold: 1,
        delay: 600
    });

    const navigate = useNavigate()
    const { getQuantity } = useQuantity()

    const { handleSubmit, setValue } = useForm();
    const { currency, setCurrency, finalContent, allContentData, dropdownData, setDropdownData, previewFormData, previewMode, deviceType } = useContext(appContext);
    const [isValidUrl, setValidUrl] = useState(true)
    const [isValidEmail, setValidEmail] = useState(true)
    const [country, setCountry] = useState("")
    const [serviceDetails, setServiceDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [initialValues, setInitialValues] = useState([])
    const [maintainValues, setMaintainValues] = useState({})
    const [fileNames, setFileNames] = useState([])
    const [realData, setRealData] = useState([])
    const [scrollCapture, setScrollCapture] = useState('down')
    const [pageIndex, setPageIndex] = useState(0)
    const [costFormScroll, setCostFormScroll] = useState(0)
    const [isDownScrolled, setIsDownScrolled] = useState(true)
    const [category, setCategory] = useState([])
    const [formData, setFormData] = useState([])
    const [countryArray, setCountryArray] = useState([])
    const [foundCountry, setFoundCountry] = useState("")
    const [expertText, setExpertText] = useState("")
    const [scrollDisable, setScrollDisable] = useState(false)
    const [isMarketingAgency, setMarketingAgency] = useState(false)
    const [nob2b, setnob2b] = useState(false)
    const [nob2c, setnob2c] = useState(false)
    const [recorder, setRecorder] = useState(null);
    const [loading, setLoading] = useState(false);
    const isRecordNeeded = allContentData?.recordVideo ?? false;
    const [permissionDialog, setPermissionDialog] = useState(false);
    const [apiBtnLoading, setApiBtnLoading] = useState(false);
    const [apiBtnDropLoading, setApiBtnDropLoading] = useState(false);
    const [isClickSent, setisClickSent] = useState(false);
    const [recorderState, setRecorderState] = useState('');
    const [choosencurrency, setChoosenCurrency] = useState("EUR");
    const [preValidatedEmail, setPreValidatedEmail] = useState([])
    const [isdaylightSavings, setDaylightSavings] = useState({})
    const videoRef = useRef(null);
    const [isSubmit, setIsSubmit] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(false)
    const isSubmitRef = useRef(false)
    isSubmitRef.current = isSubmit

    const recorderRef = useRef(recorder)
    recorderRef.current = recorder;
    const recorderStateRef = useRef(recorderState)
    recorderStateRef.current = recorderState;
    const submitRef = useRef(null);
    const bottomRef = useRef(inView);
    bottomRef.current = inView
    const topRef = useRef(null);
    const preValidatedEmailRef = useRef(null)
    preValidatedEmailRef.current = preValidatedEmail
    const submitDisableRef = useRef(null)
    submitDisableRef.current = submitDisable
    const choosencurrencyRef = useRef(null)
    choosencurrencyRef.current = choosencurrency

    const handleRecordClick = async () => {
        try {
            if (isRecordNeeded) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                const recorder1 = new MediaRecorder(stream, { mimeType: 'video/webm; codecs=vp9' });
                recorder1.onstop = () => {
                    stream?.getTracks()?.forEach(track => track?.stop());
                }
                if (videoRef?.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                }
                recorder1.start();
                setRecorder(recorder1);
            }
        } catch (error) {
            console.log("error in handleRecordClick", error.message);
        }
    };

    const handleStopClick = () => {
        if (recorderRef?.current && recorderRef?.current?.stream?.active) {
            recorder.stop();
            return "recording"
        }
        return null
    }

    const handleDuplicateNames = (clone, availableNames) => {
        const filteredName = availableNames.filter((name) => name?.includes(`${clone.name}_loop`))
        if (filteredName.length) {
            clone.name = clone.name + (findNextLoopNumber(filteredName) || "")
        } else if (availableNames.includes(clone.name)) {
            clone.name = clone.name + "_loop1"
        }
        return clone
    }

    const handleDuplicateValues = (addedValues, availableNames) => {
        return addedValues.map((clone) => {
            clone = handleDuplicateNames(clone, availableNames)
            if (inputFields.includes(clone.type)) {
                const availableFieldNames = realData?.flatMap((fieldName) => fieldName?.options?.map((moc) => moc?.name))?.filter((field) => field)
                clone.options = clone?.options?.map((field) => {
                    return handleDuplicateNames(field, availableFieldNames)
                })
            }
            return clone
        })
    }

    const getProperDateFormat = (date, type) => {
        const convertedDate = type === "date" ? new Date(date).toLocaleDateString("en-US") : dateformat(date, "dddd, mmmm dS, yyyy, h:MM:ss TT")
        return {
            convertedDate,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            realDate: new Date(date)
        }
    }

    const onSubmit = async (data, e) => {
        setIsSubmit(false)
        let finalData = []
        const keyTemplate = {}
        if (!realData?.length) return
        for (let [index, real] of realData.entries()) {
            finalData[index] = {
                question: titleFromParams(real?.title),
                key: real?.name,
                answer: {}
            }
            if (real?.type === "input" || real?.type === "multi-input" || real.type === "date" || real.type === "dateAndTime" || real?.type === "phoneNumber" || real.type === "time") {
                const optionKeys = []
                for (let option of real?.options) {
                    if (option.isRequired && index === realData.length - 1 && real?.type !== "multi-input") {
                        await handleOk(real, option?.name)
                    }
                    if (["date", "dateAndTime"].includes(real.type)) {
                        finalData[index].answer[option?.name] = maintainValues[option?.name] ? getProperDateFormat(maintainValues[option?.name], real.type) : ""
                    } else if (real.type === "time") {
                        finalData[index].answer[option?.name] = maintainValues[option?.name] ? moment(new Date(maintainValues[option?.name])).format('LT') : ""
                    } else {
                        finalData[index].answer[option?.name] = maintainValues[option?.name]
                    }
                    optionKeys.push(option?.name)
                }
                keyTemplate[real?.name] = {
                    keys: optionKeys,
                    type: real?.type
                }
            } else if (real?.type === "dropdown" && dropdownData[real?.name]) {
                const selectedValue = dropdownData[real?.name].filter((option) => option.value === maintainValues[real?.name] || option.label === maintainValues[real?.name])
                finalData[index].answer[real?.name] = selectedValue?.length ? selectedValue[0] : maintainValues[real?.name]
            } else if (real?.type !== 'submit') {
                if (real?.isRequired && index === realData?.length - 1) {
                    await handleOk(real, real?.name);
                }
                if (real?.type === 'select') {
                    const selectedValue = real?.options?.find((x) => x.value === maintainValues[real?.name]) || "Other";
                    const score = selectedValue?.score;
                    const quantity = real?.isQuantity ? getQuantity(real?._id, selectedValue.value) : null;
                    finalData[index].answer[real?.name] = { value: maintainValues[real?.name], score, quantity: quantity }
                    finalData[index].category = real?.category;
                    keyTemplate[real?.name] = {
                        keys: [],
                        type: 'select'
                    }
                }
                else if (real?.type === 'multi-select') {
                    finalData[index].answer[real?.name] = maintainValues[real?.name]?.map((x) => {
                        const score = real?.options?.find(val => val.value === x)?.score;
                        const quantity = real?.isQuantity ? getQuantity(real?._id, x) : null;

                        return { value: x, score, quantity: quantity }
                    })
                    finalData[index].category = real?.category
                    keyTemplate[real?.name] = {
                        keys: [],
                        type: 'multi-select'
                    }
                }
                else {
                    finalData[index].answer[real?.name] = maintainValues[real?.name]
                    keyTemplate[real?.name] = {
                        keys: [],
                        type: real?.type
                    }
                }
            }
        }

        if (isSubmitRef?.current) return;

        let paramsData = paramsToObject()
        finalData = [...finalData, { keyTemplate }]
        const formDetails = {
            ...allContentData?.additionalProperties, formId: allContentData?.formUUID, principalId: sessionStorage?.principalId, formName: allContentData?.formName, saveContact: allContentData?.saveContact ? true : false, publishedAt: allContentData?.publishedAt || null, contactGroup: allContentData?.saveContact && allContentData?.contactGroup ? allContentData?.contactGroup : "", sendResponseInEmail: allContentData?.sendResponseInEmail ? allContentData?.sendResponseInEmail : { isActive: false }
        }

        if (paramsData) {
            finalData = [...finalData, { ...paramsData }, { formDetails }]
        } else {
            finalData = [...finalData, { formDetails }]
        }

        if (isRecordNeeded && recorderRef?.current && handleStopClick() === "recording") {
            try {
                setLoading(true)
                recorder.ondataavailable = async (event) => {
                    try {
                        const blob = new Blob([event.data], { type: 'video/webm' });
                        const formData = new FormData();
                        formData.append("file", blob, `${atob(sessionStorage?.formUUID)}.webm`);
                        const fileRes = await uploadFile(formData, atob(sessionStorage?.formUUID), sessionStorage?.principalId)
                        if (fileRes?.url && fileRes?.url?.length) {
                            finalData["RecordedUrl"] = fileRes?.url[0];
                            finalApi(finalData)
                        }
                    } catch (error) {
                        console.log("error in ondataavailable", error?.message);
                        setLoading(false);
                        setOpen(true)
                        setErrMsg('Something went wrong, please try again')
                        setBtnLoading(false)

                    }
                };
            } catch (error) {
                setLoading(false);
                setOpen(true)
                setErrMsg('Something went wrong, please try again')
                setBtnLoading(false)
            }

        }
        else if (!isRecordNeeded) {
            finalApi(finalData)
        }
        else {
            setPermissionDialog(true)
        }
    };

    const finalApi = async (finalData) => {
        try {
            if (!previewMode) {
                setBtnLoading(true)
                window.dataLayer.push({ 'event': 'form_data', data: finalData })
                let res
                if (allContentData?.additionalProperties?.endPoinDetails?.url) {
                    res = await sendToEndPoint(finalData);
                    if (!res?.err) {
                        window.waymore?.track("form_submitted", {
                            form_uuid: atob(sessionStorage.getItem('formUUID'))
                        });
                        navigate('/submitted', {
                            state: {
                                finalContent: finalContent?.length ? finalContent : thankyouPage, allowNewResponse: allContentData?.allowNewResponse
                                    ?? false
                            }
                        })
                        setBtnLoading(false)
                        setLoading(false);
                    } else {
                        window.waymore?.track("form_submission_failed", {
                            message: res?.err || 'Something went wrong, please try again',
                            form_uuid: atob(sessionStorage.getItem('formUUID'))
                        });
                        setOpen(true)
                        setErrMsg('Something went wrong, please try again')
                        setBtnLoading(false)
                        setLoading(false);
                    }
                } else {
                    res = await saveFormData(finalData);
                    if (!res?.err && res?.data?.data) {
                        window.waymore?.track("form_submitted", {
                            form_uuid: atob(sessionStorage.getItem('formUUID'))
                        });
                        navigate('/submitted', {
                            state: {
                                finalContent: finalContent?.length ? finalContent : thankyouPage, allowNewResponse: allContentData?.allowNewResponse
                                    ?? false
                            }
                        })
                        setBtnLoading(false)
                        setLoading(false);
                    } else {
                        window.waymore?.track("form_submission_failed", {
                            message: res?.err || 'Something went wrong, please try again',
                            form_uuid: atob(sessionStorage.getItem('formUUID'))
                        });
                        setOpen(true)
                        setErrMsg('Something went wrong, please try again')
                        setBtnLoading(false)
                        setLoading(false);
                    }
                }
            } else {
                navigate('/submitted', {
                    state: {
                        finalContent: finalContent?.length ? finalContent : thankyouPage, allowNewResponse: allContentData?.allowNewResponse
                            ?? false
                    }
                })
            }
        } catch (e) {
            setOpen(true)
            setErrMsg('Something went wrong, please try again')
            setBtnLoading(false)
            setLoading(false);
        }
    }

    const sendToEndPoint = async (finalData) => {
        try {
            var config = {
                method: 'post',
                url: allContentData?.additionalProperties?.endPoinDetails?.url,
                data: finalData
            };
            if (allContentData?.additionalProperties?.endPoinDetails?.headers) config["headers"] = JSON.parse(allContentData?.additionalProperties?.endPoinDetails?.headers);
            const response = await sendDataToEndPoint(config);
            return response;
        } catch (error) {
            console.log("error in send to endpoint");
        }
    }

    const buttonRef = useRef()

    const pageIndexRef = useRef(pageIndex);
    pageIndexRef.current = pageIndex;
    const realDataRef = useRef(realData)
    realDataRef.current = realData
    const maintainValueRef = useRef(maintainValues)
    maintainValueRef.current = maintainValues
    const costFormScrollRef = useRef(costFormScroll)
    costFormScrollRef.current = costFormScroll
    const isDownScrolledRef = useRef(isDownScrolled)
    isDownScrolledRef.current = isDownScrolled
    const scrollDisableRef = useRef(scrollDisable)
    scrollDisableRef.current = scrollDisable
    const nob2bRef = useRef(nob2b)
    nob2bRef.current = nob2b
    const nob2cRef = useRef(nob2c)
    nob2cRef.current = nob2c


    const prepareInitialValues = async () => {
        let initialArray = []
        if (realData && realData.length) {

            realData.map((mock, index) => {
                if (mock.type === 'input') {
                    let newArr = []
                    mock.options.map((moc) => {
                        newArr.push(moc?.name)
                    })
                    initialArray[index] = newArr
                } else if (mock.type !== 'submit') {
                    initialArray[index] = mock?.name
                }
            })
            setInitialValues(initialArray)
            try {
                const dropdownFilteredData = realData.filter((data) => data?.type === "dropdown" && data?.fetchFromApi && data?.dropdownApiData?.dropdown_endPoint && !data?.dropdownApiData?.isAdvancedSearch)
                if (dropdownFilteredData?.length) {
                    const dropdownDataClone = { ...dropdownData }
                    for (let data of dropdownFilteredData) {
                        if (!Object.keys(dropdownData).includes(data?.name)) {
                            const dropdownRes = await getCustomDropownData(data?.dropdownApiData)
                            if (dropdownRes?.data?.dropdownData?.length) {
                                dropdownDataClone[data?.name] = dropdownRes?.data?.dropdownData?.splice(0, data?.dropdownApiData?.dropdown_maxVal && data?.dropdownApiData?.dropdown_maxVal !== '0' ? Number(data?.dropdownApiData?.dropdown_maxVal) : dropdownRes?.data?.dropdownData?.length - 1)
                            }
                        }
                    }
                    setDropdownData(dropdownDataClone)
                }
            } catch (e) {
                console.error('error in dropdown data', e)
            }
        }
    }

    const getQuestionName = (dropDependedName, nextDropdown) => {
        if (realDataRef?.current?.length) {
            const filteredData = realDataRef.current.filter((real) => real.dropDependedName == dropDependedName)
            if (filteredData.length) {
                filteredData.forEach((data) => {
                    setDropdownData(dropdownData => ({ ...dropdownData, [data.name]: nextDropdown?.data?.dropdownData }))
                })
            }
        }
    }

    const handleDropdownValue = async (value, apiValues) => {
        try {
            if (apiValues?.isActivated) {
                setApiBtnDropLoading(true)
                let apiValuesClone = { ...apiValues }
                apiValuesClone['dropdown_endPoint'] += `${apiValuesClone['dropdown_endPoint'].includes("?") ?
                    "&" : "?"
                    }${apiValuesClone?.searchKey}=${value} `
                apiValuesClone['dropdown_endPoint'] = apiValuesClone['dropdown_endPoint']
                const nextDropdown = await getCustomDropownData(apiValuesClone)
                if (nextDropdown.data?.dropdownData?.length > 0) {
                    getQuestionName(apiValuesClone?.dropDependedName, nextDropdown)
                }
            }
            setScrollDisable(false)
        } catch (err) {
            console.error("error in handleSearchChange", err);
        } finally {
            setApiBtnDropLoading(false)
            setScrollCapture("down")
            setPageIndex(pageIndexRef?.current + 1)
        }
    }

    const handleOk = async (mock, name, hasValue) => {
        try {
            if (!isRecordNeeded || (isRecordNeeded && recorderRef?.current && recorderRef?.current?.stream?.active)) {
                if (mock?.type === "input" || mock.type === "date" || mock.type === "dateAndTime" || mock.type === "time") {
                    for (let [index, moc] of mock?.options?.entries()) {

                        if (moc?.type === "url" && maintainValueRef.current[moc?.name]) {
                            var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
                            var regex = new RegExp(expression)
                            if (maintainValueRef.current[moc?.name]?.match(regex)) {
                                setValidUrl(true)
                                let isAllowNext = true
                                if (maintainValueRef.current[moc?.name] && mock?.callApiOnSelect?.isActivated) {
                                    isAllowNext = false
                                    handleDropdownValue(maintainValueRef.current[moc?.name], mock.callApiOnSelect)
                                }
                                if (index === mock?.options?.length - 1 && isAllowNext) {
                                    setScrollCapture("down")
                                    setPageIndex(pageIndexRef?.current + 1)
                                }
                                const res = await getIndustryIdentifier(maintainValueRef.current[moc?.name])
                                if (res.status === 200 && res?.data?.data?.data?.domain?.categories) {
                                    setCategory(res?.data?.data?.data?.domain?.categories);
                                } else {
                                    setCategory([])
                                }
                            } else {
                                setValidUrl(false)
                                setTimeout(() => {
                                    setValidUrl(true)
                                }, 3000)
                                isSubmitRef.current = true;
                                break;
                            }
                        } else if (moc?.type == "email" && maintainValueRef.current[moc?.name]) {
                            var expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi
                            var regex = new RegExp(expression)
                            if (maintainValueRef.current[moc?.name]?.match(regex)) {
                                setApiBtnLoading(true)
                                let emailRes = {}
                                if (!preValidatedEmailRef?.current?.includes(maintainValueRef.current[moc?.name])) {
                                    emailRes = await validateEmail(maintainValueRef.current[moc?.name])
                                }
                                setApiBtnLoading(false)
                                if (emailRes?.data?.data?.isValid || preValidatedEmailRef?.current?.includes(maintainValueRef.current[moc?.name])) {
                                    setValidEmail(true)
                                    if (!preValidatedEmailRef?.current?.includes(maintainValueRef.current[moc?.name])) {
                                        setPreValidatedEmail([...preValidatedEmailRef.current, maintainValueRef.current[moc?.name]])
                                    }
                                    let isAllowNext = true
                                    if (maintainValueRef.current[moc?.name] && mock?.callApiOnSelect?.isActivated) {
                                        isAllowNext = false
                                        handleDropdownValue(maintainValueRef.current[moc?.name], mock.callApiOnSelect)
                                    }
                                    if (index === mock?.options?.length - 1 && isAllowNext) {
                                        setScrollCapture("down")
                                        setPageIndex(pageIndexRef?.current + 1)
                                    }
                                } else {
                                    setValidEmail(false)
                                    isSubmitRef.current = true;
                                    setPreValidatedEmail([...preValidatedEmailRef.current, maintainValueRef.current[moc?.name]])
                                    setTimeout(() => {
                                        setValidEmail(true)
                                    }, 3000)
                                    break;
                                }

                            } else {
                                setValidEmail(false)
                                isSubmitRef.current = true;
                                setTimeout(() => {
                                    setValidEmail(true)
                                }, 3000)
                                break;
                            }
                        } else if (moc?.type == "file" && maintainValueRef.current[moc?.name]) {
                            if (maintainValueRef.current[moc?.name]?.length) {
                                if (index === mock?.options?.length - 1) {
                                    setScrollCapture("down")
                                    setPageIndex(pageIndexRef?.current + 1)
                                }
                            }
                        }
                        else {
                            let isAllowNext = true
                            if (["textArea", "text", "number", "decimal"].includes(moc.type) && maintainValueRef.current[moc?.name] && mock?.callApiOnSelect?.isActivated) {
                                isAllowNext = false
                                handleDropdownValue(maintainValueRef.current[moc?.name], mock.callApiOnSelect)
                            }
                            if (moc.isRequired) {
                                if (maintainValueRef.current[moc?.name]) {
                                    if (index === mock?.options?.length - 1 && isAllowNext) {
                                        setScrollCapture("down")
                                        setPageIndex(pageIndexRef?.current + 1)
                                    }
                                } else {
                                    setOpen(true)
                                    isSubmitRef.current = true;
                                    setErrMsg(`${moc?.label ? moc?.label : 'This field'} is required`)
                                    break;
                                }

                            } else {
                                if (name === 'noOfContacts' && !maintainValueRef.current['noOfContacts']) {
                                    let clonedData = [...realDataRef?.current]
                                    clonedData = clonedData.filter((clone) => clone._id !== '63fa048989dde561c553676c')
                                    setRealData(clonedData)
                                    setScrollCapture("down")
                                    setPageIndex(pageIndexRef?.current + 1)
                                } else if (index === mock?.options?.length - 1) {
                                    setScrollCapture("down")
                                    setPageIndex(pageIndexRef?.current + 1)
                                }
                            }
                        }
                    }
                } else if (mock.type === "phoneNumber") {
                    for (let [index, moc] of mock?.options?.entries()) {
                        let isValid = true
                        if (maintainValueRef.current[moc?.name]) {
                            setApiBtnLoading(true)
                            const numberRes = await validateNumber(`+${maintainValueRef.current[moc?.name]}`)
                            if (!numberRes?.data?.data?.isValid) {
                                isValid = false
                            }
                            setApiBtnLoading(false)
                        }
                        if (moc.isRequired) {
                            if (maintainValueRef.current[moc?.name]) {
                                if (isValid) {
                                    if (index === mock?.options?.length - 1) {
                                        setScrollCapture("down")
                                        setPageIndex(pageIndexRef?.current + 1)
                                    }
                                } else {
                                    isSubmitRef.current = true
                                    setOpen(true)
                                    setErrMsg(`Please enter a valid number`)
                                }
                            } else {
                                setOpen(true)
                                setIsSubmit(true);
                                setErrMsg(`${moc?.label ? moc?.label : 'This field'} is required`)
                                break;
                            }

                        } else {
                            if (maintainValueRef.current[moc?.name] && !isValid) {
                                setOpen(true)
                                isSubmitRef.current = true;
                                setErrMsg(`Please enter a valid number`)
                            } else {
                                if (index === mock?.options?.length - 1) {
                                    setScrollCapture("down")
                                    setPageIndex(pageIndexRef?.current + 1)
                                }
                            }

                        }
                    }

                } else if (mock?.type == 'multi-input') {
                    for (let [index, moc] of mock?.options?.entries()) {
                        const urlExpression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
                        const urlRegex = new RegExp(urlExpression);
                        const emailExpression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi
                        const emailRegex = new RegExp(emailExpression);
                        const reg = new RegExp("^[0-9]*$");
                        if (moc?.type === "url" && !maintainValueRef.current[moc?.name]?.answer?.match(urlRegex)) {
                            setOpen(true)
                            setIsSubmit(true);
                            setErrMsg(`${moc?.name ? moc?.name : 'This field'} is not valid url, please enter valid url`)
                            break;
                        }
                        else if (moc?.type === "email" && !maintainValueRef.current[moc?.name]?.answer?.match(emailRegex)) {
                            setOpen(true)
                            setIsSubmit(true);
                            setErrMsg(`${moc?.name ? moc?.name : 'This field'} is not valid email, please enter valid email`)
                            break;
                        }
                        else if (moc?.type === "number" && !maintainValueRef.current[moc?.name]?.answer?.match(reg)) {
                            setOpen(true)
                            setIsSubmit(true);
                            setErrMsg(`${moc?.name ? moc?.name : 'This field'} must contain only number, please enter valid number`)
                            break;
                        }
                        if (moc.isRequired) {
                            if (maintainValueRef.current[moc?.name]?.answer) {
                                if (index === mock?.options?.length - 1) {
                                    setScrollCapture("down")
                                    setPageIndex(pageIndexRef?.current + 1)
                                }
                            } else {
                                setOpen(true)
                                setIsSubmit(true);
                                setErrMsg(`${moc?.question ? moc?.question : 'This field'} is required, please click on Ok if you have filled current question`)
                                break;
                            }

                        } else {
                            if (index === mock?.options?.length - 1) {
                                setScrollCapture("down")
                                setPageIndex(pageIndexRef?.current + 1)
                            }
                        }
                    }
                } else if (mock?.type == 'linkedin') {
                    if (mock?.isRequired) {
                        if (maintainValueRef?.current[mock?.name]) {
                            setScrollCapture("down")
                            setPageIndex(pageIndexRef?.current + 1)
                        } else {
                            setOpen(true)
                            setIsSubmit(true)
                            setErrMsg("This Field is required")
                        }
                    } else {
                        setScrollCapture("down")
                        setPageIndex(pageIndexRef?.current + 1)
                    }
                } else if (mock.type === "currency") {
                    if (mock.options[0]?.isRequired) {
                        if (maintainValueRef?.current[mock?.name]?.length && choosencurrencyRef.current) {
                            setMaintainValues({ ...maintainValueRef?.current, [`${mock?.name}`]: `${maintainValueRef?.current[mock?.name].split(' ')[0]} ${choosencurrencyRef.current}` });
                            setScrollCapture("down")
                            setPageIndex(pageIndexRef?.current + 1)
                        } else {
                            if (!maintainValueRef?.current[mock?.name]?.length) {
                                setOpen(true)
                                setIsSubmit(true)
                                setErrMsg("This Field is required")
                            } else if (!choosencurrencyRef.current) {
                                setOpen(true)
                                setIsSubmit(true)
                                setErrMsg("Currency is required")
                            }
                        }
                    } else {
                        if (maintainValueRef?.current[mock?.name]?.length && choosencurrencyRef.current) {
                            setMaintainValues({ ...maintainValueRef?.current, [`${mock?.name}`]: `${maintainValueRef?.current[mock?.name].split(' ')[0]} ${choosencurrencyRef.current}` });
                        }
                        setScrollCapture("down")
                        setPageIndex(pageIndexRef?.current + 1)
                    }
                }
                else {
                    if (typeof maintainValueRef?.current[mock?.name] === 'number' ? (maintainValueRef?.current[mock?.name] || maintainValueRef?.current[mock?.name] === 0) : maintainValueRef?.current[mock?.name]?.length || hasValue || skipRequired.includes(mock?.type)) {
                        setScrollCapture("down")
                        setPageIndex(pageIndexRef?.current + 1)
                    } else {
                        if (mock?.isRequired) {
                            setOpen(true)
                            setIsSubmit(true)
                            setErrMsg("This Field is required")
                        } else {
                            setScrollCapture("down")
                            setPageIndex(pageIndexRef?.current + 1)
                        }
                    }
                }
            }
            else {
                setPermissionDialog(true)
            }
        } catch (err) {
            console.log(err)
            setApiBtnLoading(false)
        }
    }

    const handleDontKnow = async (mock, index) => {
        let cloneMaintain = { ...maintainValues }

        setMaintainValues({ ...cloneMaintain, [`${initialValues[index]}`]: "" })

        let cloneData = [...realData]
        let res = await getOptionalFormData({ idArray: mock?.dependedId })
        if (res?.status === 200 && res?.data?.data?.length) {
            const data = res?.data?.data
            const currentDependencies = realData.filter((rel) => rel?.dependedId === mock?.dependedId)
            let neededData = []
            currentDependencies.map((current) => {
                if (!mock?.modifyOrder?.removeOrder.includes(current.order)) {
                    neededData.push(current)
                }
            })
            let newData = [...data, ...neededData]
            cloneData.splice(index, currentDependencies.length, ...newData)
            setRealData(cloneData)
        }
    }

    const addInitialData = async () => {
        try {
            setIsLoading(true)
            if (previewFormData) {
                setFormData(updateDuplicateValues(previewFormData))
                setRealData(updateDuplicateValues(previewFormData))
            } else {
                const formDataRes = await getInitialFormData()
                if (formDataRes?.status === 200) {
                    const formData = formDataRes?.data?.data
                    setFormData(formData)
                    if (!realData?.length && formData?.length) {
                        setRealData(updateDuplicateValues(formData))
                    }
                }
            }
            let res = await getCountry()
            if (res?.status === 200) {
                if (res?.data) {
                    let str = res?.data;
                    str = str?.replace('geoip(', '')?.replace(")", "")
                    str = JSON.parse(str || null)
                    setCountry(str?.country_code)
                    setChoosenCurrency(Object.keys(str?.currencies || {})[0] || "EUR")
                }
            }
            setIsLoading(false)
            let countryArr = []
            countries && countries.map((count) => {
                countryArr.push({
                    label: count?.name,
                    value: count?.code
                })
            })
            setCountryArray(countryArr)
        } catch (e) {
            console.error(e.message || e)
            setIsLoading(false)
        }
    }
    const addNextData = async (key, index, ownId, isRadio, selectedIds) => {
        setSubmitDisable(true)
        try {
            const selfIndex = _.findIndex(formData, function (findId) { return findId?._id === ownId })
            const innerIndex = _.findIndex(realData, function (findId) { return findId?._id === ownId })
            const selectedOptions = transformObjectToArray(selectedIds)
            const availableNames = realData.map((realData) => realData.name)

            if (isRadio === 'radio') {
                const nextID = realData[innerIndex]?.options?.filter((opt) => opt?.value === key)
                const id = nextID && nextID.length && nextID[0]?.id ? nextID[0]?.id : 0
                const nextDropRes = await getFormData({ dependedId: id, selectedOptions })
                let nextDrop = (nextDropRes?.status === 200 && nextDropRes?.data?.data?.length) ? nextDropRes?.data?.data : [];
                let cloneData = [...realData]
                const removeID = realData[innerIndex]?.options?.filter((opt) => opt?.value !== key)
                const removeIdArr = removeID.map((remove) => remove?.id)
                cloneData = cloneData.filter((clone, ind) => {
                    if (ind >= index) {
                        if (typeof clone.dependedId === "string") {
                            return !removeIdArr.includes(clone.dependedId)
                        } else {
                            return !clone?.dependedId?.some((c) => removeIdArr?.includes(c))
                        }
                    } else {
                        return clone
                    }
                })
                if (nextDrop?.length) {
                    nextDrop = handleDuplicateValues(nextDrop, availableNames)
                }
                const splicedData = cloneData.splice(index)
                cloneData.push(...nextDrop)
                cloneData = [...cloneData, ...splicedData]
                if (isMarketingAgency) {
                    cloneData = cloneData.filter((clone) => clone?.dependedId !== "A.1.2.1.2.1" && clone?.dependedId !== "A.1.2.1.2.1.2")
                }
                if (maintainValueRef?.current['isWebsite'] === "no" || maintainValueRef?.current["ecommerce"] === "I don't know") {
                    cloneData = cloneData.filter((clone) => clone?.quotetype !== "eCommercePlugin")
                }
                if (maintainValueRef?.current['isWebsite'] === "no") {
                    cloneData = cloneData.filter((clone) => clone?.quotetype !== "websiteExists" && clone?.quotetype !== "socialMedia1")
                }
                if (maintainValueRef?.current['isWebsite'] === "yes") {
                    cloneData = cloneData.filter((clone) => clone?.quotetype !== "landingPageBuilder")
                }
                if (maintainValueRef?.current['socialmedia-marketing'] === "no") {
                    cloneData = cloneData.filter((clone) => clone?.quotetype !== "socialMedia")
                }
                if (nob2cRef.current) {
                    cloneData = cloneData.filter((clone) => clone?.quotetype !== "B2C")
                }
                if (nob2bRef.current) {
                    cloneData = cloneData.filter((clone) => clone?.quotetype !== "B2B")
                }
                setRealData(cloneData)

            } else if (isRadio === 'checkbox') {
                const nextID = realData[innerIndex]?.options?.filter((opt) => key?.includes(opt?.value))
                const idArray = [];
                nextID?.map((id) => idArray.push(id?.id))
                const nextDropRes = await getFormDataSorted({ idArray, selectedOptions })
                let nextDrop = (nextDropRes?.status === 200 && nextDropRes?.data?.data?.length) ? nextDropRes?.data?.data : [];
                if (nextDrop && nextDrop?.length) {
                    let cloneData = [...realData]
                    let splicedData = cloneData.splice(index)
                    realData[innerIndex]?.options?.map((self) => {
                        splicedData = splicedData.filter((data) => self?.id !== data?.dependedId)
                    })
                    nextDrop = handleDuplicateValues(nextDrop, availableNames)
                    cloneData.push(...nextDrop)
                    cloneData = [...cloneData, ...splicedData]
                    cloneData = _.uniqWith(cloneData, _.isEqual)

                    const removeID = realData[innerIndex]?.options?.filter((opt) => {
                        if (typeof key === "string") return opt?.value !== key
                        return !key.includes(opt?.value)
                    })
                    const removeIdArr = removeID.map((remove) => remove?.id)

                    cloneData = cloneData.filter((clone, ind) => {
                        if (ind >= index) {
                            if (typeof clone?.dependedId === "string") {
                                return !removeIdArr.includes(clone.dependedId)
                            } else {
                                return !clone?.dependedId?.every((c) => removeIdArr?.includes(c))
                            }
                        } else {
                            return clone
                        }
                    })
                    setRealData(cloneData)
                }
                else {
                    let cloneData = [...realData]
                    realData[innerIndex]?.options?.map((self) => {
                        cloneData = cloneData.filter((data, ind) => {
                            if (ind >= index) {
                                if (typeof data.dependedId === "string") {
                                    return self?.id !== data?.dependedId
                                } else {
                                    return !data?.dependedId.includes(self?.id)
                                }
                            } else {
                                return data
                            }
                        })
                    })
                    cloneData = _.uniqWith(cloneData, _.isEqual)
                    setRealData(cloneData)
                }
            } else if (isRadio === 'dropdown') {
                const nextID = formData[selfIndex]?.options?.filter((opt) => opt?.value === key)
                const nextInnerID = realData[innerIndex]?.options?.filter((opt) => opt?.value === key)

                const id = nextID && nextID.length && nextID[0]?.id ? nextID[0]?.id : nextInnerID[0]?.id ? nextInnerID[0]?.id : 0
                const nextDropRes = await getFormData({ dependedId: id, selectedOptions })
                let nextDrop = (nextDropRes?.status === 200 && nextDropRes?.data?.data?.length) ? nextDropRes?.data?.data : [];
                let cloneData = [...realData]
                const removeID = realData[innerIndex]?.options?.filter((opt) => opt?.value !== key)
                const removeIdArr = removeID.map((remove) => remove?.id)

                cloneData = cloneData.filter((clone, ind) => {
                    if (ind >= index) {
                        if (typeof clone.dependedId === "string") {
                            return !removeIdArr.includes(clone.dependedId)
                        } else {
                            return !clone?.dependedId?.some((c) => removeIdArr?.includes(c))
                        }
                    } else {
                        return clone
                    }
                })
                if (nextDrop?.length) {
                    nextDrop = handleDuplicateValues(nextDrop, availableNames)
                }
                const splicedData = cloneData.splice(index)
                cloneData.push(...nextDrop)
                cloneData = [...cloneData, ...splicedData]
                if (isMarketingAgency) {
                    cloneData = cloneData.filter((clone) => clone?.dependedId !== "A.1.2.1.2.1" && clone?.dependedId !== "A.1.2.1.2.1.2")
                }
                setRealData(cloneData)
            }
            setSubmitDisable(false)
        } catch (e) {
            setSubmitDisable(false)
            console.error('error in addNextData', e)
        }
    }

    const handlePrevious = () => {
        if (pageIndexRef.current > 0) {
            setScrollCapture('up')
            setPageIndex((pre) => pre - 1)
        }
    }

    const getService = async () => {
        let serviceRes = await getServicePrice(country)
        if (serviceRes?.status === 200) {
            const serviceData = serviceRes?.data?.data
            setServiceDetails(serviceData)
            setCurrency(serviceData?.country?.currency)
        }
        const countryData = countryArray && countryArray.filter((count) => {
            return count?.value === country
        })
        setFoundCountry(countryData)
        setIsLoading(false)
    }

    useEffect(() => {
        addInitialData()
        handleRecordClick()
        setRecorderState("")
    }, [])

    useEffect(() => {
        prepareInitialValues()
    }, [realData])

    useEffect(() => {
        if (country) {
            setIsLoading(true)
            getService()
            setIsLoading(false)
        }
    }, [country])

    useEffect(() => {
        window.scrollTo(0, 0)
        topRef.current = 0;
    }, [pageIndex])

    useEffect(() => {
        setSubmitDisable(apiBtnDropLoading)
    }, [apiBtnDropLoading])


    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        window.addEventListener('wheel', debounce((e) => {
            let newPageIndex = pageIndexRef.current;
            if (newPageIndex < realDataRef?.current?.length - 1 || e.deltaY < 0) {
                if (e.deltaY > 0 && !scrollDisableRef.current && bottomRef.current) {
                    if (isDownScrolledRef.current || realDataRef?.current[newPageIndex].name !== "costFormat") {
                        handleOk(realDataRef?.current[pageIndexRef?.current], realDataRef?.current[pageIndexRef?.current]?.name)
                    }

                }
                else if (newPageIndex > 0 && !scrollDisableRef.current && topRef.current === 0 && window.scrollY === 0) {
                    if (costFormScrollRef.current === 0 || isDownScrolledRef.current) {
                        setScrollCapture('up')
                        setPageIndex((pre) => pre - 1)
                    }
                }
                topRef.current = window.scrollY;
            }
        }, 500))
        return (() => {
            window.removeEventListener('wheel', () => { })
        })
    }, [])

    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        let div = document.getElementsByClassName("each-card")
        if (div && div.length) {
            div[0]?.addEventListener('scroll', (e) => {
                setCostFormScroll(e?.srcElement?.scrollTop)
            })
        }
        return (() => {
            if (div && div.length) {
                div[0]?.removeEventListener('scroll', () => { })
            }
        })

    })
    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        let element = document.querySelector(".each-card");
        element?.addEventListener('scroll', function (event) {
            var element = event.target;
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                setIsDownScrolled(true)
            } else {
                setIsDownScrolled(false)
            }
        });

        return (() => {
            element?.removeEventListener('scroll', () => { })
        })

    })

    useEffect(() => {
        /* eslint-disable-next-line no-restricted-globals */
        window.addEventListener('keydown', (e) => {
            if (e.key === "Enter" && pageIndexRef?.current < realDataRef?.current?.length - 1) {
                handleOk(realDataRef?.current[pageIndexRef?.current], realDataRef?.current[pageIndexRef?.current]?.name)
            }
        })
        return (() => {
            window.removeEventListener('keydown', () => { })
        })
    }, [])

    const hasSelect = (type) => {
        const showIf = ["select", "multi-select"];

        if (type.length) {
            return Boolean(showIf.includes(type));
        }

        return false;
    };

    const renderPreviousButton = () => {
        if (
            pageIndexRef.current > 0 &&
            !realData[pageIndexRef.current - 1].isTimeLimitEnabled
        ) {
            return (
                <button
                    className={`previousButton ${deviceType}`}
                    onClick={handlePrevious}
                >
                    Previous
                </button>
            );
        }

        return <React.Fragment />;
    };

    const renderFinalButton = (mock, index) => {
        if (mock.type !== "multi-input") {
            if (mock.type !== "payment") {
                if (index === realData?.length - 1) {
                    const submitClassname =
                        realData[index].type !== "multi-input" ? "hideBtn" : "";

                    return (
                        <Button
                            className={submitClassname}
                            btnLoading={btnLoading}
                            text="Submit"
                            type="submit"
                            disabled={submitDisableRef.current}
                        />
                    );
                } else {
                    const tickButtonText = apiBtnLoading
                        ? "Validating..."
                        : apiBtnDropLoading
                            ? "Please wait..."
                            : mock.type === "next"
                                ? "Next"
                                : "OK";

                    return (
                        <div className={"btnSpan"} ref={ref}>
                            <Button
                                text={tickButtonText}
                                apiBtnLoading={apiBtnLoading}
                                onClick={() => handleOk(mock, mock?.name)}
                                buttonRef={buttonRef}
                                type="button"
                                icon={TickIcon}
                                apiBtnDropLoading={apiBtnDropLoading}
                            />
                            {mock?.isDontKnow ? (
                                <Button
                                    text="I Don't know"
                                    type="button"
                                    onClick={() => handleDontKnow(mock, index)}
                                />
                            ) : mock.isDontKnowSkip ? (
                                <Button
                                    text="I Don't know"
                                    type="button"
                                    onClick={() => handleOk(mock, mock?.name)}
                                />
                            ) : (
                                <div>Press Enter</div>
                            )}
                        </div>
                    );
                }
            }

            return <React.Fragment />;
        }

        return <React.Fragment />;
    };

    useEffect(() => {
        if (!isClickSent && realData?.length && realData?.length - 1 === pageIndexRef?.current) {
            window.waymore?.track("final_page_click", {
                form_uuid: atob(sessionStorage.getItem('formUUID'))
            });
            setisClickSent(true)
        }
    }, [pageIndexRef?.current])

    return (
        <div className={`form-container ${deviceType}`} >
            {isLoading ? <CircularProgress style={{ 'color': 'white' }} /> :
                <form onSubmit={handleSubmit(onSubmit)}>
                    {realData && realData?.length ? realData?.map((mock, index) => {
                        const hasQuantity = hasSelect(mock?.type) && mock?.isQuantity;

                        if (index === pageIndex) {
                            return <div key={mock?.type + index} className={`each-card ${index} ${scrollCapture}`} >
                                {mock?.name === "costFormat" ? <CostFormat currency={currency} expertText={expertText} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} serviceDetails={serviceDetails} /> :
                                    mock?.type === "phoneNumber" ? <PhoneNumber setScrollDisable={setScrollDisable} setExpertText={setExpertText} isValidEmail={isValidEmail} isValidUrl={isValidUrl} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} category={category} countryCode={country} /> :
                                        mock?.type === "country" ? <AutoComplete setScrollDisable={setScrollDisable} handleOk={handleOk} countryArray={countryArray} country={country} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} isCountry={true} foundCountry={foundCountry} setCountry={setCountry} /> :
                                            mock?.type === "timezone" ? <TimeZone setScrollDisable={setScrollDisable} isdaylightSavings={isdaylightSavings} setDaylightSavings={setDaylightSavings} mock={mock} index={index + 1} initialValues={initialValues} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                mock?.type === "dateAndTime" ? <DateTimePicker setExpertText={setExpertText} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} type="datetime-local" /> :
                                                    mock?.type === "date" ? <DateTimePicker setExpertText={setExpertText} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} type="date" /> :
                                                        mock?.type === "time" ? <CustomTimePicker setScrollDisable={setScrollDisable} mock={mock} index={index + 1} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                            mock?.type === "input" ? <Input setScrollDisable={setScrollDisable} setExpertText={setExpertText} isValidEmail={isValidEmail} isValidUrl={isValidUrl} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} category={category} fileNames={fileNames} setFileNames={setFileNames} setIsLoading={setIsLoading} /> :
                                                                mock?.type === "multi-input" ? <MultiInput TickIcon={TickIcon} handleOk={handleOk} onSubmit={onSubmit} setScrollDisable={setScrollDisable} setExpertText={setExpertText} isValidEmail={isValidEmail} isValidUrl={isValidUrl} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} category={category} setIsLoading={setIsLoading} lastIndex={realData?.length} submitRef={submitRef} /> :
                                                                    mock?.type === "select" ? <RadioButton hasQuantity={hasQuantity} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} serviceDetails={serviceDetails} /> :
                                                                        mock?.type === "image-select" ? <RadioButton addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                            mock?.type === "multi-select" ? <CheckBox hasQuantity={hasQuantity} setScrollDisable={setScrollDisable} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                mock?.type === "image-multi-select" ? <CheckBox setScrollDisable={setScrollDisable} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                    mock?.type === "dropdown" ? <DropDown setApiBtnDropLoading={setApiBtnDropLoading} realData={realDataRef.current} setMarketingAgency={setMarketingAgency} addNextData={addNextData} setScrollDisable={setScrollDisable} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                        mock?.type === "next" ? <NextPage index={index + 1} rightIcon={rightarrowIcon} content={mock?.title} mock={mock} maintainValues={maintainValues} isApi={false} /> :
                                                                                            mock?.type === "text_api" ? <NextPage index={index + 1} rightIcon={rightarrowIcon} content={mock?.title} mock={mock} maintainValues={maintainValues} isApi={true} /> :
                                                                                                mock?.type === "payment" ? <PaymentModal goBack={handlePrevious} mock={mock} setScrollDisable={setScrollDisable} currency={currency} goNext={() => handleOk(mock, mock?.name)} /> :
                                                                                                    mock?.type === "serviceList" ? <CheckBox setScrollDisable={setScrollDisable} realData={realDataRef?.current} setRealData={setRealData} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                                        mock?.type === "quotation1" ? <CostFormat currency="EUR" expertText={expertText} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} serviceDetails={serviceDetails} isQutotation1={true} /> :
                                                                                                            (radioArr.includes(mock?.type)) ? <RadioButton setnob2b={setnob2b} setnob2c={setnob2c} addNextData={addNextData} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                                                mock?.type === "multiSelectDropdown-country" ? <DropDown countryArray={countryArray} content={mock?.content} setScrollDisable={setScrollDisable} mock={mock} index={index + 1} rightIcon={rightarrowIcon} initialValues={initialValues} setValue={setValue} maintainValues={maintainValues} setMaintainValues={setMaintainValues} isCountry={true} /> :
                                                                                                                    mock?.type === "appointment" ? <AppointmentPopup mock={mock} /> :
                                                                                                                        mock?.type === "linkedin" ? <Linkedin mock={mock} index={index + 1} rightIcon={rightarrowIcon} setValue={setValue} setMaintainValues={setMaintainValues} maintainValues={maintainValues} /> :
                                                                                                                            mock?.type === "google" ? <GoogleLogin mock={mock} index={index + 1} setValue={setValue} setMaintainValues={setMaintainValues} maintainValues={maintainValues} /> :
                                                                                                                                mock?.type === "facebook" ? <FacebookLogin mock={mock} index={index + 1} setValue={setValue} setMaintainValues={setMaintainValues} maintainValues={maintainValues} /> :
                                                                                                                                    mock?.type === "openproject" ? <OpenProject mock={mock} index={index + 1} setValue={setValue} setMaintainValues={setMaintainValues} maintainValues={maintainValues} /> :
                                                                                                                                        mock?.type === "twitter" ? <TwitterLogin mock={mock} index={index + 1} rightIcon={rightarrowIcon} setValue={setValue} setMaintainValues={setMaintainValues} maintainValues={maintainValues} /> :
                                                                                                                                            mock?.type === "currency" ? <CurrencyInput initialValues={initialValues} mock={mock} index={index + 1} setValue={setValue} setMaintainValues={setMaintainValues} maintainValues={maintainValues} setScrollDisable={setScrollDisable} choosencurrency={choosencurrency} setChoosenCurrency={setChoosenCurrency} /> :
                                                                                                                                                mock?.type === "rating" ? <CustomRating mock={mock} index={index + 1} initialValues={initialValues} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                                                                                    mock?.type === "calculation" ? <CalculativeFields mock={mock} index={index + 1} initialValues={initialValues} maintainValues={maintainValues} setMaintainValues={setMaintainValues} /> :
                                                                                                                                                        mock?.type === "submit" ? <h1 className='Submit'><div dangerouslySetInnerHTML={{ __html: mock?.title }}></div></h1> :
                                                                                                                                                            <></>
                                }
                                {
                                    <div className='action-buttons-container'>
                                        {renderPreviousButton()}
                                        {renderFinalButton(mock, index)}
                                    </div>
                                }
                                {
                                    mock.isTimeLimitEnabled &&
                                    <CustomTimer
                                        isTimeLimitEnabled={mock.isTimeLimitEnabled}
                                        timeLimit={mock.timeLimit}
                                        timeCompletedCallBack={() => {
                                            if (index === realData?.length - 1) {
                                                onSubmit();
                                            } else {
                                                setScrollCapture("down");
                                                setPageIndex(pageIndexRef?.current + 1);
                                            }
                                        }}
                                    />
                                }
                            </div>
                        } else {
                            return <React.Fragment />
                        }
                    }) : <></>}
                </form>
            }
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => { setOpen(false); setErrMsg("") }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={() => { setOpen(false); setErrMsg("") }} severity="error">
                    {errMsg}
                </Alert>
            </Snackbar>
            {permissionDialog && <AlertDialogSlide open={permissionDialog} setOpen={(val) => { setPermissionDialog(val) }} title={''} content={'Enable camera and microphone permission to continue'} />}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {(allContentData?.recordVideo && allContentData?.showRecordVideo) && <video className='video-player' ref={videoRef} autoPlay muted />}
            <WatermarkContainer allContentData={allContentData} />
        </div>
    )
}

export default memo(QuoteForm)