import { useContext, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './style.scss'
import { titleFromParams } from '../../Utilities/helperFunction';
import appContext from '../../appContext';
import SearchDropdown from '../SearchDropdown';
import { getCustomDropownData } from '../../API/api';

const DropDown = ({ setMarketingAgency, addNextData, mock, index, rightIcon, realData, initialValues, setApiBtnDropLoading, setValue, maintainValues, setMaintainValues, setScrollDisable, isCountry, countryArray }) => {
    const { setCustomStyles, dropdownData, dependedHistory, setDependedHistory, setDropdownData } = useContext(appContext)
    const [options, setOptions] = useState([])

    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }

    const getQuestionName = (dropDependedName, nextDropdown) => {
        if (realData.length) {
            const filteredData = realData.filter((real) => real.dropDependedName == dropDependedName)
            if (filteredData.length) {
                filteredData.forEach((data) => {
                    setDropdownData(dropdownData => ({ ...dropdownData, [data.name]: nextDropdown.data?.dropdownData }))
                })
            }
        }
    }

    const handleChange = async (event) => {
        try {

            setValue(initialValues[index - 1], event?.target?.value);
            setMaintainValues({ ...maintainValues, [`${name}`]: event?.target?.value })
            const dependedHistoryClone = { ...dependedHistory }
            const selectedOptions = mock?.options?.filter((selectedOption) => selectedOption?.value === event?.target?.value)
            dependedHistoryClone[name] = selectedOptions.map((option) => option?.id)
            setDependedHistory({ ...dependedHistoryClone })
            setScrollDisable(false)
            const apiValues = { ...mock.callApiOnSelect }
            if (apiValues?.isActivated) {
                setApiBtnDropLoading(true)
                apiValues['dropdown_endPoint'] += `${apiValues?.dropdown_endPoint.includes("?") ?
                    "&" : "?"
                    }${apiValues?.searchKey}=${event?.target?.value} `
                const nextDropdown = await getCustomDropownData(apiValues)
                if (nextDropdown.data?.dropdownData?.length > 0) {
                    const dependedName = getQuestionName(apiValues?.dropDependedName, nextDropdown)
                    setDropdownData(dropdownData => ({ ...dropdownData, [dependedName]: nextDropdown.data?.dropdownData }))
                }
            }
            addNextData(event?.target?.value, index, mock?._id, 'dropdown', dependedHistoryClone)
            setApiBtnDropLoading(false)
            if (name === "industry") {
                if (event?.target?.value === "Marketing Agency") {
                    setMarketingAgency(true)
                } else {
                    setMarketingAgency(false)
                }
            }
        } catch (error) {
            setApiBtnDropLoading(false)
            console.error('error in handleChange dropdown', error)
        }
    };

    const handleSearchChange = async (value) => {
        try {
            setValue(initialValues[index - 1], value);
            setMaintainValues({ ...maintainValues, [`${name}`]: value })
            const apiValues = { ...mock.callApiOnSelect }
            if (apiValues?.isActivated) {
                setApiBtnDropLoading(true)
                apiValues['dropdown_endPoint'] += `${apiValues?.dropdown_endPoint.includes("?") ?
                    "&" : "?"
                    }${apiValues?.searchKey}=${value} `
                const nextDropdown = await getCustomDropownData(apiValues)
                if (nextDropdown.data?.dropdownData?.length > 0) {
                    getQuestionName(apiValues?.dropDependedName, nextDropdown)
                }
            }
            const dependedHistoryClone = { ...dependedHistory }
            const selectedOptions = mock?.options?.filter((selectedOption) => selectedOption?.value === value)
            dependedHistoryClone[name] = selectedOptions.map((option) => option?.id)
            setDependedHistory({ ...dependedHistoryClone })
            setScrollDisable(false)
            addNextData(value, index, mock?._id, 'dropdown', dependedHistoryClone)
            setApiBtnDropLoading(false)
        } catch (err) {
            setApiBtnDropLoading(false)
            console.error("error in handleSearchChange", err);
        }
    }

    useEffect(() => {
        if (isCountry && countryArray?.length) {
            mock['options'] = countryArray
        }
    }, [isCountry])

    useEffect(() => {
        if (Object.keys(dropdownData)?.includes(name) && dropdownData[name]?.length) {
            setOptions(dropdownData[name])
        } else {
            setOptions(mock?.options || [])
        }
        setCustomStyles(mock?.styles || {})
    }, [dropdownData])

    return (
        <div className="dropdown-container">
            <div className='dropdown-title' >
                <h1 className='title'><span>{index}
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                        <path
                            d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                    </svg>
                </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /></h1>
                <div>
                    <p>{mock?.description}</p>
                </div>
            </div>

            {mock?.isSearchEnabled ? <SearchDropdown options={options} onMouseEnter={() => setScrollDisable(true)} onMouseLeave={() => setScrollDisable(false)} onChange={handleSearchChange} mock={mock} defaultValue={maintainValues[name] ?? ""} name={name} />
                : <FormControl hiddenLabel onMouseEnter={() => setScrollDisable(true)} onMouseLeave={() => setScrollDisable(false)} sx={{ m: 1 }}>
                    <InputLabel hiddenLabel disableAnimation htmlFor="name-multiple" >Click to select</InputLabel>
                    <Select
                        value={maintainValues[name] ?? ""}
                        disableUnderline={true}
                        onChange={handleChange}
                        sx={{
                            '& fieldset': { top: 0 }
                        }}
                        variant="standard"
                        InputLabelProps={{ shrink: false }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    color: "#FFFF",
                                    background: "transparent",
                                    marginButtom: '10px',
                                    borderBottom: '2px dashed #FFFF',
                                },
                            },
                        }}
                    >
                        {options?.map((ele, index) => {
                            return <MenuItem key={index} value={ele?.value} className="Dropdown-menuItem" placeholder='Click to select'>{ele?.label}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>}
        </div>
    );
};

export default DropDown;