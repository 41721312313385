import React, { useContext, useEffect, useState } from 'react'
import appContext from '../../appContext'
import './style.scss'
import axios from 'axios'
import { CircularProgress } from '@mui/material';
import { keyExists } from '../../Utilities/Constant'
import { titleFromParams } from '../../Utilities/helperFunction';

function NextPage({ content, mock, maintainValues, isApi, index, rightIcon }) {
    const [title, setTitle] = useState(content)
    const [loading, setloading] = useState(false)
    const { quotation, setQuotation, setCustomStyles } = useContext(appContext)

    const prepareNewTitle = async () => {
        try {
            setloading(true)
            let endpoint = mock?.apiDetails?.endpoint ? mock?.apiDetails?.endpoint : "";
            let modifiedTitle = titleFromParams(title)
            if (endpoint) {
                let res = await axios.get(endpoint);
                if (res?.data?.data) {
                    mock?.apiDetails?.parameter.length > 0 && mock?.apiDetails?.parameter.map((details) => {
                        if (keyExists(res?.data?.data, details)) {
                            modifiedTitle = modifiedTitle.replace(`[${details}]`, keyExists(res?.data?.data, details))
                            setloading(false)
                        }
                        setloading(false)
                    })
                    setTitle(modifiedTitle)
                    setloading(false)
                } else {
                    setTitle(modifiedTitle)
                }
                setloading(false)
            } else {
                setloading(false)
                setTitle(modifiedTitle)
            }
        } catch (e) {
            setloading(false)
            setTitle(titleFromParams(content))
        }
    }

    useEffect(() => {
        if (!isApi) {
            let oldTitle = content
            if (oldTitle?.includes('${b2bPrice}')) {
                if (maintainValues['prospectCustomersCount']) {
                    let cost = 0.1 * Number(maintainValues['prospectCustomersCount'])
                    setQuotation({ ...quotation, "quotation9": cost.toFixed(2) })
                    oldTitle = oldTitle?.replace('${b2bPrice}', cost.toFixed(2))
                    setTitle(oldTitle)
                }
            } else if (oldTitle?.includes('${totalAmount}')) {
                let cost = 0
                if (maintainValues["quotation1"] === "yes" && quotation['quotation1']) {
                    cost += Number(quotation['quotation1'])
                }
                if (maintainValues["quotation2"] === "yes" && quotation['quotation2']) {
                    cost += Number(quotation['quotation2'])
                }
                if (maintainValues["quotation3"] === "yes") {
                    cost += 0
                }
                if (maintainValues["quotation4"] === "yes") {
                    cost += 60
                }
                if (maintainValues["quotation5"] === "yes") {
                    cost += 0
                }
                if (maintainValues["quotation6"] === "yes") {
                    cost += 0
                }
                if (maintainValues["quotation7"] === "yes" && quotation['quotation7']) {
                    cost += Number(quotation['quotation7'])
                }
                if (maintainValues["quotation8"] === "yes") {
                    cost += 9
                }
                if (maintainValues["quotation9"] === "yes" && quotation['quotation9']) {
                    cost += Number(quotation['quotation9'])
                }
                if (maintainValues["quotation10"] === "yes") {
                    cost += 300
                }
                if (maintainValues["quotation11"] === "yes") {
                    cost += 300
                }
                oldTitle = oldTitle?.replace('${totalAmount}', cost.toFixed(2))
                setTitle(oldTitle)
            } else {
                setTitle(titleFromParams(content))
            }
        } else {
            if (mock?.apiDetails) {
                prepareNewTitle()
            } else {
                setTitle(titleFromParams(content))
            }
        }
    }, [content])

    useEffect(() => {
        setCustomStyles(mock?.styles || {})
    }, [])

    return (
        <>
            {loading ? <CircularProgress style={{ 'color': 'white' }} /> : <h1 className='next-title'><span className='ind-right'>{index}
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                    <path
                        d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                </svg>
            </span><div className='next-pagecontainer' dangerouslySetInnerHTML={{ __html: title }} /></h1>}
        </>
    )
}

export default NextPage