import React, { useContext, useEffect } from "react";
import Appointment from "../../Pages/Appointment";
import appContext from "../../appContext";

function AppointmentPopup({ mock }) {
  const { setCustomStyles } = useContext(appContext);

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div>
      <Appointment appointmentDataId={mock?.appointment_id} />
    </div>
  );
}

export default AppointmentPopup;
