import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.scss'

function FinalPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [finalContent, setFinalContent] = useState([]);

  useEffect(() => {
    setFinalContent(location?.state?.finalContent);
  }, [location]);

  useEffect(() => {
    const handleLinkClick = (event) => {
      event.preventDefault();
      const linkUrl = event.target.href;
      window.waymore?.track("link_clicked", {
        url: linkUrl,
        form_uuid: atob(sessionStorage.getItem('formUUID'))
    });
    };

    // Attach event listener to anchor tags within the finalContent
    const container = document.getElementById("finalContentContainer");
    if (container) {
      const links = container.getElementsByTagName("a");
      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener("click", handleLinkClick);
      }
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (container) {
        const links = container.getElementsByTagName("a");
        for (let i = 0; i < links.length; i++) {
          links[i].removeEventListener("click", handleLinkClick);
        }
      }
    };
  }, [finalContent]);

  return (
    <div className='final-page-container'>
      <div className='content' id="finalContentContainer">
        <div dangerouslySetInnerHTML={{ __html: finalContent }} />
        {location?.state?.allowNewResponse && (
          <div className='new-response' onClick={() => navigate(`/form?id=${sessionStorage.getItem('id')}`)}>
            Submit another response
          </div>
        )}
      </div>
    </div>
  );
}

export default FinalPage;
