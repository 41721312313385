import { createContext, useContext, useState } from "react";

const QuantityContext = createContext(null);

const QuantityProvider = ({ children }) => {
  const [quantity, modifyQuantity] = useState(null);

  const getQuantity = (question, option) => {
    if (quantity && quantity[question] && quantity[question][option]) {
      return quantity[question][option];
    } else {
      return 0;
    }
  };

  const setQuantity = (question, option, increaseValue) => {
    const newQuantity = { ...quantity };
    const value =
      newQuantity && newQuantity[question] && newQuantity[question][option]
        ? newQuantity[question][option]
        : 0;

    const setValue = increaseValue ? value + 1 : value - 1;

    if (newQuantity[question] === undefined) {
      newQuantity[question] = {};
      newQuantity[question][option] = setValue;
    } else {
      newQuantity[question][option] = setValue;
    }

    modifyQuantity(newQuantity);
  };

  return (
    <QuantityContext.Provider value={{ getQuantity, setQuantity }}>
      {children}
    </QuantityContext.Provider>
  );
};

const useQuantity = () => {
  const contextValues = useContext(QuantityContext);

  return contextValues;
};

export { QuantityProvider, useQuantity };
