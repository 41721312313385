import axios from 'axios'
import { getUpdatedUrl } from '../Utilities/helperFunction';

axios.interceptors.request.use(
    config => {
        const id = sessionStorage.getItem("formUUID")
        const principalId = sessionStorage.getItem("principalId")
        if (id && config.url !== "https://geojs.srv.waymore.io/geo.js/" && principalId) {
            config.headers['form-uuid'] = (id);
            config.headers['principalid'] = (principalId);
            config.headers['Cache-Control'] = "no-cache";
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)


export const getCountry = async () => {
    const countryURL = process.env.REACT_APP_COUNTRY_URL
    try {

        let response = await axios.get(countryURL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getServicePrice = async (country) => {
    const countryURL = `/api/waymore_service_price/${country}`
    try {

        let response = await axios.get(countryURL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getInitialFormData = async () => {
    const formURL = `/api/waymore_initial_form`
    try {

        let response = await axios.get(formURL)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}
export const getFormData = async (data) => {
    const formURL = `/api/waymore_form`
    try {

        let response = await axios.get(formURL, {
            params: data
        });
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getFormDataSorted = async (data) => {
    const formURL = `/api/waymore_form_sorted`
    try {

        let response = await axios.get(formURL, {
            params: data
        });
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getOptionalFormData = async (data) => {
    const formURL = `/api/waymore_Optional_form`
    try {

        let response = await axios.get(formURL, {
            params: data
        });
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getContentData = async (principalId) => {
    const contentURL = `/api/waymore_content/${principalId}`
    try {

        let response = await axios.get(contentURL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getFormCompletionTime = async (formUUID) => {
    const contentURL = `/api/getFormCompletionTime`;

    try {
        const response = await axios.get(contentURL, {
            params: {
                formUUID: formUUID,
            },
        });

        if (response?.status) {
            return {
                estimatedTimeInSeconds: response?.data?.data?.estimatedTimeInSeconds,
            };
        } else {
            return {
                status: response?.status,
                data: response?.data,
                err: "",
            };
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: "",
        };
    }
};

export const waymoreEvents = async (data) => {
    const eventsURL = `/api/waymore_Events`
    try {

        let response = await axios.post(eventsURL, data);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const saveFormData = async (data) => {
    const eventsURL = `/api/waymoreformdata`
    try {

        let response = await axios.post(eventsURL, data);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getIndustryIdentifier = async (data) => {
    const eventsURL = `/api/waymore_industry_identifier/${data}`
    try {

        let response = await axios.get(eventsURL);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getClientToken = async (principalId, paymentId) => {
    const eventsURL = paymentId ? `/api/initialize_payment/${principalId}/${paymentId}` : `/api/initialize_payment/${principalId}/`

    try {

        let response = await axios.get(eventsURL);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const processPayment = async (data) => {
    try {
        var config = {
            method: 'post',
            url: `/api/process_payment`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        let response = await axios(config);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getInternalToken = async (id) => {
    try {

        var config = {
            method: 'get',
            url: `/api/getInternalToken/${id}`
        };
        let response = await axios(config)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getConfig = async (internalUUID) => {
    const URL = `/api/getConfig/${internalUUID}`
    try {

        let response = await axios.get(URL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }
}
export const getUserInfo = async (email) => {
    const URL = `/api/waymore_contacts_info/${email}`
    try {

        let response = await axios.get(URL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }
}

export const getCmsInfo = async (domain) => {
    const URL = `/api/getCmsInfo/${domain}`
    try {

        let response = await axios.get(URL);
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }
}

export const uploadFile = async (formData, formUUID, principalId) => {
    const url = `/api/uploadFile`;
    try {
        const response = await axios.post(`${url}?formUUID=${formUUID}&principalId=${principalId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data
    } catch (error) {
        console.error(error);
    }
}

export const sendDataToEndPoint = async (payload) => {
    try {
        const response = await axios(payload)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const linkedInLoginAuthenticate = async (code, waymore_id = "") => {
    try {
        const response = await axios.get(`https://linkedinauth.connect.waymore.io/api/linkedIn/fetch?code=${code}&waymore_id=${waymore_id}&redirectUri=${window.location.origin}`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const getLinkedInDetailsByToken = async (token) => {
    try {
        const response = await axios.get(`https://linkedinauth.connect.waymore.io/api/linkedIn/getByToken?token=${token}`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const validateEmail = async (email) => {
    try {
        const response = await axios.get(`/api/validateEmail/${email}`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const validateNumber = async (mobile) => {
    try {
        const response = await axios.get(`/api/validateMobile/${mobile}`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const getCustomDropownData = async (endpointDetails) => {
    try {
        var config = {
            method: 'get',
            url: getUpdatedUrl(endpointDetails?.dropdown_endPoint),
        };
        if (endpointDetails?.dropdown_endPointHeaders) config["headers"] = JSON.parse(endpointDetails?.dropdown_endPointHeaders);
        const response = await axios(config);
        return response?.data
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const getTwitterOauthToken = async (callbackUrl) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_TWITTER_SERVER_URL}/twitter/auth/get-oauth-token?callbackUrl=${callbackUrl}`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const getTwitterAccessToken = async (oauth_token, oauth_verifier) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_TWITTER_SERVER_URL}/twitter/auth/get-access-token?oauthToken=${oauth_token}&oauthVerifier=${oauth_verifier}`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const getFacebookUserData = async (accessToken) => {
    try {
        const response = await axios.get(`https://graph.facebook.com/v19.0/me?access_token=${accessToken}&fields=id,email,first_name,installed,is_guest_user,last_name,name,name_format,supports_donate_button_in_live_video,token_for_business,video_upload_limits,accounts,business_users,ids_for_apps,ids_for_business,permissions,picture`)
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
}

export const getGoogleUserData = async (accessToken) => {
    try {
        const response = await axios.get('/api/googleUser', {
            headers: { 'Authorization': `Bearer ${accessToken}` }
        }
        );
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }

    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
};

export const getTwitterUserDetails = async (accessToken) => {
    try {
        const response = await axios.get('/api/twitterUser', {
            headers: { 'Authorization': `Bearer ${accessToken}` }
        }
        );
        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }

    } catch (error) {
        return {
            status: 400,
            err: error,
            data: ""
        }
    }
};

export const saveEsignFormData = async (data) => {
    const eventsURL = `/api/esign_formdata`
    try {

        let response = await axios.post(eventsURL, data);

        return {
            status: response?.status,
            data: response?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getEsignFormStatus = async (recipientId) => {
    const eventsURL = `/api/esign_form_status/${recipientId}`
    try {

        let response = await axios.get(eventsURL);

        return {
            status: response?.status,
            data: response?.data?.data,
            err: ""
        }
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }

}

export const getConfigData = async(waymore_id) => {
    const eventsURL = `${process.env.REACT_APP_CONFIG_BASE_URL}/config?waymore_id=${waymore_id}`
    try {

        let response = await axios.get(eventsURL);

        return response?.data
    } catch (e) {
        return {
            status: 400,
            err: e,
            data: ""
        }
    }
}
