import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { getConfig, getContentData, getFormCompletionTime, getInternalToken, linkedInLoginAuthenticate, getTwitterAccessToken, getFacebookUserData, getGoogleUserData, getTwitterUserDetails, getConfigData } from "./API/api";
import './App.css';
import Appointment from "./Pages/Appointment";
import FinalPage from "./Pages/FinalPage";
import Payment from "./Pages/Payment";
import QuoteForm from './Pages/QuoteForm';
import appContext from "./appContext";
import SomethingWentWrong from "./Pages/SomethingWentWrong";
import LandingPage from "./Pages/LandingPage";
import { CircularProgress, Container } from "@mui/material";
import watermark from './Assets/watermark.svg'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

export const WatermarkContainer = ({ allContentData }) => {
  if (allContentData?.additionalProperties?.Watermark) {
    return (
      <div className="watermark">
        <a
          href="https://go.waymore.io/?utm_campaign=madebywaymorejs"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={watermark}
            alt=""
            className={allContentData?.additionalProperties?.WatermarkPosition}
          />
        </a>
      </div>
    );
  }

  return <React.Fragment />;
}

function App() {
  const [content, setContent] = useState([])
  const [appointmentIdDetails, setAppointmentIdDetails] = useState({})
  const [frontPageContent, setFrontPageContent] = useState([])
  const [validId, setValidId] = useState(true)
  const [allContentData, setAllContentData] = useState([])
  const [quotation, setQuotation] = useState({})
  const [landingPage, setLandingPage] = useState(null)
  const [totalQuotationAmount, setTotalQuotationAmount] = useState(0)
  const [costFormatArr, setCostFormatArr] = useState([])
  const [currency, setCurrency] = useState("EUR")
  const [loading, setLoading] = useState(false)
  const [finalContent, setFinalContent] = useState([])
  const [dropdownData, setDropdownData] = useState({})
  const [otherOption, setOtherOption] = useState({})
  const [customStyles, setCustomStyles] = useState({
    background: "",
    fontFamily: "",
    color: ""
  })
  const [gaTrackingId, setGaTrackingId] = useState("")
  const [gtmTrackingId, setGtmId] = useState("")
  const [linkedInName, setLinkedInName] = useState("")
  const [twitterName, setTwitterName] = useState("")
  const [twitterDetails, setTwitterDetails] = useState({})
  const [facebookDetails, setFacebookDetails] = useState({})
  const [googleDetails, setGoogleDetails] = useState({})
  const [tiktokDetails, setTiktokDetails] = useState({})
  const [openprojectDetails, setOpenprojectDetails] = useState({})
  const [timeEstimate, setTimeEstimate] = useState(null)
  const [previewFormData, setPreviewFormData] = useState(null)
  const [previewMode, setPreviewMode] = useState(false)
  const [deviceType, setDeviceType] = useState('pc')
  const [dependedHistory, setDependedHistory] = useState({})
  const location = useLocation();

  const contextItems = {
    appointmentIdDetails,
    setAppointmentIdDetails,
    frontPageContent,
    setFrontPageContent,
    allContentData,
    setAllContentData,
    validId,
    setValidId,
    landingPage,
    setLandingPage,
    quotation,
    setQuotation,
    costFormatArr,
    setCostFormatArr,
    totalQuotationAmount,
    setTotalQuotationAmount,
    currency,
    setCurrency,
    finalContent,
    setFinalContent,
    linkedInName,
    setLinkedInName,
    customStyles,
    setCustomStyles,
    otherOption,
    setOtherOption,
    dropdownData,
    setDropdownData,
    timeEstimate,
    previewFormData,
    setPreviewFormData,
    previewMode,
    setPreviewMode,
    deviceType,
    dependedHistory,
    setDependedHistory,
    twitterName,
    setTwitterName,
    twitterDetails,
    setTwitterDetails,
    facebookDetails,
    setFacebookDetails,
    googleDetails,
    setGoogleDetails,
    tiktokDetails,
    setTiktokDetails,
    openprojectDetails,
    setOpenprojectDetails
  }

  useEffect(() => {

    if (gaTrackingId) {
      ReactGA.initialize(gaTrackingId.trim())
    }
    if (gtmTrackingId) {
      const tagManagerArgs = {
        gtmId: gtmTrackingId.trim()
      }

      TagManager.initialize(tagManagerArgs)
    }

  }, [gaTrackingId, gtmTrackingId])

  const getContent = async (id) => {
    await getInternalToken(id).then(async (res) => {
      if (res?.status === 200 && res?.data?.data?.internalUUID) {
        try {
          let configRes = await getConfig(res?.data?.data?.internalUUID)
          if (configRes?.status === 200 && configRes?.data?.data?.config) {
            let formData = configRes?.data?.data?.config
            if (formData?.formUUID && formData?.principalId) {
              sessionStorage.setItem('formUUID', btoa(formData?.formUUID))
              sessionStorage.setItem("principalId", formData?.principalId)

              if (formData?.principalId) {
                const waymoreId = formData?.principalId?.split('_')[1]
                if (waymoreId) {
                  const data = await getConfigData(waymoreId)
                  const routeeToken = data?.id;
                  if (window.routee && routeeToken) {
                    window.routee.initialize({ routeeToken });
                  }
                }
              }
              setValidId(true)
              try {
                const res = await getContentData(formData?.principalId)
                if (res.status === 200 && res?.data?.data?.length) {
                  const data = res?.data?.data[0]
                  if (sessionStorage.getItem('formUUID') === 'YmNkNGM2MjctM2Q4ZC00ZTc2LThhMmEtMWI0ODZhZTlmNjI1') {
                    setContent(data?.formContent[0]?.style)
                    setAppointmentIdDetails(data?.formContent[0]?.appointmentID)
                    setFrontPageContent(data?.formContent[0]?.frontPageContent)
                    setFinalContent(data?.frontPageContent?.finalContent ?? data?.finalContent ?? "")
                    setLoading(false)
                  } else {
                    const formUUID = formData?.formUUID;
                    const hasLandingPage = Boolean(res?.data?.data[0]?.formContent);

                    if (hasLandingPage) {
                      const { estimatedTimeInSeconds } = await getFormCompletionTime(formUUID)

                      setTimeEstimate(estimatedTimeInSeconds)
                    }

                    setLandingPage(data?.formContent)
                    setAllContentData(data)
                    setContent(data?.style)
                    setFinalContent(data?.frontPageContent?.finalContent ?? data?.finalContent ?? "")

                    if (data?.gaTrackingId) {
                      setGaTrackingId(data?.gaTrackingId)
                    }

                    if (data?.gtmTrackingId) {
                      setGtmId(data?.gtmTrackingId)
                    }
                    setLoading(false)
                  }
                } else {
                  sessionStorage.clear()
                  setValidId(false)
                  setLoading(false)
                }
                setLoading(false)
              } catch (e) {
                sessionStorage.clear()
                setValidId(false)
                setLoading(false)
              }
            } else {
              sessionStorage.clear()
              setValidId(false)
              setLoading(false)
            }
          } else {
            sessionStorage.clear()
            setValidId(false)
            setLoading(false)
          }
        } catch (e) {
          sessionStorage.clear()
          setValidId(false)
          setLoading(false)
        }
      } else {
        sessionStorage.clear()
        setValidId(false)
        setLoading(false)
      }
    }).catch(() => {
      sessionStorage.clear()
      setValidId(false)
      setLoading(false)
    })
  }

  const fetchFacebookData = async (token) => {
    const userDetails = await getFacebookUserData(token)
    if (userDetails?.data && Object.keys(userDetails.data || {}).length) {
      setFacebookDetails(userDetails?.data)
    }
  }

  const fetchGoogleData = async (token) => {
    const userDetails = await getGoogleUserData(token)
    if (userDetails?.data?.data && Object.keys(userDetails.data?.data || {}).length) {
      setGoogleDetails(userDetails?.data?.data)
    }
  }

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data?.event === 'dataUpdate' && event.data?.payload) {
        const { landingPage, formData } = event.data.payload
        if (landingPage && formData) {
          sessionStorage.setItem('formUUID', btoa(landingPage?.formUUID))
          sessionStorage.setItem("principalId", landingPage?.principalId)
          setLandingPage(landingPage?.formContent)
          setAllContentData(landingPage)
          setContent(landingPage?.style)
          setFinalContent(landingPage?.frontPageContent?.finalContent ?? landingPage?.finalContent ?? "")
          setPreviewFormData(formData)
        }
      } else if (event.data?.event === 'previewType' && event.data?.payload) {
        setDeviceType(event.data.payload);
      }
      if (event?.data?.data?.access_token && event.data.Iframe_name === "Executive summary Facebook Login") {
        let access_token = event.data.data.access_token;
        fetchFacebookData(access_token)
      } else if (event?.data?.data?.token && event.data.Iframe_name === "Executive summary Google Login") {
        fetchGoogleData(event.data.data.token)
      } else if (event?.data?.IframeName === "open-project-login" && event?.data?.data?.userDetails) {
        setOpenprojectDetails(event?.data?.data?.userDetails)
      }
      if (event?.data?.data && event.data.IframeName === "tik-tok-organic-login") {
        setTiktokDetails(event?.data?.data)
      }
    });

    window.addEventListener('storage', () => {
      const LocalLinkedinDetails = localStorage?.getItem('linkedinDetails')
      const LocalTwitterDetails = localStorage?.getItem('twitter')

      if (LocalLinkedinDetails && Object.keys(LocalLinkedinDetails || {}).length > 0) {
        sessionStorage.setItem('linkedinDetails', LocalLinkedinDetails)
        localStorage.removeItem('linkedinDetails')
        const linkedinData = JSON.parse(LocalLinkedinDetails || null)
        setLinkedInName((linkedinData?.email ||
          linkedinData?.localizedFirstName ||
          linkedinData?.localizedLastName))
      }

      if (LocalTwitterDetails && Object.keys(LocalTwitterDetails || {}).length > 0) {
        sessionStorage.setItem('twitterDetails', LocalTwitterDetails)
        localStorage.removeItem('twitterDetails')
        const twitterData = JSON.parse(LocalTwitterDetails || null)
        getTwitterAccessTokenDetails(
          twitterData?.oauth_token,
          twitterData?.oauth_verifier
        );
      }

    })
  }, [])


  const getLinkedinDetails = async (code) => {
    try {
      const response = await linkedInLoginAuthenticate(code)
      if (response?.data?.data?.linkedinToken) {
        localStorage.setItem('linkedinDetails', JSON.stringify(response.data.data || null));
      }
      window.close()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log("err", e.message)
    }
  }

  const getTwitterAccessTokenDetails = async (oauth_token, oauth_verifier) => {
    try {
      const response = await getTwitterAccessToken(oauth_token, oauth_verifier);
      if (response?.data && Object.keys(response.data || {}).length) {
        const twitterToken = {
          token: response.data.accessToken,
          secret: response.data.accessTokenSecret,
          id: response.data.userId
        }
        const encodedToken = btoa(JSON.stringify(twitterToken))
        const userDetails = await getTwitterUserDetails(encodedToken)
        setTwitterDetails(userDetails?.data?.data?.data?.profileInfo || response?.data?.data || {})
        setTwitterName(response?.data?.data?.screenName)
      }
    } catch (e) {
      console.log("err", e.message);
    }
  };




  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Conversational Form" });
  }, [])


  useEffect(() => {
    try {
      setLoading(true)
      const params = new URL(window.location.href).searchParams;
      const sessionLinkedInDetails = sessionStorage?.getItem('linkedinDetails') || ""
      let id = params.get('id') || "";
      let code = params.get('code') || "";
      let state = params.get('state') || "";
      let mode = params.get('mode') || "";

      if (state === 'loadingPage') {
        if (code) {
          localStorage.setItem('linkedinCode', code)
          getLinkedinDetails(code)
        } else {
          setLoading(false)
        }
      } else if (mode === "preview") {
        setPreviewMode(true)
        setLoading(false)
      } else if (params.get('oauth_token') && params.get('oauth_verifier')) {
        const twitterDetails = { oauth_token: params.get('oauth_token'), oauth_verifier: params.get('oauth_verifier') };
        localStorage.setItem('twitter', JSON.stringify(twitterDetails));
        window.close();
      } else {
        if (id) {
          id = id.replace("?id=", "")
          sessionStorage.setItem("id", id)
        } else {
          id = sessionStorage.getItem("id") || "";
        }
        getContent(id)
      }
      if (sessionLinkedInDetails) {
        const linkedinData = JSON.parse(sessionLinkedInDetails || null)
        setLinkedInName((linkedinData?.email ||
          linkedinData?.localizedFirstName ||
          linkedinData?.localizedLastName))
      }
    } catch (e) {
      setLoading(false)
      console.log("err in app", e)
    }
  }, [])

  const containerMaxWidth = React.useMemo(() => {
    if (deviceType === "pc") {
      return "false";
    } else if (deviceType === "mobile") {
      return "sm";
    } else if (deviceType === "tab") {
      return "md";
    }

    return "false";
  }, [deviceType])

  return (
    <div style={{ height: "100vh", backgroundColor: "#ffffff" }}>
      <Container
        maxWidth={containerMaxWidth}
        style={{
          height: "100%",
          padding: containerMaxWidth === "false" ? "0px" : "unset",
        }}
      >
        <div
          className={`App ${deviceType}`}
          style={{
            "--color": customStyles?.color || content?.foregroundColor,
            background: `url(${customStyles?.background ||
              (location?.pathname === "/" && content?.landingPageBackground
                ? content?.landingPageBackground
                : location?.pathname === "/submitted" &&
                  content?.submitPageBackground
                  ? content?.submitPageBackground
                  : content?.background)
              })`,
            "--btn_color": content?.buttonColor,
            "--btn_txt_clr": content?.buttonTextColor,
            "--font_family": customStyles?.fontFamily || content?.fontFamily,
            "--landing_btn_color": content?.landingPageButtonColor,
            "--landing_btn_txt_color": content?.landingPageTxtColor,
            "--landing_btn_font": content?.landingPageButtonFont,
            "--landing_custom_copy_color": content?.landingPageCustomContentColour,
            "--landing_custom_copy_font": content?.landingPageCustomContentFont,
            "--loaderColor": content?.loaderColor,
          }}
        >
          <appContext.Provider value={contextItems}>
            {loading ? (
              <CircularProgress className="circular-Loader" />
            ) : (
              <>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route
                    path="/quote"
                    element={validId ? <QuoteForm /> : <SomethingWentWrong />}
                  />
                  <Route
                    path="/form"
                    element={validId ? <QuoteForm /> : <SomethingWentWrong />}
                  />
                  <Route path="/submitted" element={<FinalPage />} />
                  <Route path="/booking" element={<Appointment />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/notfound" element={<SomethingWentWrong />} />
                  <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
              </>
            )}
          </appContext.Provider>
        </div>
      </Container>
    </div>
  );
}

export default App;
