import React, { useContext, useEffect, useState } from "react";
import linkedinButton from "../../Assets/linkedinButton.svg";
import linkedInIcon from "../../Assets/linkedInIcon.svg";
import waymoreLinkedinIcon from "../../Assets/waymoreLinkedinIcon.svg";
import "./style.scss";
import { titleFromParams } from "../../Utilities/helperFunction";
import appContext from "../../appContext";

const Linkedin = ({
  mock,
  index,
  setMaintainValues,
  maintainValues,
  rightIcon,
  setValue,
}) => {
  const { linkedInName, setCustomStyles } = useContext(appContext);

  const handleLinkedInLogin = () => {
    // Initialize the LinkedIn SDK
    window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=profile,r_organization_social,w_member_social,r_emailaddress,w_organization_social,r_basicprofile,r_events,email,r_liteprofile,r_1st_connections_size&state=loadingPage&redirect_uri=${window.location.origin}`,
      "_blank"
    );
  };

  useEffect(() => {
    const linkedDetails = sessionStorage.getItem("linkedinDetails");
    if (linkedDetails) {
      setValue(mock?.name, linkedDetails);
      setMaintainValues({
        ...maintainValues,
        [`${mock?.name}`]: linkedDetails,
      });
    }
  }, [linkedInName]);

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="linkedin-container">
      <div className="linkedin-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div
            dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }}
          />
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      {linkedInName ? (
        <div className="linkedin-connected" onClick={handleLinkedInLogin}>
          <img className="linkedin-icon" src={linkedInIcon} />
          <div>Connected as : {linkedInName}</div>
          <img className="waymoreLinkedin-Icon" src={waymoreLinkedinIcon} />
        </div>
      ) : (
        <img
          className="linkedin-img"
          src={linkedinButton}
          onClick={handleLinkedInLogin}
        />
      )}
    </div>
  );
};

export default Linkedin;
