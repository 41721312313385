import React from "react";
import DoneIcon from "./DoneIcon";
import EditIcon from "./EditIcon";
import QuantityComponent from "../common/QuantityButton";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Alphabets } from "../../Utilities/landing-mock-data";
import "./style.scss";

function SelectButton({
  country,
  currency,
  mock,
  label,
  value,
  multiSelect,
  handleRadio,
  handleCheckbox,
  index,
  trueValue,
  isCostFormat,
  symbol,
  finalTotalCost,
  isOther,
  setOtherName,
  otherName,
  maintainValues,
  newValue,
  setCheckedOther,
  hasQuantity,
  optionImg
}) {
  const navigate = useNavigate();
  const [inputDom, setInputDom] = React.useState(false);
  const [inputVal, setInputVal] = React.useState(otherName);

  const handleClick = (type) => {
    switch (type) {
      case "learnMore": {
        navigate("/booking", { state: { country: country } });
        break;
      }
      case "createAccount": {
        window.location.href = "https://go.waymore.io/signup";
        break;
      }
      case "topup": {
        navigate(`/payment?id=${sessionStorage.getItem("id")}`, {
          state: {
            amount: finalTotalCost,
            symbol: symbol,
            currency: currency,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  const renderCustomSelectButton = (isMulti) => {
    const identifier = isMulti
      ? trueValue?.includes(otherName)
      : maintainValues && maintainValues[newValue] === otherName;

    return (
      <>
        {!isOther &&
          (isMulti ? (
            <input
              type="checkbox"
              defaultChecked={trueValue?.includes(value)}
              className="check-input"
              value={value}
              onClick={(e) => handleCheckbox(e?.target?.value)}
            />
          ) : (
            <input
              className="radio-input"
              checked={trueValue === value}
              type="radio"
              name={mock?.name}
              value={value}
              onChange={(e) => handleRadio(e.target.value)}
            />
          ))}
        <div>
          {isOther && inputDom && (
            <div
              className={`other-input-container ${inputDom ? "active" : ""}`}
            >
              <input
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                placeholder="Type your option..."
                className="other-input"
                value={inputVal}
                onChange={(e) => {
                  setInputVal(e.target.value);
                  isMulti && setCheckedOther(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  setOtherName(inputVal);
                  setInputDom(false);
                  if (isMulti) {
                    handleCheckbox(inputVal);
                  } else {
                    handleRadio(inputVal);
                  }
                }}
              >
                <DoneIcon />
              </button>
            </div>
          )}
          <Button
            disableRipple
            className={`select-button ${isOther && otherName && identifier ? "other" : ""
              }`}
            onClick={() => {
              !otherName && isOther && setInputDom(true);
              isOther && isMulti
                ? handleCheckbox(otherName)
                : handleRadio(otherName);
            }}
          >
            {optionImg && <img className="button-img" src={optionImg} />}
            <div className="option-button-inner-container">
              <div className="labelIndex">{Alphabets[index]}</div>
              <span
                className="btnLabel"
                dangerouslySetInnerHTML={{ __html: label }}
              ></span>
              <DoneIcon />
              {hasQuantity && (
                <QuantityComponent
                  isOther={isMulti ? false : isOther}
                  option={label}
                  question={mock?._id}
                />
              )}
            </div>
          </Button>
        </div >
        <div>
          {isOther && !inputDom && otherName && identifier && (
            <span
              onClick={() => {
                setInputVal(otherName);
                setInputDom(true);
              }}
              className="edit-svg"
            >
              <EditIcon />
            </span>
          )}
        </div>
      </>
    );
  };

  const renderCostFormatButton = () => {
    return (
      <div>
        <Button onClick={() => handleClick(value)} className="cost-button">
          <div className="labelIndex">{Alphabets[index]}</div>
          <span
            className="btnLabel"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Button>
      </div>
    );
  };

  const renderMultiSelectButton = () => {
    return renderCustomSelectButton(true);
  };

  const renderSingleSelectButton = () => {
    return renderCustomSelectButton(false);
  };

  const renderSelectableButton = () => {
    if (isCostFormat) {
      return renderCostFormatButton();
    }

    if (multiSelect) {
      return renderMultiSelectButton();
    }

    return renderSingleSelectButton();
  };

  return renderSelectableButton();
}

export default SelectButton;
