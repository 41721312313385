import React, { useEffect, useState } from 'react'
import { getClientToken, processPayment } from '../../API/api';
import DropIn from 'braintree-web-drop-in-react';
import './style.scss'
import TransactionSuccess from '../TransactionSuccess';
import { Base64, getCurrencyFromEuro } from '../../Utilities/helperFunction';
import errorIcon from '../../Assets/error.png'

function Payment({ actualAmount, actualSymbol, actualCurrency, goNext, goBack, paymentId }) {
    const [clientToken, setClientToken] = useState(null)
    const [subString, setSubString] = useState("")
    const [convertedAmount, setConvertedAmount] = useState("")
    const [loading, setLoading] = useState(false)
    const [errorData, setErrorData] = useState({ status: false, msg: "" })
    const [instance, setInstance] = useState("")
    const [transactionID, setTransactionID] = useState("")
    const [principalId, setPrincipalId] = useState(sessionStorage.getItem('principalId'))

    const callSuccessScript = (id) => {
        if (window?.dataLayer) {
            window.dataLayer.push(window.gtag('event', 'conversion', {
                'send_to': 'AW-10932421562/w7EeCOqA4IgYELqH_two',
                'value': convertedAmount,
                'currency': 'EUR',
                'transaction_id': id
            }))
        }
    }
    const getToken = async () => {
        setLoading(true)
        await getClientToken(principalId, paymentId).then((res) => {
            if (res?.data?.data) {
                setClientToken(Base64.decode(res?.data?.data));
            } else {
                setErrorData({ status: true, msg: "Something Went Wrong" });
                setLoading(false);
            }
        }).catch((err) => { setErrorData({ status: true, msg: "Something Went Wrong" }); setLoading(false) })
        setLoading(false)
    }
    const getCurrencyConverted = async (currency, amount, symbol) => {
        if (currency !== "EUR") {
            let equalentValue = await getCurrencyFromEuro(currency, "EUR", amount)
            equalentValue = equalentValue.toFixed(2)
            setSubString(` ${equalentValue} € (${amount} ${symbol})`)
            setConvertedAmount(equalentValue)
        } else {
            setSubString(` ${amount} € `)
            setConvertedAmount(amount)
        }
    }

    useEffect(() => {
        if (actualCurrency && actualAmount && actualSymbol) {

            getCurrencyConverted(actualCurrency, actualAmount, actualSymbol)
        } else {
            setErrorData({ status: true, msg: "Something went Wrong" })
        }
    }, [])

    useEffect(() => {
        getToken()
    }, [])

    const onPurchase = () => {
        setLoading(true)
        instance.requestPaymentMethod()
            .then(data => {
                if (data) {
                    let nonce = data.nonce;
                    let paymentData = {
                        payment_method_nonce: Base64.encode(nonce),
                        amount: convertedAmount,
                        principalId: principalId
                    }


                    processPayment(paymentData).then(response => {
                        let responseData = Base64.decode(response?.data?.data);
                        responseData = JSON.parse(responseData || null)
                        if (response?.status === 200 && responseData?.success) {
                            const transactionId = responseData?.transaction?.id;
                            setTransactionID(transactionId)
                            callSuccessScript(transactionId)
                            setLoading(false)
                        } else {
                            setErrorData({ status: true, msg: responseData?.message })
                            setLoading(false)
                        }
                    }).catch((err) => { setErrorData({ status: true, msg: err?.data?.data?.message }); setLoading(false) })
                }

            }).catch((err) => { setErrorData({ status: true, msg: err?.data?.data?.message }); setLoading(false) })

    }

    return (
        <div className='payment'>
            {/* {loading ? <CircularProgress style={{ 'color': 'white' }} /> : <></>} */}
            {errorData?.status ?
                <div className='Error-container'>
                    <div className='error-content'>
                        <span><img src={errorIcon} alt="" /> Transaction Failed</span>
                        <span className='error-reason'> Reason: {errorData?.msg ?? "Something went Wrong"}</span>
                    </div>
                    <button className='login-register-btn' onClick={goBack} >Go back</button>
                </div>
                :
                <>{transactionID ? <TransactionSuccess goNext={goNext} transactionID={transactionID} /> : <div className='payment-container'>
                    <div className='payment-Header'>
                        <span>Please pay the amount of {subString}</span>
                    </div>
                    {clientToken ? <><DropIn
                        options={{ authorization: clientToken, card: { cardholderName: true } }}
                        onInstance={(instanceData) => setInstance(instanceData)}
                    />
                        <button onClick={onPurchase}>{loading ? 'Paying...' : 'Pay'}</button>
                    </> :
                        <div><h1>Please wait ...</h1></div>
                    }
                </div>}
                </>
            }
        </div>
    )
}

export default Payment