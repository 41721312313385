import React, { useContext, useEffect } from 'react'
import Stack from '@mui/material/Stack';
import './style.scss'
import { titleFromParams } from '../../Utilities/helperFunction';
import appContext from '../../appContext';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs from 'dayjs';
import { InputAdornment } from '@mui/material';

function DateTimePicker({ mock, index, rightIcon, setValue, maintainValues, setMaintainValues, initialValues, type }) {
    const { setCustomStyles } = useContext(appContext)

    useEffect(() => {
        setCustomStyles(mock?.styles || {})
    }, [])

    const disableDays = (date, moc) => {
        const day = date?.day();
        if (moc?.rules?.showDate?.length) {
            return !moc?.rules?.showDate?.includes(day)
        } else {
            return false
        }
    };

    const getRuleDate = (rule) => {
        try {
            switch (rule.key) {
                case '<':
                    return dayjs().subtract(Number(rule.value), 'day')
                case '>':
                    return dayjs().add(Number(rule.value), 'day')
                case '=':
                    return dayjs()
                default:
                    return null
            }
        } catch (e) {
            console.error('error in getRuleDate', e)
        }
    }

    const handleMinMax = (type, rules) => {
        try {
            if (type === 'min') {
                return rules?.minDate ? dayjs(rules?.minDate) : rules?.minRule?.key ? getRuleDate(rules?.minRule) : null
            } else {
                return rules?.maxDate ? dayjs(rules?.maxDate) : rules?.maxRule?.key ? getRuleDate(rules?.maxRule) : null
            }
        } catch (e) {
            console.error('error in handleMinMax', e)
        }

    }

    return (
        <div className='datetimepicker-container'>
            <div className='input-title'>
                <h1 className='title'><span>{index}
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                        <path
                            d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                    </svg>

                </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /></h1>
                <div>
                    <p>{mock?.description}</p>
                </div>
            </div>
            <Stack noValidate spacing={1}>
                {mock?.options?.map((moc, i) => {
                    let name = ''
                    if (initialValues && initialValues[index - 1] && initialValues[index - 1]?.length) {
                        name = initialValues[index - 1]
                    }

                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs} key={moc?.label + i}>
                            {type === "date" ?
                                <DatePicker
                                    label={moc?.label}
                                    id={moc?.label}
                                    className='date-range-picker'
                                    onChange={(newValue) => { setValue(name, newValue); setMaintainValues({ ...maintainValues, [`${moc.name}`]: newValue }) }}
                                    shouldDisableDate={(date) => disableDays(date, moc)}
                                    defaultValue={maintainValues[moc.name]}
                                    minDate={handleMinMax('min', moc?.rules)}
                                    maxDate={handleMinMax('max', moc?.rules)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <DateRangeIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                :
                                <MuiDateTimePicker
                                    label={moc?.label}
                                    id={moc?.label}
                                    className='date-range-picker'
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    shouldDisableDate={(date) => disableDays(date, moc)}
                                    onChange={(newValue) => { setValue(name, newValue); setMaintainValues({ ...maintainValues, [`${moc.name}`]: newValue }) }}
                                    defaultValue={maintainValues[moc.name]}
                                    minDate={handleMinMax('min', moc?.rules)}
                                    maxDate={handleMinMax('max', moc?.rules)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <DateRangeIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                        </LocalizationProvider>
                    )
                })}
            </Stack>
        </div>
    )
}

export default DateTimePicker