import React, { useContext, useEffect } from "react";
import "./style.scss";
import { titleFromParams } from "../../Utilities/helperFunction";
import appContext from "../../appContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";

function CustomTimePicker({
  mock,
  index,
  setValue,
  maintainValues,
  setMaintainValues,
  initialValues,
  setScrollDisable,
}) {
  const { setCustomStyles } = useContext(appContext);

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="datetimepicker-container">
      <div className="input-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div
            dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }}
          />
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      <Stack noValidate spacing={1} className="time-picker-container">
        {mock?.options?.map((moc, i) => {
          let name = "";
          if (
            initialValues &&
            initialValues[index - 1] &&
            initialValues[index - 1]?.length
          ) {
            name = initialValues[index - 1];
          }

          return (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              key={moc?.label + i}
            >
              <div className="each-time-picker">
                {moc?.label && <div className="label">{moc?.label}</div>}
                <TimePicker
                  id={moc?.label}
                  className="date-range-picker"
                  onFocus={() => setScrollDisable(true)}
                  onBlur={() => setScrollDisable(false)}
                  ampm={false}
                  maxTime={
                    moc.rules?.maxTime ? moment(moc.rules?.maxTime) : null
                  }
                  minTime={
                    moc.rules?.minTime ? moment(moc.rules?.minTime) : null
                  }
                  onChange={(newValue) => {
                    setValue(name, newValue);
                    setMaintainValues({
                      ...maintainValues,
                      [`${moc.name}`]: newValue,
                    });
                  }}
                  defaultValue={maintainValues[moc.name]}
                />
              </div>
            </LocalizationProvider>
          );
        })}
      </Stack>
    </div>
  );
}

export default CustomTimePicker;
