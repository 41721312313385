import Paper from "@material-ui/core/Paper";

export const currency_symbols = (currency) => {

    switch (currency) {
        case 'USD': // US Dollar
            return '$';
        case 'EUR': // Euro
            return '€';
        case 'CRC': // Costa Rican Colón
            return '₡';
        case 'GBP': // British Pound Sterling
            return '£';
        case 'ILS': // Israeli New Sheqel
            return '₪';
        case 'INR': // Indian Rupee
            return '₹';
        case 'JPY': // Japanese Yen
            return '¥';
        case 'KRW': // South Korean Won
            return '₩';
        case 'NGN': // Nigerian Naira
            return '₦';
        case 'PHP': // Philippine Peso
            return '₱';
        case 'PLN': // Polish Zloty
            return 'zł';
        case 'PYG': // Paraguayan Guarani
            return '₲';
        case 'THB': // Thai Baht
            return '฿';
        case 'UAH': // Ukrainian Hryvnia
            return '₴';
        case 'VND': // Vietnamese Dong
            return '₫';
        default:
            return currency;

    }
};

export const imageSupportedtypes = ["image/png", "image/jpeg", "image/jpeg"]

export const cmsNames = ["PrestaShop", "Magento 1", "OpenCart", "WooCommerce", "CS Cart", "Drupal", "Magento 2", "Shopify", "Bitrix", "BigCommerce", "Wix", "Squarespace", "Big Cartel", "Weebly", "3dcart", "Volusion"]

export const marketingServices = ["Voice for Mobile Services", "Voice for Landline Services", "Telegram Services", "WhatsApp Services", "Viber Services", "Facebook Messenger Services", "Push notification Services", "Email Services", "SMS Services"]

export const radioArr = ["quotation2", "quotation3", "quotation4", "quotation5", "quotation6", "quotationSocial", "quotation7", 'quotation8', 'quotation9', 'quotation10', 'quotation11']

export const skipRequired = ["next", "payment", "text_api", "appointment", "google", "openproject"]

export const inputFields = ["input", "phoneNumber", "multi-input", "currency", "date", "dateAndTime", "time"]

export const thankyouPage = `<div class='thankyou-default-container'><div>Thank you</div></div>`

export const keyExists = (obj, key) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
        return false;
    }
    else if (obj.hasOwnProperty(key)) {
        return obj[key];
    }
    else if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            const result = keyExists(obj[i], key);
            if (result) {
                return result;
            }
        }
    }
    else {
        for (const k in obj) {
            const result = keyExists(obj[k], key);
            if (result) {
                return result;
            }
        }
    }

    return false;
};

export const DayLightTimezone = [
    { label: "(GMT-11) Pacific/Midway" },
    { label: "(GMT-11) Pacific/Niue" },
    { label: "(GMT-11) Pacific/Pago Pago" },
    { label: "(GMT-10) Pacific/Honolulu" },
    { label: "(GMT-10) Pacific/Rarotonga" },
    { label: "(GMT-10) Pacific/Tahiti" },
    { label: "(GMT-9.5) Pacific/Marquesas" },
    { label: "(GMT-9) America/Adak" },
    { label: "(GMT-9) Pacific/Gambier" },
    { label: "(GMT-8) America/Anchorage" },
    { label: "(GMT-8) America/Juneau" },
    { label: "(GMT-8) America/Metlakatla" },
    { label: "(GMT-8) America/Nome" },
    { label: "(GMT-8) America/Sitka" },
    { label: "(GMT-8) America/Yakutat" },
    { label: "(GMT-8) Pacific/Pitcairn" },
    { label: "(GMT-7) America/Chihuahua" },
    { label: "(GMT-7) America/Creston" },
    { label: "(GMT-7) America/Dawson" },
    { label: "(GMT-7) America/Dawson Creek" },
    { label: "(GMT-7) America/Fort Nelson" },
    { label: "(GMT-7) America/Hermosillo" },
    { label: "(GMT-7) America/Los Angeles" },
    { label: "(GMT-7) America/Mazatlan" },
    { label: "(GMT-7) America/Phoenix" },
    { label: "(GMT-7) America/Tijuana" },
    { label: "(GMT-7) America/Vancouver" },
    { label: "(GMT-7) America/Whitehorse" },
    { label: "(GMT-6) America/Bahia Banderas" },
    { label: "(GMT-6) America/Belize" },
    { label: "(GMT-6) America/Boise" },
    { label: "(GMT-6) America/Cambridge Bay" },
    { label: "(GMT-6) America/Costa Rica" },
    { label: "(GMT-6) America/Denver" },
    { label: "(GMT-6) America/Edmonton" },
    { label: "(GMT-6) America/El Salvador" },
    { label: "(GMT-6) America/Guatemala" },
    { label: "(GMT-6) America/Inuvik" },
    { label: "(GMT-6) America/Managua" },
    { label: "(GMT-6) America/Merida" },
    { label: "(GMT-6) America/Mexico City" },
    { label: "(GMT-6) America/Monterrey" },
    { label: "(GMT-6) America/Ojinaga" },
    { label: "(GMT-6) America/Regina" },
    { label: "(GMT-6) America/Swift Current" },
    { label: "(GMT-6) America/Tegucigalpa" },
    { label: "(GMT-6) America/Yellowknife" },
    { label: "(GMT-6) Pacific/Galapagos" },
    { label: "(GMT-5) America/Atikokan" },
    { label: "(GMT-5) America/Bogota" },
    { label: "(GMT-5) America/Cancun" },
    { label: "(GMT-5) America/Cayman" },
    { label: "(GMT-5) America/Chicago" },
    { label: "(GMT-5) America/Eirunepe" },
    { label: "(GMT-5) America/Guayaquil" },
    { label: "(GMT-5) America/Indiana/Knox" },
    { label: "(GMT-5) America/Indiana/Tell City" },
    { label: "(GMT-5) America/Jamaica" },
    { label: "(GMT-5) America/Lima" },
    { label: "(GMT-5) America/Matamoros" },
    { label: "(GMT-5) America/Menominee" },
    { label: "(GMT-5) America/North Dakota/Beulah" },
    { label: "(GMT-5) America/North Dakota/Center" },
    { label: "(GMT-5) America/North Dakota/New Salem" },
    { label: "(GMT-5) America/Panama" },
    { label: "(GMT-5) America/Rainy River" },
    { label: "(GMT-5) America/Rankin Inlet" },
    { label: "(GMT-5) America/Resolute" },
    { label: "(GMT-5) America/Rio Branco" },
    { label: "(GMT-5) America/Winnipeg" },
    { label: "(GMT-5) Pacific/Easter" },
    { label: "(GMT-4) America/Anguilla" },
    { label: "(GMT-4) America/Antigua" },
    { label: "(GMT-4) America/Aruba" },
    { label: "(GMT-4) America/Asuncion" },
    { label: "(GMT-4) America/Barbados" },
    { label: "(GMT-4) America/Blanc-Sablon" },
    { label: "(GMT-4) America/Boa Vista" },
    { label: "(GMT-4) America/Campo Grande" },
    { label: "(GMT-4) America/Caracas" },
    { label: "(GMT-4) America/Cuiaba" },
    { label: "(GMT-4) America/Curacao" },
    { label: "(GMT-4) America/Detroit" },
    { label: "(GMT-4) America/Dominica" },
    { label: "(GMT-4) America/Grand Turk" },
    { label: "(GMT-4) America/Grenada" },
    { label: "(GMT-4) America/Guadeloupe" },
    { label: "(GMT-4) America/Guyana" },
    { label: "(GMT-4) America/Havana" },
    { label: "(GMT-4) America/Indiana/Indianapolis" },
    { label: "(GMT-4) America/Indiana/Marengo" },
    { label: "(GMT-4) America/Indiana/Petersburg" },
    { label: "(GMT-4) America/Indiana/Vevay" },
    { label: "(GMT-4) America/Indiana/Vincennes" },
    { label: "(GMT-4) America/Indiana/Winamac" },
    { label: "(GMT-4) America/Iqaluit" },
    { label: "(GMT-4) America/Kentucky/Louisville" },
    { label: "(GMT-4) America/Kentucky/Monticello" },
    { label: "(GMT-4) America/Kralendijk" },
    { label: "(GMT-4) America/La Paz" },
    { label: "(GMT-4) America/Lower Princes" },
    { label: "(GMT-4) America/Manaus" },
    { label: "(GMT-4) America/Marigot" },
    { label: "(GMT-4) America/Martinique" },
    { label: "(GMT-4) America/Montserrat" },
    { label: "(GMT-4) America/Nassau" },
    { label: "(GMT-4) America/New York" },
    { label: "(GMT-4) America/Nipigon" },
    { label: "(GMT-4) America/Pangnirtung" },
    { label: "(GMT-4) America/Port of Spain" },
    { label: "(GMT-4) America/Port-au-Prince" },
    { label: "(GMT-4) America/Porto Velho" },
    { label: "(GMT-4) America/Puerto Rico" },
    { label: "(GMT-4) America/Santo Domingo" },
    { label: "(GMT-4) America/St Barthelemy" },
    { label: "(GMT-4) America/St Kitts" },
    { label: "(GMT-4) America/St Lucia" },
    { label: "(GMT-4) America/St Thomas" },
    { label: "(GMT-4) America/St Vincent" },
    { label: "(GMT-4) America/Thunder Bay" },
    { label: "(GMT-4) America/Toronto" },
    { label: "(GMT-4) America/Tortola" },
    { label: "(GMT-3) America/Araguaina" },
    { label: "(GMT-3) America/Argentina/Buenos Aires" },
    { label: "(GMT-3) America/Argentina/Catamarca" },
    { label: "(GMT-3) America/Argentina/Cordoba" },
    { label: "(GMT-3) America/Argentina/Jujuy" },
    { label: "(GMT-3) America/Argentina/La Rioja" },
    { label: "(GMT-3) America/Argentina/Mendoza" },
    { label: "(GMT-3) America/Argentina/Rio Gallegos" },
    { label: "(GMT-3) America/Argentina/Salta" },
    { label: "(GMT-3) America/Argentina/San Juan" },
    { label: "(GMT-3) America/Argentina/San Luis" },
    { label: "(GMT-3) America/Argentina/Tucuman" },
    { label: "(GMT-3) America/Argentina/Ushuaia" },
    { label: "(GMT-3) America/Bahia" },
    { label: "(GMT-3) America/Belem" },
    { label: "(GMT-3) America/Cayenne" },
    { label: "(GMT-3) America/Fortaleza" },
    { label: "(GMT-3) America/Glace Bay" },
    { label: "(GMT-3) America/Goose Bay" },
    { label: "(GMT-3) America/Halifax" },
    { label: "(GMT-3) America/Maceio" },
    { label: "(GMT-3) America/Moncton" },
    { label: "(GMT-3) America/Montevideo" },
    { label: "(GMT-3) America/Paramaribo" },
    { label: "(GMT-3) America/Punta Arenas" },
    { label: "(GMT-3) America/Recife" },
    { label: "(GMT-3) America/Santarem" },
    { label: "(GMT-3) America/Santiago" },
    { label: "(GMT-3) America/Sao Paulo" },
    { label: "(GMT-3) America/Thule" },
    { label: "(GMT-3) Antarctica/Palmer" },
    { label: "(GMT-3) Antarctica/Rothera" },
    { label: "(GMT-3) Atlantic/Bermuda" },
    { label: "(GMT-3) Atlantic/Stanley" },
    { label: "(GMT-2.5) America/St Johns" },
    { label: "(GMT-2) America/Miquelon" },
    { label: "(GMT-2) America/Noronha" },
    { label: "(GMT-2) America/Nuuk" },
    { label: "(GMT-2) Atlantic/South Georgia" },
    { label: "(GMT-1) Atlantic/Cape Verde" },
    { label: "(GMT+0) Africa/Abidjan" },
    { label: "(GMT+0) Africa/Accra" },
    { label: "(GMT+0) Africa/Bamako" },
    { label: "(GMT+0) Africa/Banjul" },
    { label: "(GMT+0) Africa/Bissau" },
    { label: "(GMT+0) Africa/Conakry" },
    { label: "(GMT+0) Africa/Dakar" },
    { label: "(GMT+0) Africa/Freetown" },
    { label: "(GMT+0) Africa/Lome" },
    { label: "(GMT+0) Africa/Monrovia" },
    { label: "(GMT+0) Africa/Nouakchott" },
    { label: "(GMT+0) Africa/Ouagadougou" },
    { label: "(GMT+0) Africa/Sao Tome" },
    { label: "(GMT+0) America/Danmarkshavn" },
    { label: "(GMT+0) America/Scoresbysund" },
    { label: "(GMT+0) Atlantic/Azores" },
    { label: "(GMT+0) Atlantic/Reykjavik" },
    { label: "(GMT+0) Atlantic/St Helena" },
    { label: "(GMT+1) Africa/Algiers" },
    { label: "(GMT+1) Africa/Bangui" },
    { label: "(GMT+1) Africa/Brazzaville" },
    { label: "(GMT+1) Africa/Casablanca" },
    { label: "(GMT+1) Africa/Douala" },
    { label: "(GMT+1) Africa/El Aaiun" },
    { label: "(GMT+1) Africa/Kinshasa" },
    { label: "(GMT+1) Africa/Lagos" },
    { label: "(GMT+1) Africa/Libreville" },
    { label: "(GMT+1) Africa/Luanda" },
    { label: "(GMT+1) Africa/Malabo" },
    { label: "(GMT+1) Africa/Ndjamena" },
    { label: "(GMT+1) Africa/Niamey" },
    { label: "(GMT+1) Africa/Porto-Novo" },
    { label: "(GMT+1) Africa/Tunis" },
    { label: "(GMT+1) Atlantic/Canary" },
    { label: "(GMT+1) Atlantic/Faroe" },
    { label: "(GMT+1) Atlantic/Madeira" },
    { label: "(GMT+1) Europe/Dublin" },
    { label: "(GMT+1) Europe/Guernsey" },
    { label: "(GMT+1) Europe/Isle of Man" },
    { label: "(GMT+1) Europe/Jersey" },
    { label: "(GMT+1) Europe/Lisbon" },
    { label: "(GMT+1) Europe/London" },
    { label: "(GMT+2) Africa/Blantyre" },
    { label: "(GMT+2) Africa/Bujumbura" },
    { label: "(GMT+2) Africa/Cairo" },
    { label: "(GMT+2) Africa/Ceuta" },
    { label: "(GMT+2) Africa/Gaborone" },
    { label: "(GMT+2) Africa/Harare" },
    { label: "(GMT+2) Africa/Johannesburg" },
    { label: "(GMT+2) Africa/Khartoum" },
    { label: "(GMT+2) Africa/Kigali" },
    { label: "(GMT+2) Africa/Lubumbashi" },
    { label: "(GMT+2) Africa/Lusaka" },
    { label: "(GMT+2) Africa/Maputo" },
    { label: "(GMT+2) Africa/Maseru" },
    { label: "(GMT+2) Africa/Mbabane" },
    { label: "(GMT+2) Africa/Tripoli" },
    { label: "(GMT+2) Africa/Windhoek" },
    { label: "(GMT+2) Antarctica/Troll" },
    { label: "(GMT+2) Arctic/Longyearbyen" },
    { label: "(GMT+2) Europe/Amsterdam" },
    { label: "(GMT+2) Europe/Andorra" },
    { label: "(GMT+2) Europe/Belgrade" },
    { label: "(GMT+2) Europe/Berlin" },
    { label: "(GMT+2) Europe/Bratislava" },
    { label: "(GMT+2) Europe/Brussels" },
    { label: "(GMT+2) Europe/Budapest" },
    { label: "(GMT+2) Europe/Busingen" },
    { label: "(GMT+2) Europe/Copenhagen" },
    { label: "(GMT+2) Europe/Gibraltar" },
    { label: "(GMT+2) Europe/Kaliningrad" },
    { label: "(GMT+2) Europe/Ljubljana" },
    { label: "(GMT+2) Europe/Luxembourg" },
    { label: "(GMT+2) Europe/Madrid" },
    { label: "(GMT+2) Europe/Malta" },
    { label: "(GMT+2) Europe/Monaco" },
    { label: "(GMT+2) Europe/Oslo" },
    { label: "(GMT+2) Europe/Paris" },
    { label: "(GMT+2) Europe/Podgorica" },
    { label: "(GMT+2) Europe/Prague" },
    { label: "(GMT+2) Europe/Rome" },
    { label: "(GMT+2) Europe/San Marino" },
    { label: "(GMT+2) Europe/Sarajevo" },
    { label: "(GMT+2) Europe/Skopje" },
    { label: "(GMT+2) Europe/Stockholm" },
    { label: "(GMT+2) Europe/Tirane" },
    { label: "(GMT+2) Europe/Vaduz" },
    { label: "(GMT+2) Europe/Vatican" },
    { label: "(GMT+2) Europe/Vienna" },
    { label: "(GMT+2) Europe/Warsaw" },
    { label: "(GMT+2) Europe/Zagreb" },
    { label: "(GMT+2) Europe/Zurich" },
    { label: "(GMT+3) Africa/Addis Ababa" },
    { label: "(GMT+3) Africa/Asmara" },
    { label: "(GMT+3) Africa/Dar es Salaam" },
    { label: "(GMT+3) Africa/Djibouti" },
    { label: "(GMT+3) Africa/Juba" },
    { label: "(GMT+3) Africa/Kampala" },
    { label: "(GMT+3) Africa/Mogadishu" },
    { label: "(GMT+3) Africa/Nairobi" },
    { label: "(GMT+3) Antarctica/Syowa" },
    { label: "(GMT+3) Asia/Aden" },
    { label: "(GMT+3) Asia/Amman" },
    { label: "(GMT+3) Asia/Baghdad" },
    { label: "(GMT+3) Asia/Bahrain" },
    { label: "(GMT+3) Asia/Beirut" },
    { label: "(GMT+3) Asia/Damascus" },
    { label: "(GMT+3) Asia/Famagusta" },
    { label: "(GMT+3) Asia/Gaza" },
    { label: "(GMT+3) Asia/Hebron" },
    { label: "(GMT+3) Asia/Jerusalem" },
    { label: "(GMT+3) Asia/Kuwait" },
    { label: "(GMT+3) Asia/Nicosia" },
    { label: "(GMT+3) Asia/Qatar" },
    { label: "(GMT+3) Asia/Riyadh" },
    { label: "(GMT+3) Europe/Athens" },
    { label: "(GMT+3) Europe/Bucharest" },
    { label: "(GMT+3) Europe/Chisinau" },
    { label: "(GMT+3) Europe/Helsinki" },
    { label: "(GMT+3) Europe/Istanbul" },
    { label: "(GMT+3) Europe/Kirov" },
    { label: "(GMT+3) Europe/Kyiv" },
    { label: "(GMT+3) Europe/Mariehamn" },
    { label: "(GMT+3) Europe/Minsk" },
    { label: "(GMT+3) Europe/Moscow" },
    { label: "(GMT+3) Europe/Riga" },
    { label: "(GMT+3) Europe/Simferopol" },
    { label: "(GMT+3) Europe/Sofia" },
    { label: "(GMT+3) Europe/Tallinn" },
    { label: "(GMT+3) Europe/Uzhgorod" },
    { label: "(GMT+3) Europe/Vilnius" },
    { label: "(GMT+3) Europe/Zaporozhye" },
    { label: "(GMT+3) Indian/Antananarivo" },
    { label: "(GMT+3) Indian/Comoro" },
    { label: "(GMT+3) Indian/Mayotte" },
    { label: "(GMT+4) Asia/Baku" },
    { label: "(GMT+4) Asia/Dubai" },
    { label: "(GMT+4) Asia/Muscat" },
    { label: "(GMT+4) Asia/Tbilisi" },
    { label: "(GMT+4) Asia/Yerevan" },
    { label: "(GMT+4) Europe/Astrakhan" },
    { label: "(GMT+4) Europe/Samara" },
    { label: "(GMT+4) Europe/Saratov" },
    { label: "(GMT+4) Europe/Ulyanovsk" },
    { label: "(GMT+4) Europe/Volgograd" },
    { label: "(GMT+4) Indian/Mahe" },
    { label: "(GMT+4) Indian/Mauritius" },
    { label: "(GMT+4) Indian/Reunion" },
    { label: "(GMT+4.5) Asia/Kabul" },
    { label: "(GMT+4.5) Asia/Tehran" },
    { label: "(GMT+5) Antarctica/Mawson" },
    { label: "(GMT+5) Asia/Aqtau" },
    { label: "(GMT+5) Asia/Aqtobe" },
    { label: "(GMT+5) Asia/Ashgabat" },
    { label: "(GMT+5) Asia/Atyrau" },
    { label: "(GMT+5) Asia/Dushanbe" },
    { label: "(GMT+5) Asia/Karachi" },
    { label: "(GMT+5) Asia/Oral" },
    { label: "(GMT+5) Asia/Qyzylorda" },
    { label: "(GMT+5) Asia/Samarkand" },
    { label: "(GMT+5) Asia/Tashkent" },
    { label: "(GMT+5) Asia/Yekaterinburg" },
    { label: "(GMT+5) Indian/Kerguelen" },
    { label: "(GMT+5) Indian/Maldives" },
    { label: "(GMT+5.5) Asia/Colombo" },
    { label: "(GMT+5.5) Asia/Kolkata" },
    { label: "(GMT+5.75) Asia/Kathmandu" },
    { label: "(GMT+6) Antarctica/Vostok" },
    { label: "(GMT+6) Asia/Almaty" },
    { label: "(GMT+6) Asia/Bishkek" },
    { label: "(GMT+6) Asia/Dhaka" },
    { label: "(GMT+6) Asia/Omsk" },
    { label: "(GMT+6) Asia/Qostanay" },
    { label: "(GMT+6) Asia/Thimphu" },
    { label: "(GMT+6) Asia/Urumqi" },
    { label: "(GMT+6) Indian/Chagos" },
    { label: "(GMT+6.5) Asia/Yangon" },
    { label: "(GMT+6.5) Indian/Cocos" },
    { label: "(GMT+7) Antarctica/Davis" },
    { label: "(GMT+7) Asia/Bangkok" },
    { label: "(GMT+7) Asia/Barnaul" },
    { label: "(GMT+7) Asia/Ho Chi Minh" },
    { label: "(GMT+7) Asia/Hovd" },
    { label: "(GMT+7) Asia/Jakarta" },
    { label: "(GMT+7) Asia/Krasnoyarsk" },
    { label: "(GMT+7) Asia/Novokuznetsk" },
    { label: "(GMT+7) Asia/Novosibirsk" },
    { label: "(GMT+7) Asia/Phnom Penh" },
    { label: "(GMT+7) Asia/Pontianak" },
    { label: "(GMT+7) Asia/Tomsk" },
    { label: "(GMT+7) Asia/Vientiane" },
    { label: "(GMT+7) Indian/Christmas" },
    { label: "(GMT+8) Antarctica/Casey" },
    { label: "(GMT+8) Asia/Brunei" },
    { label: "(GMT+8) Asia/Choibalsan" },
    { label: "(GMT+8) Asia/Hong Kong" },
    { label: "(GMT+8) Asia/Irkutsk" },
    { label: "(GMT+8) Asia/Kuala Lumpur" },
    { label: "(GMT+8) Asia/Kuching" },
    { label: "(GMT+8) Asia/Macau" },
    { label: "(GMT+8) Asia/Makassar" },
    { label: "(GMT+8) Asia/Manila" },
    { label: "(GMT+8) Asia/Shanghai" },
    { label: "(GMT+8) Asia/Singapore" },
    { label: "(GMT+8) Asia/Taipei" },
    { label: "(GMT+8) Asia/Ulaanbaatar" },
    { label: "(GMT+8) Australia/Perth" },
    { label: "(GMT+8.75) Australia/Eucla" },
    { label: "(GMT+9) Asia/Chita" },
    { label: "(GMT+9) Asia/Dili" },
    { label: "(GMT+9) Asia/Jayapura" },
    { label: "(GMT+9) Asia/Khandyga" },
    { label: "(GMT+9) Asia/Pyongyang" },
    { label: "(GMT+9) Asia/Seoul" },
    { label: "(GMT+9) Asia/Tokyo" },
    { label: "(GMT+9) Asia/Yakutsk" },
    { label: "(GMT+9) Pacific/Palau" },
    { label: "(GMT+9.5) Australia/Darwin" },
    { label: "(GMT+10) Antarctica/DumontDUrville" },
    { label: "(GMT+10) Asia/Ust-Nera" },
    { label: "(GMT+10) Asia/Vladivostok" },
    { label: "(GMT+10) Australia/Brisbane" },
    { label: "(GMT+10) Australia/Lindeman" },
    { label: "(GMT+10) Pacific/Chuuk" },
    { label: "(GMT+10) Pacific/Guam" },
    { label: "(GMT+10) Pacific/Port Moresby" },
    { label: "(GMT+10) Pacific/Saipan" },
    { label: "(GMT+10.5) Australia/Adelaide" },
    { label: "(GMT+10.5) Australia/Broken Hill" },
    { label: "(GMT+11) Antarctica/Macquarie" },
    { label: "(GMT+11) Asia/Magadan" },
    { label: "(GMT+11) Asia/Sakhalin" },
    { label: "(GMT+11) Asia/Srednekolymsk" },
    { label: "(GMT+11) Australia/Hobart" },
    { label: "(GMT+11) Australia/Lord Howe" },
    { label: "(GMT+11) Australia/Melbourne" },
    { label: "(GMT+11) Australia/Sydney" },
    { label: "(GMT+11) Pacific/Bougainville" },
    { label: "(GMT+11) Pacific/Efate" },
    { label: "(GMT+11) Pacific/Guadalcanal" },
    { label: "(GMT+11) Pacific/Kosrae" },
    { label: "(GMT+11) Pacific/Norfolk" },
    { label: "(GMT+11) Pacific/Noumea" },
    { label: "(GMT+11) Pacific/Pohnpei" },
    { label: "(GMT+12) Asia/Anadyr" },
    { label: "(GMT+12) Asia/Kamchatka" },
    { label: "(GMT+12) Pacific/Fiji" },
    { label: "(GMT+12) Pacific/Funafuti" },
    { label: "(GMT+12) Pacific/Kwajalein" },
    { label: "(GMT+12) Pacific/Majuro" },
    { label: "(GMT+12) Pacific/Nauru" },
    { label: "(GMT+12) Pacific/Tarawa" },
    { label: "(GMT+12) Pacific/Wake" },
    { label: "(GMT+12) Pacific/Wallis" },
    { label: "(GMT+13) Antarctica/McMurdo" },
    { label: "(GMT+13) Pacific/Auckland" },
    { label: "(GMT+13) Pacific/Fakaofo" },
    { label: "(GMT+13) Pacific/Kanton" },
    { label: "(GMT+13) Pacific/Tongatapu" },
    { label: "(GMT+13.75) Pacific/Chatham" },
    { label: "(GMT+14) Pacific/Apia" },
    { label: "(GMT+14) Pacific/Kiritimati" }
]

export const StandardTimeZone = [
    { label: "(GMT-11) Pacific/Midway" },
    { label: "(GMT-11) Pacific/Niue" },
    { label: "(GMT-11) Pacific/Pago Pago" },
    { label: "(GMT-10) America/Adak" },
    { label: "(GMT-10) Pacific/Honolulu" },
    { label: "(GMT-10) Pacific/Rarotonga" },
    { label: "(GMT-10) Pacific/Tahiti" },
    { label: "(GMT-9.5) Pacific/Marquesas" },
    { label: "(GMT-9) America/Anchorage" },
    { label: "(GMT-9) America/Juneau" },
    { label: "(GMT-9) America/Metlakatla" },
    { label: "(GMT-9) America/Nome" },
    { label: "(GMT-9) America/Sitka" },
    { label: "(GMT-9) America/Yakutat" },
    { label: "(GMT-9) Pacific/Gambier" },
    { label: "(GMT-8) America/Dawson" },
    { label: "(GMT-8) America/Los Angeles" },
    { label: "(GMT-8) America/Tijuana" },
    { label: "(GMT-8) America/Vancouver" },
    { label: "(GMT-8) America/Whitehorse" },
    { label: "(GMT-8) Pacific/Pitcairn" },
    { label: "(GMT-7) America/Boise" },
    { label: "(GMT-7) America/Cambridge Bay" },
    { label: "(GMT-7) America/Chihuahua" },
    { label: "(GMT-7) America/Creston" },
    { label: "(GMT-7) America/Dawson Creek" },
    { label: "(GMT-7) America/Denver" },
    { label: "(GMT-7) America/Edmonton" },
    { label: "(GMT-7) America/Fort Nelson" },
    { label: "(GMT-7) America/Hermosillo" },
    { label: "(GMT-7) America/Inuvik" },
    { label: "(GMT-7) America/Mazatlan" },
    { label: "(GMT-7) America/Ojinaga" },
    { label: "(GMT-7) America/Phoenix" },
    { label: "(GMT-7) America/Yellowknife" },
    { label: "(GMT-6) America/Bahia Banderas" },
    { label: "(GMT-6) America/Belize" },
    { label: "(GMT-6) America/Chicago" },
    { label: "(GMT-6) America/Costa Rica" },
    { label: "(GMT-6) America/El Salvador" },
    { label: "(GMT-6) America/Guatemala" },
    { label: "(GMT-6) America/Indiana/Knox" },
    { label: "(GMT-6) America/Indiana/Tell City" },
    { label: "(GMT-6) America/Managua" },
    { label: "(GMT-6) America/Matamoros" },
    { label: "(GMT-6) America/Menominee" },
    { label: "(GMT-6) America/Merida" },
    { label: "(GMT-6) America/Mexico City" },
    { label: "(GMT-6) America/Monterrey" },
    { label: "(GMT-6) America/North Dakota/Beulah" },
    { label: "(GMT-6) America/North Dakota/Center" },
    { label: "(GMT-6) America/North Dakota/New Salem" },
    { label: "(GMT-6) America/Rainy River" },
    { label: "(GMT-6) America/Rankin Inlet" },
    { label: "(GMT-6) America/Regina" },
    { label: "(GMT-6) America/Resolute" },
    { label: "(GMT-6) America/Swift Current" },
    { label: "(GMT-6) America/Tegucigalpa" },
    { label: "(GMT-6) America/Winnipeg" },
    { label: "(GMT-6) Pacific/Galapagos" },
    { label: "(GMT-5) America/Atikokan" },
    { label: "(GMT-5) America/Bogota" },
    { label: "(GMT-5) America/Cancun" },
    { label: "(GMT-5) America/Cayman" },
    { label: "(GMT-5) America/Detroit" },
    { label: "(GMT-5) America/Eirunepe" },
    { label: "(GMT-5) America/Grand Turk" },
    { label: "(GMT-5) America/Guayaquil" },
    { label: "(GMT-5) America/Havana" },
    { label: "(GMT-5) America/Indiana/Indianapolis" },
    { label: "(GMT-5) America/Indiana/Marengo" },
    { label: "(GMT-5) America/Indiana/Petersburg" },
    { label: "(GMT-5) America/Indiana/Vevay" },
    { label: "(GMT-5) America/Indiana/Vincennes" },
    { label: "(GMT-5) America/Indiana/Winamac" },
    { label: "(GMT-5) America/Iqaluit" },
    { label: "(GMT-5) America/Jamaica" },
    { label: "(GMT-5) America/Kentucky/Louisville" },
    { label: "(GMT-5) America/Kentucky/Monticello" },
    { label: "(GMT-5) America/Lima" },
    { label: "(GMT-5) America/Nassau" },
    { label: "(GMT-5) America/New York" },
    { label: "(GMT-5) America/Nipigon" },
    { label: "(GMT-5) America/Panama" },
    { label: "(GMT-5) America/Pangnirtung" },
    { label: "(GMT-5) America/Port-au-Prince" },
    { label: "(GMT-5) America/Rio Branco" },
    { label: "(GMT-5) America/Thunder Bay" },
    { label: "(GMT-5) America/Toronto" },
    { label: "(GMT-5) Pacific/Easter" },
    { label: "(GMT-4) America/Anguilla" },
    { label: "(GMT-4) America/Antigua" },
    { label: "(GMT-4) America/Aruba" },
    { label: "(GMT-4) America/Barbados" },
    { label: "(GMT-4) America/Blanc-Sablon" },
    { label: "(GMT-4) America/Boa Vista" },
    { label: "(GMT-4) America/Campo Grande" },
    { label: "(GMT-4) America/Caracas" },
    { label: "(GMT-4) America/Cuiaba" },
    { label: "(GMT-4) America/Curacao" },
    { label: "(GMT-4) America/Dominica" },
    { label: "(GMT-4) America/Glace Bay" },
    { label: "(GMT-4) America/Goose Bay" },
    { label: "(GMT-4) America/Grenada" },
    { label: "(GMT-4) America/Guadeloupe" },
    { label: "(GMT-4) America/Guyana" },
    { label: "(GMT-4) America/Halifax" },
    { label: "(GMT-4) America/Kralendijk" },
    { label: "(GMT-4) America/La Paz" },
    { label: "(GMT-4) America/Lower Princes" },
    { label: "(GMT-4) America/Manaus" },
    { label: "(GMT-4) America/Marigot" },
    { label: "(GMT-4) America/Martinique" },
    { label: "(GMT-4) America/Moncton" },
    { label: "(GMT-4) America/Montserrat" },
    { label: "(GMT-4) America/Port of Spain" },
    { label: "(GMT-4) America/Porto Velho" },
    { label: "(GMT-4) America/Puerto Rico" },
    { label: "(GMT-4) America/Santo Domingo" },
    { label: "(GMT-4) America/St Barthelemy" },
    { label: "(GMT-4) America/St Kitts" },
    { label: "(GMT-4) America/St Lucia" },
    { label: "(GMT-4) America/St Thomas" },
    { label: "(GMT-4) America/St Vincent" },
    { label: "(GMT-4) America/Thule" },
    { label: "(GMT-4) America/Tortola" },
    { label: "(GMT-4) Atlantic/Bermuda" },
    { label: "(GMT-3.5) America/St Johns" },
    { label: "(GMT-3) America/Araguaina" },
    { label: "(GMT-3) America/Argentina/Buenos Aires" },
    { label: "(GMT-3) America/Argentina/Catamarca" },
    { label: "(GMT-3) America/Argentina/Cordoba" },
    { label: "(GMT-3) America/Argentina/Jujuy" },
    { label: "(GMT-3) America/Argentina/La Rioja" },
    { label: "(GMT-3) America/Argentina/Mendoza" },
    { label: "(GMT-3) America/Argentina/Rio Gallegos" },
    { label: "(GMT-3) America/Argentina/Salta" },
    { label: "(GMT-3) America/Argentina/San Juan" },
    { label: "(GMT-3) America/Argentina/San Luis" },
    { label: "(GMT-3) America/Argentina/Tucuman" },
    { label: "(GMT-3) America/Argentina/Ushuaia" },
    { label: "(GMT-3) America/Asuncion" },
    { label: "(GMT-3) America/Bahia" },
    { label: "(GMT-3) America/Belem" },
    { label: "(GMT-3) America/Cayenne" },
    { label: "(GMT-3) America/Fortaleza" },
    { label: "(GMT-3) America/Maceio" },
    { label: "(GMT-3) America/Miquelon" },
    { label: "(GMT-3) America/Montevideo" },
    { label: "(GMT-3) America/Nuuk" },
    { label: "(GMT-3) America/Paramaribo" },
    { label: "(GMT-3) America/Punta Arenas" },
    { label: "(GMT-3) America/Recife" },
    { label: "(GMT-3) America/Santarem" },
    { label: "(GMT-3) America/Santiago" },
    { label: "(GMT-3) America/Sao Paulo" },
    { label: "(GMT-3) Antarctica/Palmer" },
    { label: "(GMT-3) Antarctica/Rothera" },
    { label: "(GMT-3) Atlantic/Stanley" },
    { label: "(GMT-2) America/Noronha" },
    { label: "(GMT-2) Atlantic/South Georgia" },
    { label: "(GMT-1) America/Scoresbysund" },
    { label: "(GMT-1) Atlantic/Azores" },
    { label: "(GMT-1) Atlantic/Cape Verde" },
    { label: "(GMT+0) Africa/Abidjan" },
    { label: "(GMT+0) Africa/Accra" },
    { label: "(GMT+0) Africa/Bamako" },
    { label: "(GMT+0) Africa/Banjul" },
    { label: "(GMT+0) Africa/Bissau" },
    { label: "(GMT+0) Africa/Conakry" },
    { label: "(GMT+0) Africa/Dakar" },
    { label: "(GMT+0) Africa/Freetown" },
    { label: "(GMT+0) Africa/Lome" },
    { label: "(GMT+0) Africa/Monrovia" },
    { label: "(GMT+0) Africa/Nouakchott" },
    { label: "(GMT+0) Africa/Ouagadougou" },
    { label: "(GMT+0) Africa/Sao Tome" },
    { label: "(GMT+0) America/Danmarkshavn" },
    { label: "(GMT+0) Antarctica/Troll" },
    { label: "(GMT+0) Atlantic/Canary" },
    { label: "(GMT+0) Atlantic/Faroe" },
    { label: "(GMT+0) Atlantic/Madeira" },
    { label: "(GMT+0) Atlantic/Reykjavik" },
    { label: "(GMT+0) Atlantic/St Helena" },
    { label: "(GMT+0) Europe/Dublin" },
    { label: "(GMT+0) Europe/Guernsey" },
    { label: "(GMT+0) Europe/Isle of Man" },
    { label: "(GMT+0) Europe/Jersey" },
    { label: "(GMT+0) Europe/Lisbon" },
    { label: "(GMT+0) Europe/London" },
    { label: "(GMT+1) Africa/Algiers" },
    { label: "(GMT+1) Africa/Bangui" },
    { label: "(GMT+1) Africa/Brazzaville" },
    { label: "(GMT+1) Africa/Casablanca" },
    { label: "(GMT+1) Africa/Ceuta" },
    { label: "(GMT+1) Africa/Douala" },
    { label: "(GMT+1) Africa/El Aaiun" },
    { label: "(GMT+1) Africa/Kinshasa" },
    { label: "(GMT+1) Africa/Lagos" },
    { label: "(GMT+1) Africa/Libreville" },
    { label: "(GMT+1) Africa/Luanda" },
    { label: "(GMT+1) Africa/Malabo" },
    { label: "(GMT+1) Africa/Ndjamena" },
    { label: "(GMT+1) Africa/Niamey" },
    { label: "(GMT+1) Africa/Porto-Novo" },
    { label: "(GMT+1) Africa/Tunis" },
    { label: "(GMT+1) Arctic/Longyearbyen" },
    { label: "(GMT+1) Europe/Amsterdam" },
    { label: "(GMT+1) Europe/Andorra" },
    { label: "(GMT+1) Europe/Belgrade" },
    { label: "(GMT+1) Europe/Berlin" },
    { label: "(GMT+1) Europe/Bratislava" },
    { label: "(GMT+1) Europe/Brussels" },
    { label: "(GMT+1) Europe/Budapest" },
    { label: "(GMT+1) Europe/Busingen" },
    { label: "(GMT+1) Europe/Copenhagen" },
    { label: "(GMT+1) Europe/Gibraltar" },
    { label: "(GMT+1) Europe/Ljubljana" },
    { label: "(GMT+1) Europe/Luxembourg" },
    { label: "(GMT+1) Europe/Madrid" },
    { label: "(GMT+1) Europe/Malta" },
    { label: "(GMT+1) Europe/Monaco" },
    { label: "(GMT+1) Europe/Oslo" },
    { label: "(GMT+1) Europe/Paris" },
    { label: "(GMT+1) Europe/Podgorica" },
    { label: "(GMT+1) Europe/Prague" },
    { label: "(GMT+1) Europe/Rome" },
    { label: "(GMT+1) Europe/San Marino" },
    { label: "(GMT+1) Europe/Sarajevo" },
    { label: "(GMT+1) Europe/Skopje" },
    { label: "(GMT+1) Europe/Stockholm" },
    { label: "(GMT+1) Europe/Tirane" },
    { label: "(GMT+1) Europe/Vaduz" },
    { label: "(GMT+1) Europe/Vatican" },
    { label: "(GMT+1) Europe/Vienna" },
    { label: "(GMT+1) Europe/Warsaw" },
    { label: "(GMT+1) Europe/Zagreb" },
    { label: "(GMT+1) Europe/Zurich" },
    { label: "(GMT+2) Africa/Blantyre" },
    { label: "(GMT+2) Africa/Bujumbura" },
    { label: "(GMT+2) Africa/Cairo" },
    { label: "(GMT+2) Africa/Gaborone" },
    { label: "(GMT+2) Africa/Harare" },
    { label: "(GMT+2) Africa/Johannesburg" },
    { label: "(GMT+2) Africa/Khartoum" },
    { label: "(GMT+2) Africa/Kigali" },
    { label: "(GMT+2) Africa/Lubumbashi" },
    { label: "(GMT+2) Africa/Lusaka" },
    { label: "(GMT+2) Africa/Maputo" },
    { label: "(GMT+2) Africa/Maseru" },
    { label: "(GMT+2) Africa/Mbabane" },
    { label: "(GMT+2) Africa/Tripoli" },
    { label: "(GMT+2) Africa/Windhoek" },
    { label: "(GMT+2) Asia/Amman" },
    { label: "(GMT+2) Asia/Beirut" },
    { label: "(GMT+2) Asia/Damascus" },
    { label: "(GMT+2) Asia/Famagusta" },
    { label: "(GMT+2) Asia/Gaza" },
    { label: "(GMT+2) Asia/Hebron" },
    { label: "(GMT+2) Asia/Jerusalem" },
    { label: "(GMT+2) Asia/Nicosia" },
    { label: "(GMT+2) Europe/Athens" },
    { label: "(GMT+2) Europe/Bucharest" },
    { label: "(GMT+2) Europe/Chisinau" },
    { label: "(GMT+2) Europe/Helsinki" },
    { label: "(GMT+2) Europe/Kaliningrad" },
    { label: "(GMT+2) Europe/Kyiv" },
    { label: "(GMT+2) Europe/Mariehamn" },
    { label: "(GMT+2) Europe/Riga" },
    { label: "(GMT+2) Europe/Sofia" },
    { label: "(GMT+2) Europe/Tallinn" },
    { label: "(GMT+2) Europe/Uzhgorod" },
    { label: "(GMT+2) Europe/Vilnius" },
    { label: "(GMT+2) Europe/Zaporozhye" },
    { label: "(GMT+3) Africa/Addis Ababa" },
    { label: "(GMT+3) Africa/Asmara" },
    { label: "(GMT+3) Africa/Dar es Salaam" },
    { label: "(GMT+3) Africa/Djibouti" },
    { label: "(GMT+3) Africa/Juba" },
    { label: "(GMT+3) Africa/Kampala" },
    { label: "(GMT+3) Africa/Mogadishu" },
    { label: "(GMT+3) Africa/Nairobi" },
    { label: "(GMT+3) Antarctica/Syowa" },
    { label: "(GMT+3) Asia/Aden" },
    { label: "(GMT+3) Asia/Baghdad" },
    { label: "(GMT+3) Asia/Bahrain" },
    { label: "(GMT+3) Asia/Kuwait" },
    { label: "(GMT+3) Asia/Qatar" },
    { label: "(GMT+3) Asia/Riyadh" },
    { label: "(GMT+3) Europe/Istanbul" },
    { label: "(GMT+3) Europe/Kirov" },
    { label: "(GMT+3) Europe/Minsk" },
    { label: "(GMT+3) Europe/Moscow" },
    { label: "(GMT+3) Europe/Simferopol" },
    { label: "(GMT+3) Indian/Antananarivo" },
    { label: "(GMT+3) Indian/Comoro" },
    { label: "(GMT+3) Indian/Mayotte" },
    { label: "(GMT+3.5) Asia/Tehran" },
    { label: "(GMT+4) Asia/Baku" },
    { label: "(GMT+4) Asia/Dubai" },
    { label: "(GMT+4) Asia/Muscat" },
    { label: "(GMT+4) Asia/Tbilisi" },
    { label: "(GMT+4) Asia/Yerevan" },
    { label: "(GMT+4) Europe/Astrakhan" },
    { label: "(GMT+4) Europe/Samara" },
    { label: "(GMT+4) Europe/Saratov" },
    { label: "(GMT+4) Europe/Ulyanovsk" },
    { label: "(GMT+4) Europe/Volgograd" },
    { label: "(GMT+4) Indian/Mahe" },
    { label: "(GMT+4) Indian/Mauritius" },
    { label: "(GMT+4) Indian/Reunion" },
    { label: "(GMT+4.5) Asia/Kabul" },
    { label: "(GMT+5) Antarctica/Mawson" },
    { label: "(GMT+5) Asia/Aqtau" },
    { label: "(GMT+5) Asia/Aqtobe" },
    { label: "(GMT+5) Asia/Ashgabat" },
    { label: "(GMT+5) Asia/Atyrau" },
    { label: "(GMT+5) Asia/Dushanbe" },
    { label: "(GMT+5) Asia/Karachi" },
    { label: "(GMT+5) Asia/Oral" },
    { label: "(GMT+5) Asia/Qyzylorda" },
    { label: "(GMT+5) Asia/Samarkand" },
    { label: "(GMT+5) Asia/Tashkent" },
    { label: "(GMT+5) Asia/Yekaterinburg" },
    { label: "(GMT+5) Indian/Kerguelen" },
    { label: "(GMT+5) Indian/Maldives" },
    { label: "(GMT+5.5) Asia/Colombo" },
    { label: "(GMT+5.5) Asia/Kolkata" },
    { label: "(GMT+5.75) Asia/Kathmandu" },
    { label: "(GMT+6) Antarctica/Vostok" },
    { label: "(GMT+6) Asia/Almaty" },
    { label: "(GMT+6) Asia/Bishkek" },
    { label: "(GMT+6) Asia/Dhaka" },
    { label: "(GMT+6) Asia/Omsk" },
    { label: "(GMT+6) Asia/Qostanay" },
    { label: "(GMT+6) Asia/Thimphu" },
    { label: "(GMT+6) Asia/Urumqi" },
    { label: "(GMT+6) Indian/Chagos" },
    { label: "(GMT+6.5) Asia/Yangon" },
    { label: "(GMT+6.5) Indian/Cocos" },
    { label: "(GMT+7) Antarctica/Davis" },
    { label: "(GMT+7) Asia/Bangkok" },
    { label: "(GMT+7) Asia/Barnaul" },
    { label: "(GMT+7) Asia/Ho Chi Minh" },
    { label: "(GMT+7) Asia/Hovd" },
    { label: "(GMT+7) Asia/Jakarta" },
    { label: "(GMT+7) Asia/Krasnoyarsk" },
    { label: "(GMT+7) Asia/Novokuznetsk" },
    { label: "(GMT+7) Asia/Novosibirsk" },
    { label: "(GMT+7) Asia/Phnom Penh" },
    { label: "(GMT+7) Asia/Pontianak" },
    { label: "(GMT+7) Asia/Tomsk" },
    { label: "(GMT+7) Asia/Vientiane" },
    { label: "(GMT+7) Indian/Christmas" },
    { label: "(GMT+8) Asia/Brunei" },
    { label: "(GMT+8) Asia/Choibalsan" },
    { label: "(GMT+8) Asia/Hong Kong" },
    { label: "(GMT+8) Asia/Irkutsk" },
    { label: "(GMT+8) Asia/Kuala Lumpur" },
    { label: "(GMT+8) Asia/Kuching" },
    { label: "(GMT+8) Asia/Macau" },
    { label: "(GMT+8) Asia/Makassar" },
    { label: "(GMT+8) Asia/Manila" },
    { label: "(GMT+8) Asia/Shanghai" },
    { label: "(GMT+8) Asia/Singapore" },
    { label: "(GMT+8) Asia/Taipei" },
    { label: "(GMT+8) Asia/Ulaanbaatar" },
    { label: "(GMT+8) Australia/Perth" },
    { label: "(GMT+8.75) Australia/Eucla" },
    { label: "(GMT+9) Asia/Chita" },
    { label: "(GMT+9) Asia/Dili" },
    { label: "(GMT+9) Asia/Jayapura" },
    { label: "(GMT+9) Asia/Khandyga" },
    { label: "(GMT+9) Asia/Pyongyang" },
    { label: "(GMT+9) Asia/Seoul" },
    { label: "(GMT+9) Asia/Tokyo" },
    { label: "(GMT+9) Asia/Yakutsk" },
    { label: "(GMT+9) Pacific/Palau" },
    { label: "(GMT+9.5) Australia/Darwin" },
    { label: "(GMT+10) Antarctica/DumontDUrville" },
    { label: "(GMT+10) Asia/Ust-Nera" },
    { label: "(GMT+10) Asia/Vladivostok" },
    { label: "(GMT+10) Australia/Brisbane" },
    { label: "(GMT+10) Australia/Lindeman" },
    { label: "(GMT+10) Pacific/Chuuk" },
    { label: "(GMT+10) Pacific/Guam" },
    { label: "(GMT+10) Pacific/Port Moresby" },
    { label: "(GMT+10) Pacific/Saipan" },
    { label: "(GMT+10.5) Australia/Adelaide" },
    { label: "(GMT+10.5) Australia/Broken Hill" },
    { label: "(GMT+11) Antarctica/Casey" },
    { label: "(GMT+11) Antarctica/Macquarie" },
    { label: "(GMT+11) Asia/Magadan" },
    { label: "(GMT+11) Asia/Sakhalin" },
    { label: "(GMT+11) Asia/Srednekolymsk" },
    { label: "(GMT+11) Australia/Hobart" },
    { label: "(GMT+11) Australia/Lord Howe" },
    { label: "(GMT+11) Australia/Melbourne" },
    { label: "(GMT+11) Australia/Sydney" },
    { label: "(GMT+11) Pacific/Bougainville" },
    { label: "(GMT+11) Pacific/Efate" },
    { label: "(GMT+11) Pacific/Guadalcanal" },
    { label: "(GMT+11) Pacific/Kosrae" },
    { label: "(GMT+11) Pacific/Norfolk" },
    { label: "(GMT+11) Pacific/Noumea" },
    { label: "(GMT+11) Pacific/Pohnpei" },
    { label: "(GMT+12) Asia/Anadyr" },
    { label: "(GMT+12) Asia/Kamchatka" },
    { label: "(GMT+12) Pacific/Fiji" },
    { label: "(GMT+12) Pacific/Funafuti" },
    { label: "(GMT+12) Pacific/Kwajalein" },
    { label: "(GMT+12) Pacific/Majuro" },
    { label: "(GMT+12) Pacific/Nauru" },
    { label: "(GMT+12) Pacific/Tarawa" },
    { label: "(GMT+12) Pacific/Wake" },
    { label: "(GMT+12) Pacific/Wallis" },
    { label: "(GMT+13) Antarctica/McMurdo" },
    { label: "(GMT+13) Pacific/Auckland" },
    { label: "(GMT+13) Pacific/Fakaofo" },
    { label: "(GMT+13) Pacific/Kanton" },
    { label: "(GMT+13) Pacific/Tongatapu" },
    { label: "(GMT+13.75) Pacific/Chatham" },
    { label: "(GMT+14) Pacific/Apia" },
    { label: "(GMT+14) Pacific/Kiritimati" },
]

export const CustomPaper = (props) => {
    return (
        <Paper
            style={{ color: "#FFFF", background: "transparent", marginButtom: '10px', borderBottom: '2px dashed #FFFF' }}
            {...props}
        />
    );
};
