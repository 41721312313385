import React, { useContext } from "react";
import "./style.scss";

function SomethingWentWrong() {
  return (
    <div className="error-container">
      <div className="inner-err-container">
        <div className="wrong-text">
          <span className="something-text">ID Not</span>
          <span>Found</span>
        </div>
        <img
          src="https://waymore.io/wp-content/uploads/2021/08/girl1copy.png"
          className="girl-img"
          alt=""
        />
      </div>
    </div>
  );
}

export default SomethingWentWrong;
