import React from "react";
import { useDropzone } from "react-dropzone";
import "./style.scss";

const Fileupload = ({ handleFile, i, name, fileNames, index }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFile(acceptedFiles, i, name),
  });

  return (
    <div className="file-uploader" {...getRootProps()}>
      <input {...getInputProps()} />
      <label htmlFor="actual-btn">Choose or drop the File</label>
      <span id="file-chosen">
        {fileNames[index - 1]?.length && fileNames[index - 1][i]?.length
          ? fileNames[index - 1][i][0]
          : "No file chosen"}
      </span>
    </div>
  );
};

export default Fileupload;
