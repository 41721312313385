import React, { useContext, useEffect, useState } from 'react'
import { changeTitle, ModifyTitle, titleFromParams } from '../../Utilities/helperFunction'
import SelectButton from '../Select-button'
import arrowCostIcon from '../../Assets/arrowCostIcon.svg'
import './style.scss'
import { currency_symbols } from '../../Utilities/Constant'
import appContext from '../../appContext'

function RadioButton({ hasQuantity, setnob2b, setnob2c, currency, viberMinimumCost, addNextData, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues, symbol, finalTotalCost, minusPercent, isCostFormat }) {
    const [title, setTitle] = useState(titleFromParams(mock?.title))
    const [otherName, setOtherName] = useState('')
    const [price, setPrice] = useState("")
    const { setQuotation, quotation, setCustomStyles, otherOption, setOtherOption, dependedHistory, setDependedHistory } = useContext(appContext)
    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }

    const changeCostFormatTitle = () => {
        setTitle(ModifyTitle(mock?.title, symbol, finalTotalCost, finalTotalCost))
    }

    const handleRadio = (e) => {
        const dependedHistoryClone = { ...dependedHistory }
        const selectedOptions = mock?.options?.filter((selectedOption) => selectedOption?.value === e)
        dependedHistoryClone[name] = selectedOptions.map((option) => option?.id)
        setDependedHistory({ ...dependedHistoryClone })
        setValue(initialValues[index - 1], e)
        setMaintainValues({ ...maintainValues, [`${name}`]: e })
        addNextData(e, index, mock?._id, 'radio', dependedHistoryClone)
    }

    const handleCostFormat = () => {
        if (maintainValues['noOfContacts']) {
            let totalCost = Number(maintainValues['noOfContacts']) * 0.003
            let symbol = currency_symbols('EUR')
            setPrice(`${totalCost?.toFixed(2)} ${symbol}`)
            setQuotation({ ...quotation, "quotation2": totalCost })
        }

    }
    const handleB2bB2c = () => {
        if (!maintainValues['business type']?.includes('b2b') || !maintainValues['business type']?.includes('b2c')) {
            if (!maintainValues['business type']?.includes('b2c') && maintainValues['business type']?.includes('b2b')) {
                setnob2c(true)
            } else if (maintainValues['business type']?.includes('b2c') && !maintainValues['business type']?.includes('b2b')) {
                setnob2b(true)
            }
        }
    }

    const handleQuotation7 = () => {
        if (maintainValues['modulesName']?.length > 0) {
            let cost = 0
            if (maintainValues['modulesName']?.includes("Event Management")) {
                cost += 30
            }
            if (maintainValues['modulesName']?.includes("SEO Report")) {
                cost += 150
            }
            if (maintainValues['modulesName']?.includes("SocialCampaignAI") && maintainValues['currentCampaignsCount']) {
                cost += 50 * Number(maintainValues['currentCampaignsCount'])
            }
            if (maintainValues['modulesName']?.includes("roas_optimizer") && maintainValues['currentCampaignsCount']) {
                cost += 10 * Number(maintainValues['currentCampaignsCount'])
            }
            let oldTitle = mock?.title
            cost = cost.toString()
            oldTitle = oldTitle?.replace('${solutionCost}', cost)
            setQuotation({ ...quotation, "quotation7": cost })
            setTitle(oldTitle)
        }
    }

    useEffect(() => {
        setOtherName(otherOption[mock?.name] || "")
        setCustomStyles(mock?.styles || {})
        if (mock?.name === "costFormat") {

        } else if (mock?.name === "quotation2") {
            handleCostFormat()
            handleB2bB2c()
        } else if (mock?.name === "quotation7") {
            handleQuotation7()
        } else {
            setTitle(changeTitle(title, maintainValues));
        }
    }, [])
    useEffect(() => {
        if (mock?.name === "costFormat") {
            changeCostFormatTitle()
        }
    }, [minusPercent])

    useEffect(() => {
        otherOption[mock?.name] = otherName
        setOtherOption({ ...otherOption })
    }, [otherName])

    return (
        <div onClick={() => (index)} className='radio-container'>
            <div className='radio-title'>

                {mock?.name?.includes("costFormat") ? <div className='cost-suggestions'><h1><img src={arrowCostIcon} /><span className='cost-title' dangerouslySetInnerHTML={{ __html: title }} /></h1>
                    {maintainValues && maintainValues?.services?.includes("viber") && maintainValues["viberMessageCount"] && (maintainValues["viberMessageCount"] < 10000) &&
                        <h1><img src={arrowCostIcon} /> <span className='cost-title'>{`We do not recommend using viber as a communication channel in your campaign mix for the time being.
                    Viber as a channel has minimum monthly fee ${viberMinimumCost ? viberMinimumCost : 'X'} ${symbol} , and it will not brake even in your use case.
                    Instead we propose using SMS with a link redirecting to a landing page created from WayMore Platform as a best alternative approach.`}</span></h1>}
                    <h1><img src={arrowCostIcon} /><span className='cost-title'> Avoid needless expenses. Use Validation Services to delete invalid contacts from your list.</span></h1>
                </div> : mock?.name === "quotation2" ? <h1 className='title'>
                    <div>Your cost for Email and Number Validation will be {price} {mock.title}</div>
                </h1>
                    : <h1 className='title'><span>{index}
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                            <path
                                d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                        </svg>
                    </span><div dangerouslySetInnerHTML={{ __html: title }} /></h1>}
                <div>
                    <p>{mock?.description}</p>
                </div>
                {/* : <h1 className='title'><span>{index}<img src={rightIcon} /></span><div dangerouslySetInnerHTML={{ __html: title }} /></h1>} */}
            </div>

            <div className='radio-button-container'>
                {mock?.options?.map((moc, i) => <div key={moc?.value + i} className="radio-button" > <SelectButton hasQuantity={hasQuantity} country={maintainValues?.country} currency={currency} isCostFormat={isCostFormat} label={moc?.label} value={moc?.value} multiSelect={false} newValue={name} trueValue={maintainValues[name] ? maintainValues[name] : []} handleRadio={handleRadio} index={i} mock={mock} symbol={symbol} finalTotalCost={finalTotalCost} optionImg={moc?.img ?? ""} /></div>)}
                {mock?.isOtherEnabled && <div className="radio-button" > <SelectButton hasQuantity={hasQuantity} country={maintainValues?.country} maintainValues={maintainValues} currency={currency} isCostFormat={isCostFormat} label={otherName || 'Other'} value={otherName || 'Other'} otherName={otherName} setOtherName={setOtherName} multiSelect={false} newValue={name} trueValue={maintainValues[name] ? maintainValues[name] : []} handleRadio={handleRadio} index={mock?.options?.length} mock={mock} symbol={symbol} finalTotalCost={finalTotalCost} isOther={true} /></div>}
            </div>
        </div>

    )
}

export default RadioButton