import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import './style.scss'

function Button({ text, icon, onClick, type, btnLoading, buttonRef, apiBtnLoading, disabled, apiBtnDropLoading }) {
    return (
        type === "submit" ? <LoadingButton loading={btnLoading} type={type} variant="outlined" className='loadingBtn' disabled={disabled}> {btnLoading ? "" : "Submit"} </LoadingButton> : <button type={type} ref={buttonRef} onClick={onClick} disabled={disabled || apiBtnDropLoading}><span>{text}{(icon && !apiBtnLoading && !apiBtnDropLoading) ? <img src={icon} /> : <></>}</span></button>
    )
}

export default Button;