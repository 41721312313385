import React, { useContext, useEffect } from "react";
import "./style.scss";
import appContext from "../../appContext";
import Rating from "@mui/material/Rating";
import { convertNumberToArray } from "../../Utilities/helperFunction";
import OptionScaleButton from "../optionScaleButton";
import { useMediaQuery } from "@mui/material";

const CustomRating = ({
  mock,
  index,
  initialValues,
  maintainValues,
  setMaintainValues,
}) => {
  const { setCustomStyles } = useContext(appContext);
  const isLessThan650 = useMediaQuery("(max-width:650px)");

  let name = "";
  if (initialValues && initialValues[index - 1]) {
    name = initialValues[index - 1];
  }

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="rating-container" onClick={() => index}>
      <div className="rating-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          {mock.title}
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      <div className="rating-inner-container">
        {mock?.ratingSettings?.isStar ? (
          <>
            <Rating
              name="customized-10"
              color="red"
              max={mock?.ratingSettings?.max || 5}
              defaultValue={maintainValues[`${name}`]}
              value={maintainValues[`${name}`]?.selection}
              onChange={(_, value) => {
                setMaintainValues({
                  ...maintainValues,
                  [`${name}`]: {
                    selection: value,
                    total: mock?.ratingSettings?.max || 5,
                  },
                });
              }}
            />
          </>
        ) : (
          <>
            <div className="option-scale-container">
              {isLessThan650 && (
                <div className="label-container">
                  {mock?.ratingSettings?.label?.start && (
                    <div className="Label">
                      {mock?.ratingSettings?.min} {" => "}
                      <span className="TextWrapper">
                        {mock?.ratingSettings?.label?.start}
                      </span>
                    </div>
                  )}
                  {mock?.ratingSettings?.label?.mid && (
                    <div className="Label">
                      {mock?.ratingSettings?.max / 2} {" => "}
                      <span className="TextWrapper">
                        {mock?.ratingSettings?.label?.mid}
                      </span>
                    </div>
                  )}
                  {mock?.ratingSettings?.label?.mid && (
                    <div className="Label">
                      {mock?.ratingSettings?.max} {" => "}
                      <span className="TextWrapper">
                        {mock?.ratingSettings?.label?.end}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="option-buttons">
                {convertNumberToArray(
                  mock?.ratingSettings?.min,
                  mock?.ratingSettings?.max
                )?.map((label, ind) => {
                  const newIndex =
                    mock?.ratingSettings?.min === 0 ? ind : ind + 1;
                  return (
                    <OptionScaleButton
                      text={label}
                      isSelected={
                        newIndex === maintainValues[`${name}`]?.selection
                      }
                      handleClick={(val) => {
                        setMaintainValues({
                          ...maintainValues,
                          [`${name}`]: {
                            selection:
                              mock?.ratingSettings?.min === 0 ? val : val + 1,
                            total: mock?.ratingSettings?.max,
                          },
                        });
                      }}
                      index={ind}
                    />
                  );
                })}
              </div>
              {!isLessThan650 && (
                <div className="bottom-label-container">
                  <div className="Labels">
                    <div className="Label start">
                      <span className="TextWrapper">
                        {mock?.ratingSettings?.label?.start}
                      </span>
                    </div>
                    <div className="Label center">
                      <span className="TextWrapper">
                        {mock?.ratingSettings?.label?.mid}
                      </span>
                    </div>
                    <div className="Label end">
                      <span className="TextWrapper">
                        {mock?.ratingSettings?.label?.end}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomRating;
