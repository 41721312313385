import React from "react";
import "./style.scss";

const OptionScaleButton = ({ text, isSelected, index, handleClick }) => {
  return (
    <div
      className={`option-button-container ${isSelected ? "selected" : ""}`}
      onClick={() => handleClick(index)}
    >
      {text}
    </div>
  );
};

export default OptionScaleButton;
