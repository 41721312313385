import React, { useContext, useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Alert from '@mui/material/Alert';
import './style.scss'
import { paramsToObject, titleFromParams } from '../../Utilities/helperFunction';
import Button from '../../Components/Button'
import appContext from '../../appContext';



function MultiInput({ TickIcon, handleOk, onSubmit, setScrollDisable, mock, index, rightIcon, maintainValues, setMaintainValues, isValidUrl, lastIndex }) {
  const [valid, setValid] = useState(true);
  const [fieldIndex, setFieldIndex] = useState(0);
  const totalNumberOfField = mock?.options?.length ?? 0;
  const [selectedOptions, setSelectedOptions] = useState(mock?.options[fieldIndex])
  const [inputValue, setInputValue] = useState(null)
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false)
  const { setCustomStyles } = useContext(appContext)
  const inputRef = useRef(inputValue)
  const optionRef = useRef(selectedOptions)
  const maintainValueRef = useRef(maintainValues)
  const fieldIndexRef = useRef(fieldIndex);
  inputRef.current = inputValue;
  optionRef.current = selectedOptions;
  maintainValueRef.current = maintainValues;
  fieldIndexRef.current = fieldIndex;



  const handleIndex = (value, i) => {
    if (optionRef?.current?.isRequired && !value) {
      setValid(false)
      setErrorMessage('value must not be empty')
      setTimeout(() => {
        setValid(true)
        setErrorMessage('')
      }, 3000)
      return;
    }

    if (optionRef?.current?.type === "url") {
      const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
      const regex = new RegExp(expression)
      if (!value.match(regex)) {
        setValid(false)
        setErrorMessage('Looks like the URL is not valid')
        setTimeout(() => {
          setValid(true);
          setErrorMessage('');
        }, 3000)
        return;
      }
    }
    else if (optionRef?.current?.type === "email") {
      const expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi
      const regex = new RegExp(expression);
      if (!value.match(regex)) {
        setValid(false)
        setErrorMessage('Please enter valid email')
        setTimeout(() => {
          setValid(true)
          setErrorMessage('')
        }, 3000)
        return;
      }
    }
    else if (optionRef?.current?.type === "number") {
      const reg = new RegExp("^[0-9]*$");
      if (!reg.test(value)) {
        setValid(false)
        setErrorMessage('Number only please')
        setTimeout(() => {
          setValid(true)
          setErrorMessage('')
        }, 3000)
        return;
      }
    } else if (optionRef?.current?.type === "decimal") {
      const reg = new RegExp(`^\\d*\\.?\\d{0,${optionRef?.current?.max_decimal || 2}}$`);
      if (!reg.test(value)) {
        setValid(false)
        if (!isNaN(value)) {
          setErrorMessage('Number only please')
        } else {
          setErrorMessage('Decimal digits more than this not allowed')
        }
      }
    }
    setMaintainValues({ ...maintainValues, [optionRef?.current?.name]: { answer: value, question: optionRef?.current?.question } });
    setInputValue('');
    setFieldIndex(fieldIndex < totalNumberOfField - 1 ? fieldIndex + 1 : totalNumberOfField - 1)
    setValid(true)
    return true;
  }

  const handleNext = () => {
    if (index !== lastIndex && fieldIndex === totalNumberOfField - 1) {

      const validationDetails = handleIndex(maintainValues[optionRef?.current?.name]?.answer, fieldIndex);
      if (validationDetails) {
        handleOk(mock, mock?.name, true);
        setScrollDisable(false)
      }
    }
    else {
      handleIndex(maintainValueRef?.current[optionRef?.current?.name]?.answer, fieldIndex);
    }
  }

  const submitHandler = () => {
    const validationDetails = handleIndex(inputRef?.current, fieldIndex);
    if (validationDetails) {
      onSubmit()
    }
  }

  const handleChange = (value) => {
    const cloneVal = { ...maintainValues }
    cloneVal[selectedOptions.name] = { answer: value, question: selectedOptions?.question };
    setMaintainValues(cloneVal)
  }


  useEffect(() => {
    setCustomStyles(mock?.styles || {})

    const inputVal = paramsToObject()
    if (inputVal) {
      mock?.options?.map((x) => {
        if (inputVal[x?.name] && !maintainValues[x?.name]) {
          maintainValues[x?.name] = { answer: inputVal[x?.name], question: x?.question };
        }
      })
    }
    setMaintainValues({ ...maintainValues })
  }, [])


  useEffect(() => {
    setSelectedOptions({ ...mock?.options[fieldIndex] });
    setInputValue(maintainValues[mock?.options[fieldIndex]?.name]?.answer || '');
    if (index === lastIndex && fieldIndex === totalNumberOfField - 1) {
      setIsSubmit(true);
      setScrollDisable(false)
    }
  }, [fieldIndex])

  const handleNumber = (e, isDecimal, decimalNumber) => {
    let reg = ""
    if (isDecimal) {
      reg = new RegExp(`^\\d*\\.?\\d{0,${decimalNumber || 2}}$`);
    } else {
      reg = new RegExp("^[0-9]*$");
    }
    if (reg.test(e)) {
      setInputValue(e)
      setValid(true);
    } else {
      setValid(false)
      if (!isNaN(e) && isDecimal) {
        setErrorMessage('Decimal digits more than this not allowed')
      } else {
        setErrorMessage('Number only please')
      }
    }
  }


  return (
    <div onClick={() => (index)} className={(!valid || !isValidUrl) ? "alert-animation" : 'input-container'}>
      <div className='input-title'>
        <h1 className='title'><span>{index}
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
            <path
              d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
          </svg>
        </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /></h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      <div className='multiSelect-scroll' key={fieldIndex}>
        <div className='multi-question'><span>{fieldIndex + 1} . </span>{selectedOptions?.question}</div>
        <div className='input'>
          {selectedOptions?.type === "text" &&
            <div>
              <TextField key={fieldIndex}
                id="standard"
                label={selectedOptions?.label}
                placeholder={selectedOptions?.placeholder}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                InputLabelProps={{
                  shrink: true,
                }}
                name={selectedOptions?.name}
                onChange={(e) => { setInputValue(e?.target?.value); handleChange(e?.target?.value) }}
                value={inputValue ?? ""}
              />
            </div>
          }
          {selectedOptions?.type === "textArea" && <div>
            <TextField key={fieldIndex}
              className='text-'
              placeholder={selectedOptions?.placeholder}
              InputLabelProps={{ shrink: false }}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
              multiline
              minRows={4}
              variant="outlined"
              onChange={(e) => { setInputValue(e?.target?.value); handleChange(e?.target?.value) }}
              value={inputValue ?? ""}
            />
          </div>}
          {selectedOptions?.type === "url" && <div >
            <TextField key={fieldIndex}
              id="standard"
              label={selectedOptions?.label}
              placeholder={selectedOptions?.placeholder}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
              InputLabelProps={{
                shrink: true,
              }}
              name={selectedOptions?.name}
              onChange={(e) => { setInputValue(e?.target?.value); handleChange(e?.target?.value) }}
              value={inputValue ?? ""}
            />
          </div>}
          {selectedOptions?.type === "email" && <div>
            <TextField key={fieldIndex}
              id="standard"
              label={selectedOptions?.label}
              placeholder={selectedOptions?.placeholder}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
              InputLabelProps={{
                shrink: true,
              }}
              name={selectedOptions?.name}
              onChange={(e) => { setInputValue(e?.target?.value); handleChange(e?.target?.value) }}
              value={inputValue ?? ""}
            />
          </div>}
          {["number", "decimal"].includes(selectedOptions?.type) && <div >
            <TextField key={fieldIndex}
              id="standard"
              label={selectedOptions?.label}
              placeholder={selectedOptions?.placeholder}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              name={selectedOptions?.name}
              onChange={(e) => { handleNumber(e?.target?.value, selectedOptions?.type === "decimal", selectedOptions?.max_decimal); handleChange(e?.target?.value) }}
              value={inputValue ?? ""}
            />
          </div>}
        </div>
        {!isSubmit ? <div className='btnSpan'><Button onClick={() => handleNext()} text='Ok' type='button' icon={TickIcon}>ok</Button> <span>please enter</span></div> : <Button type='button' text='Submit' onClick={submitHandler}>Submit</Button>}
        <Alert className={valid ? 'alert' : 'alert warning'} severity="error">{errorMessage}</Alert>
      </div>
    </div>
  )
}

export default MultiInput