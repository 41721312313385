import React, { useState } from 'react';
import './style.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import TextField from '@material-ui/core/TextField';

function TransactionSuccess({ transactionID, goNext }) {
    const [open, setOpen] = useState(false)

    const handleLoginRegister = () => {
        window.location.href = 'https://go.waymore.io/signup';
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(transactionID).then(() => setOpen(true))
    }

    return (
        <div className='transactionSuccess-container'>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={() => setOpen(false)} severity="success">
                    Copied successfully
                </Alert>
            </Snackbar>
            <div className='transactionid-header'>
                <span className='id-header-span'>Your Transaction ID is : </span>
                <div className='id-division'>
                    <span id='transactionId'>{transactionID}</span>
                    <ContentCopyIcon onClick={handleCopy} />
                </div>
            </div>
            <div className='inputField'>
                <p>Enter your E-mail address to receive your Token via E-mail.</p>
                <TextField placeholder='example@gmail.com' />
            </div>
            <div className='login-text'>
                Please keep this token, and share it to our customer success department to topup your account.
            </div>
            {/* <button className='login-register-btn' onClick={handleLoginRegister} > Register/Login </button> */}
            <button className='login-register-btn' onClick={goNext} >Go back to form </button>

        </div>
    )
}

export default TransactionSuccess