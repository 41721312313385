import React, { useContext, useEffect, useState } from 'react'
import appContext from '../../appContext'
import { marketingServices } from '../../Utilities/Constant'
import { titleFromParams } from '../../Utilities/helperFunction'
import SelectButton from '../Select-button'
import './style.scss'


function CheckBox({ hasQuantity, setScrollDisable, realData, setRealData, addNextData, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues }) {
    const [checkedArray, setCheckedArray] = useState([])
    const [otherName, setOtherName] = useState('')
    const [checkedOther, setCheckedOther] = useState('')
    const { quotation, setCurrency, costFormatArr, totalQuotationAmount, setTotalQuotationAmount, setFinalQuotationCost, setCustomStyles, otherOption, setOtherOption, setDependedHistory, dependedHistory } = useContext(appContext)
    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }

    const createQuestion = (optionVal, ind) => {
        let realDataClone = [...realData]
        if (optionVal.length < 9) {
            let QuestionArr = {
                "title": "Somemore services you have selected",
                "type": "multi-select",
                "description": "",
                "name": "",
                "isDepended": false,
                "dependedId": "",
                "options": optionVal,
                "isRequired": false
            }
            realDataClone.splice(ind - 1, 0, QuestionArr)
            setRealData(realDataClone)
        } else {
            let QuestionArr = {
                "title": "Somemore services you have selected",
                "type": "multi-select",
                "description": "",
                "name": "",
                "isDepended": false,
                "dependedId": "",
                "options": optionVal?.splice(8),
                "isRequired": false
            }
            realDataClone.splice(ind - 1, 0, QuestionArr)
            setRealData(realDataClone)
            createQuestion(optionVal, index + 1)
        }
    }


    const handleChecked = (e) => {
        let checkedArr = maintainValues[name] ? [...maintainValues[name]] : checkedArray
        let totalCost = Number(totalQuotationAmount)
        const dependedHistoryClone = { ...dependedHistory }
        if (checkedArr.includes(e)) {
            checkedArr = checkedArr.filter((data) => data !== e)
        } else {
            checkedArr.push(e)
        }
        if (mock?.isOtherEnabled && checkedArr?.length) {
            const optionsValue = mock?.options?.map((moc) => moc?.value);
            checkedArr = checkedArr.filter((checked) => {
                if (optionsValue.includes(checked) || checked === checkedOther || (!checkedOther && checked === otherName)) {
                    return checked
                }
            })
        }
        const selectedOptions = mock?.options?.filter((selectedOption) => checkedArr?.includes(selectedOption?.value))
        dependedHistoryClone[name] = selectedOptions.map((option) => option?.id)
        setDependedHistory({ ...dependedHistoryClone })
        setValue(initialValues[index - 1], checkedArr)
        setMaintainValues({ ...maintainValues, [`${name}`]: checkedArr })
        setCheckedArray(checkedArr)
        addNextData(checkedArr, index, mock?._id, 'checkbox', dependedHistoryClone)
        if (mock?.type === "serviceList") {
            if (marketingServices?.includes(e)) {
                let currentData = costFormatArr.filter((cost) => `${cost?.service} Services` === e)
                if (e === "SMS Services") {
                    if (!checkedArr?.includes("SMS Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Email Services") {
                    if (!checkedArr?.includes("Email Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Push notification Services") {
                    if (!checkedArr?.includes("Push notification Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Facebook Messenger Services") {
                    if (!checkedArr?.includes("Facebook Messenger Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Viber Services") {
                    if (!checkedArr?.includes("Viber Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "WhatsApp Services") {
                    if (!checkedArr?.includes("WhatsApp Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Telegram Services") {
                    if (!checkedArr?.includes("Telegram Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Voice for Landline Services") {
                    if (!checkedArr?.includes("Voice for Landline Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
                if (e === "Voice for Mobile Services") {
                    if (!checkedArr?.includes("Voice for Mobile Services")) {
                        if (currentData?.length) {
                            totalCost -= Number(currentData[0]?.totalCost)
                        }
                    } else {
                        totalCost += Number(currentData[0]?.totalCost)
                    }
                }
            }
            if (e == "Validation Services") {
                if (!checkedArr?.includes("Validation Services")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= Number(quotation['quotation2'])
                } else {
                    totalCost += Number(quotation['quotation2'])
                }
            }
            if (e == "Validation Services") {
                if (!checkedArr?.includes("Validation Services")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= Number(quotation['quotation2'])
                } else {
                    totalCost += Number(quotation['quotation2'])
                }
            }
            if (e == "E commerce plugin") {
                if (!checkedArr?.includes("E commerce plugin")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= 60
                } else {
                    totalCost += 60
                }
            }
            if (e == "Event Management") {
                if (!checkedArr?.includes("Event Management")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= 30
                } else {
                    totalCost += 30
                }
            }
            if (e == "SEO Report") {
                if (!checkedArr?.includes("SEO Report")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= 150
                } else {
                    totalCost += 150
                }
            }
            if (e === "SocialCampaignAI") {
                if (!checkedArr?.includes("SocialCampaignAI")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= 50 * Number(maintainValues['currentCampaignsCount'])
                } else {
                    totalCost += 50 * Number(maintainValues['currentCampaignsCount'])
                }
            }
            if (e === "ROAS Optimizer") {
                if (!checkedArr?.includes("ROAS Optimizer")) {
                    // setMaintainValues({ ...clonedValue, quotation7: "no" })
                    totalCost -= 10 * Number(maintainValues['currentCampaignsCount'])
                } else {
                    totalCost += 10 * Number(maintainValues['currentCampaignsCount'])
                }
            }
            if (e === "Appointment service") {
                if (!checkedArr?.includes("Appointment service")) {
                    // setMaintainValues({ ...clonedValue, quotation8: "no" })
                    totalCost -= 9
                } else {
                    totalCost += 9
                }
            }
            if (e === "B2B leads service") {
                if (!checkedArr?.includes("B2B leads service")) {
                    // setMaintainValues({ ...clonedValue, quotation9: "no" })
                    totalCost -= Number(quotation['quotation9'])
                } else {
                    totalCost += Number(quotation['quotation9'])
                }
            }
            if (e === "B2C leads installation") {
                if (!checkedArr?.includes("B2C leads installation")) {
                    // setMaintainValues({ ...clonedValue, quotation10: "no" })
                    totalCost -= 150
                } else {
                    totalCost += 150
                }
            }
            if (!checkedArr?.includes("B2C leads service")) {
                if (!checkedArr?.includes("B2C leads service")) {
                    // setMaintainValues({ ...clonedValue, quotation10: "no" })
                    totalCost -= 150
                } else {
                    totalCost += 150
                }
            }
            if (e === "Video bubble installation") {
                if (!checkedArr?.includes("Video bubble installation")) {
                    // setMaintainValues({ ...maintainValues, quotation11: "no" })
                    totalCost -= 150
                } else {
                    totalCost += 150
                }
            }
            if (e === "Video bubble service") {
                if (!checkedArr?.includes("Video bubble service")) {
                    // setMaintainValues({ ...maintainValues, quotation11: "no" })
                    totalCost -= 150
                } else {
                    totalCost += 150
                }
            }
            setTotalQuotationAmount(totalCost?.toFixed(2))
            setFinalQuotationCost(totalCost?.toFixed(2))

        }
    }

    useEffect(() => {
        setOtherName(otherOption[mock?.name] || "")
        setCustomStyles(mock?.styles || {})
        if (mock?.type === "serviceList") {
            let options = []
            let selectedArr = []
            let totalCost = 0
            setCurrency("EUR")
            if (maintainValues["quotation1"] === "yes" && quotation['quotation1']) {
                costFormatArr.map((cost) => {
                    let option = {
                        label: `${cost?.service} Services - ${cost?.totalCost}€`,
                        value: `${cost?.service} Services`,
                    }
                    options.push(option)
                    selectedArr.push(option?.value)
                    totalCost += Number(cost?.totalCost)
                })
            }
            if (maintainValues["quotation2"] === "yes" && quotation['quotation2']) {
                let option = {
                    label: `Number Validation Services - ${quotation['quotation2']}€`,
                    value: "Validation Services",
                }
                totalCost += Number(quotation['quotation2'])
                options.push(option)
                selectedArr.push(option?.value)
            }
            if (maintainValues["quotation3"] === "yes") {
                let option = {
                    label: `Form Service - 0€`,
                    value: "Forms",
                }
                options.push(option)
                selectedArr.push(option?.value)
            }
            if (maintainValues["quotation4"] === "yes") {
                let option = {
                    label: "eCommerce plugin - 60€",
                    value: "E commerce plugin",
                }
                options.push(option)
                selectedArr.push(option?.value)
                totalCost += 60
            }
            if (maintainValues["quotation5"] === "yes") {
                let option = {
                    label: "Landing page builder for No-website - 0€",
                    value: "Landing page builder for No-website",
                }
                options.push(option)
                selectedArr.push(option?.value)
            }
            if (maintainValues["quotation6"] === "yes") {
                let option = {
                    label: "Landing page builder for website - 0€",
                    value: "Landing page builder for website",
                }
                options.push(option)
                selectedArr.push(option?.value)
            }
            if (maintainValues["quotation7"] === "yes" && quotation['quotation7']) {
                let quotation7Arr = []
                let selArr = []
                if (maintainValues['modulesName']?.includes("Event Management")) {
                    let option = {
                        label: `Event Management - 30€`,
                        value: "Event Management",
                    }
                    totalCost += 30
                    quotation7Arr.push(option)
                    selArr.push(option.value)
                }
                if (maintainValues['modulesName']?.includes("SEO Report")) {
                    let option = {
                        label: `SEO Report - 150€`,
                        value: "SEO Report",
                    }
                    totalCost += 150
                    quotation7Arr.push(option)
                    selArr.push(option.value)
                }
                if (maintainValues['modulesName']?.includes("SocialCampaignAI") && maintainValues['currentCampaignsCount']) {
                    let option = {
                        label: `SocialCampaignAI - 50€ * ${maintainValues['currentCampaignsCount']} = ${50 * Number(maintainValues['currentCampaignsCount'])}€`,
                        value: "SocialCampaignAI",
                    }
                    totalCost += (50 * Number(maintainValues['currentCampaignsCount']))
                    quotation7Arr.push(option)
                    selArr.push(option.value)
                }
                if (maintainValues['modulesName']?.includes("roas_optimizer") && maintainValues['currentCampaignsCount']) {
                    let option = {
                        label: `ROAS Optimizer - 10€ * ${maintainValues['currentCampaignsCount']} = ${10 * Number(maintainValues['currentCampaignsCount'])}€`,
                        value: "ROAS Optimizer",
                    }
                    quotation7Arr.push(option)
                    selArr.push(option.value)
                }
                totalCost += 10 * Number(maintainValues['currentCampaignsCount'])
                options.push(...quotation7Arr)
                selectedArr.push(...selArr)
            }
            if (maintainValues["quotation8"] === "yes") {
                let option = {
                    label: "Appointment service - 9€",
                    value: "Appointment service",
                }
                options.push(option)
                selectedArr.push(option?.value)
                totalCost += 9
            }
            if (maintainValues["quotation9"] === "yes" && quotation['quotation9']) {
                let option = {
                    label: `B2B leads service 0.1€ * ${maintainValues['prospectCustomersCount']} = ${Number(quotation['quotation9'])}€`,
                    value: "B2B leads service",
                }
                options.push(option)
                selectedArr.push(option?.value)
                totalCost += Number(quotation['quotation9'])
            }
            if (maintainValues["quotation10"] === "yes") {
                let option = [{
                    label: "B2C leads Installation - 150€",
                    value: "B2C leads installation",
                },
                {
                    label: "B2C leads service - 150€",
                    value: "B2C leads service",
                }]
                let selectedVal = ["B2C leads installation", "B2C leads service"]
                options.push(...option)
                selectedArr.push(...selectedVal)
                totalCost += 300
            }
            if (maintainValues["quotation11"] === "yes") {
                let option = [{
                    label: "Video bubble Installation - 150€",
                    value: "Video bubble installation",
                },
                {
                    label: "Video bubble service - 150€",
                    value: "Video bubble service",
                }]
                let selectedVal = ["Video bubble service", "Video bubble installation"]
                options.push(...option)
                selectedArr.push(...selectedVal)
                totalCost += 300
            }
            setTotalQuotationAmount(totalCost?.toFixed(2))
            mock['options'] = options

            setMaintainValues({ ...maintainValues, [`${name}`]: selectedArr })
        }
    }, [])

    useEffect(() => {
        otherOption[mock?.name] = otherName
        setOtherOption({ ...otherOption })
    }, [otherName])

    return (
        <div onClick={() => (index)} className={mock?.type === "serviceList" ? "serviceList-container" : 'checkbox-container'} onMouseEnter={mock?.type === "serviceList" ? setScrollDisable(true) : () => null} onMouseLeave={mock?.type === "serviceList" ? setScrollDisable(false) : () => null}>
            <div className='checkbox-title'>
                <h1 className='title'><span>{index}
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                        <path
                            d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                    </svg>
                </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /> {mock?.type === "serviceList" ? `(${totalQuotationAmount}€)` : ""}</h1>
                <div>
                    <p>{mock?.description}</p>
                </div>
                {/* <h1 className='title'><span>{index}<img src={rightIcon} /></span>{mock?.title}</h1> */}
            </div>
            <div className='checkbox-button-container'>
                <div className='select-many'>{mock?.type === "serviceList" ? "Unselect if you don't want any of the service" : "Choose as many as you like"}</div>
                {mock?.options?.map((moc, i) =>
                    <div className='checkbox-button' key={moc?.value + i}>
                        <SelectButton hasQuantity={hasQuantity} country={maintainValues?.country} label={moc?.label} value={moc?.value} multiSelect={true} handleCheckbox={(e) => handleChecked(e)} trueValue={maintainValues[name]} mock={mock} index={i} optionImg={moc?.img ?? ""} />
                    </div>
                )}
                {mock?.isOtherEnabled && <div className="checkbox-button" ><SelectButton hasQuantity={hasQuantity} setCheckedOther={setCheckedOther} maintainValues={maintainValues} label={otherName || 'Other'} value={otherName || 'Other'} otherName={otherName} setOtherName={setOtherName} country={maintainValues?.country} multiSelect={true} handleCheckbox={(e) => handleChecked(e)} trueValue={maintainValues[name]} mock={mock} index={mock?.options?.length} isOther={true} /></div>}
            </div>
        </div>
    )
}

export default CheckBox