import React from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useQuantity } from "../../appContext/QuantityContext";
import "../Select-button/style.scss";

const ButtonsGroupContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 10px;
  color: black;
  display: flex;
  font-family: Poppins;
  font-size: 14px;
  height: 30px;
  margin-left: 10px;
  width: 90px;
  z-index: 15;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px;
`;

export default function QuantityComponent({ isOther, option, question }) {
  const { getQuantity, setQuantity } = useQuantity();

  const customOption = React.useMemo(() => {
    return isOther ? "Other" : option;
  }, [isOther, option]);

  const value = React.useMemo(() => {
    return getQuantity(question, customOption);
  }, [question, customOption, getQuantity]);

  return (
    <ButtonsGroupContainer>
      <ButtonContainer>
        <IconButton
          className="quantity-buttons"
          disableRipple
          disabled={value === 0}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setQuantity(question, customOption, false);
          }}
        >
          <ChevronLeftIcon
            className={
              value === 0 ? "quantity-icons-disabled" : "quantity-icons"
            }
          />
        </IconButton>
      </ButtonContainer>
      <ButtonContainer>{value}</ButtonContainer>
      <ButtonContainer>
        <IconButton
          className="quantity-buttons"
          disableRipple
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setQuantity(question, customOption, true);
          }}
        >
          <ChevronRightIcon className="quantity-icons" />
        </IconButton>
      </ButtonContainer>
    </ButtonsGroupContainer>
  );
}
