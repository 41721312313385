import { Autocomplete, TextField } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import './style.scss'
import Paper from "@material-ui/core/Paper";
import { titleFromParams } from '../../Utilities/helperFunction';
import appContext from '../../appContext';
import { CustomPaper } from '../../Utilities/Constant';


function AutoComplete({ setScrollDisable, handleOk, isCountry, mock, index, rightIcon, initialValues, setValue, maintainValues, setMaintainValues, countryArray, country, foundCountry, setCountry }) {

    const { setCustomStyles } = useContext(appContext)

    let name = ''
    if (initialValues && initialValues[index - 1]) {
        name = initialValues[index - 1]
    }

    const getDefaultValue = () => {
        let defaultCountry = {}
        if (maintainValues[name]) {
            if (isCountry) {
                defaultCountry = countryArray && countryArray?.find((countryArr) => {
                    return countryArr?.label === maintainValues[name]
                })
            } else {
                defaultCountry = mock?.options?.find((moc) => moc?.label === maintainValues[name])
            }
        } else {
            if (isCountry && country) {
                defaultCountry = countryArray && countryArray?.find((countryArr) => {
                    return countryArr?.value === country
                })
            }
        }
        setMaintainValues({ ...maintainValues, [`${name}`]: defaultCountry ? defaultCountry?.label : "" })
        return defaultCountry
    }

    useEffect(() => {
        setCustomStyles(mock?.styles || {})
    }, [])

    return (
        <div className='dropdown-container' onClick={() => (index)}>
            <div className='dropdown-title' >
                <h1 className='title'><span>{index}
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                        <path
                            d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                    </svg>

                </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /></h1>
                <div>
                    <p>{mock?.description}</p>
                </div>
                {/* <h1 className='title'>{mock.title}</h1> */}
            </div>
            <Autocomplete
                options={isCountry ? countryArray : mock?.options}
                getOptionLabel={(option) => option?.label || ""}
                onChange={(event, newValue) => {
                    setValue(initialValues[index - 1], newValue?.value);
                    setMaintainValues({ ...maintainValues, [`${name}`]: isCountry ? newValue?.label : newValue?.value })
                    //need for future usage
                    // if (isCountry && newValue?.value) {
                    //     setCountry(newValue?.value)
                    //      handleOk(mock, name, true);
                    // }
                    setScrollDisable(false)
                }}
                renderInput={(params) => (
                    <TextField {...params} placeholder="Type or select an option" variant="standard" />
                )}
                defaultValue={getDefaultValue}
                getOptionSelected={(option, value) => {
                    return option.label === value.label;
                }}
                PaperComponent={CustomPaper}
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                onMouseEnter={() => setScrollDisable(true)}
                onMouseLeave={() => setScrollDisable(false)}
            />
        </div>
    )
}

export default AutoComplete