import React, { useContext, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { paramsToObject, titleFromParams } from '../../Utilities/helperFunction';
import './style.scss'
import appContext from '../../appContext';

function PhoneNumber({ setScrollDisable, mock, index, rightIcon, setValue, maintainValues, setMaintainValues, initialValues, countryCode }) {

    const { setCustomStyles } = useContext(appContext)

    useEffect(() => {
        setCustomStyles(mock?.styles || {})
        const inputVal = paramsToObject()
        if (inputVal) {
            mock?.options?.map((x) => {
                if (inputVal[x?.name] && !maintainValues[x?.name]) {
                    maintainValues[x?.name] = inputVal[x?.name];
                }
            })
            setMaintainValues({ ...maintainValues })
        }
    }, [])

    return (
        <div className='phone-container'>
            <div className='input-title'>
                <h1 className='title'><span>{index}
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 24 24" >
                        <path
                            d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
                    </svg>
                </span><div dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }} /></h1>
                <div>
                    <p>{mock?.description}</p>
                </div>
            </div>
            {
                mock.options.map((moc, i) => {
                    let name = ''
                    if (initialValues && initialValues[index - 1] && initialValues[index - 1]?.length) {
                        name = moc?.name
                    }
                    return <div className='input-container' onMouseEnter={() => setScrollDisable(true)} onMouseLeave={() => setScrollDisable(false)}>
                        <PhoneInput
                            country={countryCode ? countryCode.toLowerCase() : "us"}
                            placeholder={moc?.placeholder}
                            inputProps={{ name: name, required: true, autoFocus: true }}
                            onChange={(e) => { setValue(name, e); setMaintainValues({ ...maintainValues, [`${name}`]: e }); setScrollDisable(false) }}
                            value={maintainValues[name]}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                        />
                    </div>
                })}
        </div>
    )
}

export default PhoneNumber