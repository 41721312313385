/* eslint-disable no-unused-expressions */
import { Convert } from "easy-currencies"
import { currency_symbols } from "./Constant";
import smsIcon from "../Assets/socialIcons/sms.svg";
import emailIcon from "../Assets/socialIcons/email.svg";
import pushNotificationIcon from "../Assets/socialIcons/pushNotification.svg";
import messengerIcon from "../Assets/socialIcons/messenger.svg";
import viberIcon from "../Assets/socialIcons/viber.svg";
import whatsappIcon from "../Assets/socialIcons/whatsapp.svg";
import telegramIcon from "../Assets/socialIcons/telegram.svg";
import voiceIcon from "../Assets/socialIcons/voice.svg";

export const changeTitle = (title, values) => {
    if (title.includes('${')) {
        let newTitle = title;
        const startIndex = newTitle.indexOf("${");
        const endIndex = newTitle.indexOf("}", startIndex + 2);
        let replaceSubString = newTitle.substring(startIndex, endIndex + 1);
        const value = newTitle.substring(startIndex + 2, endIndex);
        if (values && replaceSubString && values[value]) {
            newTitle = newTitle.replace(replaceSubString, values[value]);
        } else {
            newTitle = newTitle.replace(replaceSubString, '_________');
        }
        return newTitle;
    } else {
        return title;
    }
}

export const getCurrencyFromEuro = async (fromCurrency, toCurrency, amount) => {
    return await Convert(amount).from(fromCurrency).to(toCurrency)
}

export const getMinusPercent = (totalCost) => {
    let minusPercent = (totalCost * 47.8 / 100)
    minusPercent = totalCost - minusPercent
    minusPercent = (Math.round(minusPercent * 100) / 100).toFixed(2)
    return minusPercent
}

export const getSmsFormat = (pricePerItem, number, title, service, currency) => {
    let totalCost = pricePerItem * number;
    totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "")
    const titleValue = {
        service: "SMS",
        count: number,
        pricePerItem: pricePerItem?.toFixed(4),
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: smsIcon,
        footName: "SMS"
    }
    return titleValue;
}

export const getSmsService = (maintainValues, smsService, title, number, service, currency) => {
    let countArray = smsService.filter((service) => service?.name?.trim()?.toLowerCase() === 'For Total Traffic Amount'.toLowerCase());
    countArray = countArray && countArray[0]?.rowElements;
    let priceArray = smsService.filter((service) => service?.name?.trim()?.toLowerCase() === 'Your price will be'.toLowerCase());
    priceArray = priceArray && priceArray[0]?.rowElements;
    let findIndex = null;

    for (const [index, count] of countArray?.entries()) {
        if (Number(maintainValues["smsCount"]) <= Number(count?.name)) {
            findIndex = index;
            break;
        }
    }
    if (!findIndex && countArray?.length) {
        if (Number(maintainValues["smsCount"]) > Number(countArray[countArray?.length - 1]?.name)) {
            findIndex = countArray?.length - 1;
        }
    }
    const pricePerItem = priceArray?.length && Number(priceArray[findIndex]?.price);
    return getSmsFormat(pricePerItem, number, title, service, currency)
}

export const getEmailService = (emailService, number, service, currency, isDontknow) => {

    let subscribersArr = []

    if (isDontknow) {

        emailService?.length && emailService[0] && emailService[0]?.rowElements?.map((serv) => {
            subscribersArr.push(Number(serv?.name))
        })

    } else {

        emailService?.length && emailService[0] && emailService[0]?.rowElements?.map((serv) => {
            subscribersArr.push(Number(serv?.name?.split("-")[1]))
        })
    }
    let subIndex = null
    for (const [index, sub] of subscribersArr?.entries()) {
        if (number <= sub) {
            subIndex = index;
            break
        }
    }
    if (!subIndex && subscribersArr?.length) {
        if (number > subscribersArr[subscribersArr?.length - 1]) {
            subIndex = subscribersArr?.length - 1;
        }
    }
    let totalCost = emailService && emailService.length && emailService[1]?.rowElements[subIndex]?.price;
    totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "")
    const titleValue = {
        service: "Email",
        count: number,
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: emailIcon,
        footName: "Emails"
    }
    return titleValue
}

export const getPushService = (pushService, number, service, currency) => {

    let pricePerThousand = pushService && pushService.length && pushService[0]?.price
    pricePerThousand = Number(pricePerThousand)
    let totalCost = 0
    // let minusPercent = 0
    if (number < 1000) {
        totalCost = 0
    } else {
        let count = parseInt(number / 1000)
        totalCost = count * pricePerThousand
        totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "")

    }
    const titleValue = {
        service: "Push notification",
        count: number,
        pricePerThousand: pricePerThousand?.toFixed(4),
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: pushNotificationIcon,
        footName: "Notifications"
    }

    return titleValue

}

export const getFacebookMessengerService = (facebookService, number, service, currency) => {
    let subscribersArr = []
    let subIndex = null

    facebookService?.length && facebookService[0] && facebookService[0]?.rowElements?.map((serv) => {
        subscribersArr.push(Number(serv?.name))
    })
    for (const [index, subs] of subscribersArr.entries()) {
        if (number <= subs) {
            subIndex = index
            break;
        }
    }
    if (!subIndex && subscribersArr?.length) {
        if (number > subscribersArr[subscribersArr?.length - 1]) {
            subIndex = subscribersArr?.length - 1;
        }
    }
    let totalCost = facebookService && facebookService.length && facebookService[1]?.rowElements[subIndex]?.price;
    totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "")

    const titleValue = {
        service: "Facebook Messenger",
        count: number,
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: messengerIcon,
        footName: "Messages"
    }

    return titleValue;
}

export const getViberService = (viberService, number, service, currency) => {
    let pricePerItem = viberService?.length && viberService[0]?.price
    let totalCost = number * Number(pricePerItem)
    totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "")

    const titleValue = {
        service: "Viber",
        count: number,
        pricePerItem: Number(pricePerItem)?.toFixed(4),
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: viberIcon,
        footName: "Messages"
    }


    return titleValue
}

export const getWhatsappService = async (number, service, currency) => {
    let pricePerItem = await getCurrencyFromEuro("EUR", currency, 0.004)
    let totalCost = number * Number(pricePerItem)
    totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "");

    const titleValue = {
        service: "WhatsApp",
        count: number,
        pricePerItem: Number(pricePerItem)?.toFixed(4),
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: whatsappIcon,
        footName: "Messages"
    }

    return titleValue
}

export const getTelegramService = (telegramService, number, service, currency) => {
    let pricePerItem = telegramService?.length && telegramService[0]?.price
    let totalCost = number * Number(pricePerItem)
    totalCost = (Math.round(totalCost * 100) / 100).toFixed(2)?.replace(/\.00$/, "");

    const titleValue = {
        service: "Telegram",
        count: number,
        pricePerItem: Number(pricePerItem)?.toFixed(4),
        totalCost: totalCost,
        symbol: currency_symbols(currency),
        icon: telegramIcon,
        footName: "Messages"
    }
    return titleValue;


}

export const getVoiceService = (voiceService, number, service, currency, maintainValues) => {
    let pricePerItemForMobile = voiceService?.length && voiceService[1]?.rowElements
    pricePerItemForMobile = voiceService?.length && pricePerItemForMobile[0].price;
    let pricePerItemForLandline = voiceService?.length && voiceService[2]?.rowElements
    pricePerItemForLandline = voiceService?.length && pricePerItemForLandline[0].price;
    let totalCostMobile = number * Number(pricePerItemForMobile)
    totalCostMobile = (Math.round(totalCostMobile * 100) / 100).toFixed(2)?.replace(/\.00$/, "")

    let totalCostLandline = number * Number(pricePerItemForLandline)
    totalCostLandline = (Math.round(totalCostLandline * 100) / 100).toFixed(2)?.replace(/\.00$/, "")


    let newTitle = []
    if (maintainValues?.voiceSelection?.includes(`landline_number`)) {
        const titleValue = {
            service: "Voice for Landline",
            count: number,
            pricePerItem: Number(pricePerItemForLandline)?.toFixed(4),
            totalCost: totalCostLandline,
            symbol: currency_symbols(currency),
            icon: voiceIcon,
            footName: "Messages"
        }
        newTitle.push(titleValue)
    }
    if (maintainValues?.voiceSelection?.includes(`mobile_number`)) {
        const titleValue = {
            service: "Voice for Mobile",
            count: number,
            pricePerItem: Number(pricePerItemForMobile)?.toFixed(4),
            totalCost: totalCostMobile,
            symbol: currency_symbols(currency),
            icon: voiceIcon,
            footName: "Messages"
        }
        newTitle.push(titleValue)
    }

    return newTitle;


}

export const ModifyTitle = (title, symbol, finalTotalCost) => {
    let minusPercent = getMinusPercent(Number(finalTotalCost))
    let newSubString = `<div class="minus-container"></span></div>`
    title = title.replace('[amount]', newSubString)

    return title

}

export const Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    encode: function (r) {
        var t,
            e,
            o,
            a,
            h,
            n,
            c,
            d = "",
            C = 0;
        for (r = Base64._utf8_encode(r); C < r.length;)
            (a = (t = r.charCodeAt(C++)) >> 2),
                (h = ((3 & t) << 4) | ((e = r.charCodeAt(C++)) >> 4)),
                (n = ((15 & e) << 2) | ((o = r.charCodeAt(C++)) >> 6)),
                (c = 63 & o),
                isNaN(e) ? (n = c = 64) : isNaN(o) && (c = 64),
                (d = d + this._keyStr.charAt(a) + this._keyStr.charAt(h) + this._keyStr.charAt(n) + this._keyStr.charAt(c));
        return d;
    },
    decode: function (r) {
        var t,
            e,
            o,
            a,
            h,
            n,
            c = "",
            d = 0;
        for (r = r.replace(/[^A-Za-z0-9\+\/\=]/g, ""); d < r.length;)
            (t = (this._keyStr.indexOf(r.charAt(d++)) << 2) | ((a = this._keyStr.indexOf(r.charAt(d++))) >> 4)),
                (e = ((15 & a) << 4) | ((h = this._keyStr.indexOf(r.charAt(d++))) >> 2)),
                (o = ((3 & h) << 6) | (n = this._keyStr.indexOf(r.charAt(d++)))),
                (c += String.fromCharCode(t)),
                64 != h && (c += String.fromCharCode(e)),
                64 != n && (c += String.fromCharCode(o));
        return (c = Base64._utf8_decode(c));
    },
    _utf8_encode: function (r) {
        r = r.replace(/\r\n/g, "\n");
        for (var t = "", e = 0; e < r.length; e++) {
            var o = r.charCodeAt(e);
            o < 128
                ? (t += String.fromCharCode(o))
                : o > 127 && o < 2048
                    ? ((t += String.fromCharCode((o >> 6) | 192)), (t += String.fromCharCode((63 & o) | 128)))
                    : ((t += String.fromCharCode((o >> 12) | 224)), (t += String.fromCharCode(((o >> 6) & 63) | 128)), (t += String.fromCharCode((63 & o) | 128)));
        }
        return t;
    },
    _utf8_decode: function (r) {
        var c1, c2, c3;
        for (var t = "", e = 0, o = (c1 = c2 = 0); e < r.length;)
            (o = r.charCodeAt(e)) < 128
                ? ((t += String.fromCharCode(o)), e++)
                : o > 191 && o < 224
                    ? ((c2 = r.charCodeAt(e + 1)), (t += String.fromCharCode(((31 & o) << 6) | (63 & c2))), (e += 2))
                    : ((c2 = r.charCodeAt(e + 1)), (c3 = r.charCodeAt(e + 2)), (t += String.fromCharCode(((15 & o) << 12) | ((63 & c2) << 6) | (63 & c3))), (e += 3));
        return t;
    },
};

export const paramsToObject = () => {
    try {
        var search = window.location.search.substring(1);
        if (search) {
            return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
        } else {
            return
        }
    } catch (e) {
        return
    }
}

export const titleFromParams = (question) => {
    if (question) {
        let questionCopy = question
        let urlParams = paramsToObject()
        if (urlParams) {
            if (Object.keys(urlParams)?.length) {
                Object.keys(urlParams).forEach((params) => {
                    if (questionCopy.includes(`[${params}]`)) {
                        questionCopy = questionCopy.replace(`[${params}]`, urlParams[params])
                    }
                })
                return questionCopy
            } else {
                return questionCopy
            }
        } else return questionCopy
    }
}

export const getParamsValue = (name) => {
    const params = new URL(window.location.href).searchParams;
    return params?.get(name, 'input-html')
}

export const updateDuplicateValues = (arr) => {
    const optionNameCounts = {};

    for (const obj of arr) {
        if (obj.options && Array.isArray(obj.options)) {
            const processedNames = new Set();
            for (const option of obj.options) {
                const optionName = option.name;
                if (optionName && !processedNames.has(optionName)) {
                    if (optionNameCounts[optionName] === undefined) {
                        optionNameCounts[optionName] = 1;
                    } else {
                        optionNameCounts[optionName]++;
                    }
                    processedNames.add(optionName);
                }
            }
        }
    }

    for (const obj of arr) {
        if (obj.options && Array.isArray(obj.options)) {
            for (const option of obj.options) {
                const optionName = option.name;
                if (optionName) {
                    if (optionNameCounts[optionName] === undefined) {
                        optionNameCounts[optionName] = 1;
                    } else {
                        optionNameCounts[optionName]++;
                        option.name = `${optionName}_${optionNameCounts[optionName]}`;
                    }
                }
            }
        }
    }

    return arr;
}

export const transformObjectToArray = (obj) => {
    const result = [];
    Object.values(obj).forEach((arr) => {
        arr.forEach((val) => {
            result.push(val);
        });
    });
    return result;
};

export const findNextLoopNumber = (arr) => {
    let maxNumber = -1;

    arr.forEach((str) => {
        const match = str.match(/_loop(\d+)/);
        if (match) {
            const number = parseInt(match[1], 10);
            if (!isNaN(number) && number > maxNumber) {
                maxNumber = number;
            }
        }
    });

    // Increment the maxNumber to get the next number in the sequence
    const nextNumber = maxNumber + 1;

    // Create the string with "_loop" and the next number
    const result = `_loop${nextNumber}`;

    return result;
}

export const getUpdatedUrl = (url) => {
    if (url.includes('[') && url.includes(']')) {
        const regex = /\[(.*?)\]/g;
        let match;
        let updatedUrl = url
        while ((match = regex.exec(updatedUrl)) !== null) {
            const params = new URL(window.location.href).searchParams;
            updatedUrl = updatedUrl.replace(`[${match[1]}]`, params.get(match[1]))
        }
        return updatedUrl
    } else {
        return url;
    }
}


export const convertNumberToArray = (min, max) => {
    return Array.from({ length: max - min + 1 }, (v, k) => k + min);
}

export const debounce = (cb, delay) => {
    let timeoutId;
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            cb(...args);
        }, delay);
    };
}

export const getVariables = (title) => {
    const regex = /\[@([^\]]+)\]/g;
    const variables = [];
    let match;
    // Use a while loop to find all matches
    while ((match = regex.exec(title)) !== null) {
        variables.push(match[1]);
    }
    return variables;
}
