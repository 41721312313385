import React, { useContext, useEffect } from "react";
import { titleFromParams } from "../../Utilities/helperFunction";
import appContext from "../../appContext";
import "./style.scss";

const OpenProject = ({
  mock,
  index,
  setValue,
  setMaintainValues,
  maintainValues,
}) => {
  const { setCustomStyles, openprojectDetails } = useContext(appContext);

  useEffect(() => {
    if (Object.keys(openprojectDetails || {}).length) {
      setValue(mock?.name, openprojectDetails);
      setMaintainValues({
        ...maintainValues,
        [`${mock?.name}`]: openprojectDetails,
      });
    }
  }, [openprojectDetails]);

  useEffect(() => {
    setCustomStyles(mock?.styles || {});
  }, []);

  return (
    <div className="open-project-container">
      <div className="open-project-title">
        <h1 className="title">
          <span>
            {index}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
            </svg>
          </span>
          <div
            dangerouslySetInnerHTML={{ __html: titleFromParams(mock?.title) }}
          />
        </h1>
        <div>
          <p>{mock?.description}</p>
        </div>
      </div>
      <iframe
        title="Executive summary Open project Login"
        frameborder="0"
        height="50px"
        src="https://open-project-login.demo.connect.waymore.io"
      ></iframe>
    </div>
  );
};

export default OpenProject;
