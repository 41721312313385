import React from "react";
import styled from "@emotion/styled";
import TimerIcon from "@mui/icons-material/Timer";

const TimerContainer = styled.div`
  align-items: center;
  background-color: #ff323280;
  border-radius: 5px;
  display: flex;
  font-size: 18px;
  justify-content: center;
  padding: 5px;
  width: 130px;
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  width: 35px;
`;

const TextContainer = styled.div`
  width: calc(100% - 35px);
`;

let interval = null;

export default function CustomTimer({
  isTimeLimitEnabled,
  timeCompletedCallBack,
  timeLimit,
}) {
  const [hours, setHours] = React.useState(null);
  const [minutes, setMinutes] = React.useState(null);
  const [seconds, setSeconds] = React.useState(null);
  const [timeLeft, setTimeLeft] = React.useState(null);

  React.useEffect(() => {
    if (isTimeLimitEnabled) {
      const timeLimitCount = timeLimit.includes("m")
        ? Number(timeLimit.split("m")[0])
        : Number(timeLimit.split("s")[0]);

      const timeInMilliseconds = timeLimit.includes("m")
        ? timeLimitCount * 60000
        : timeLimitCount * 1000;

      setTimeLeft(timeInMilliseconds);
    }
  }, [isTimeLimitEnabled, timeLimit]);

  React.useEffect(() => {
    if (isTimeLimitEnabled && timeLeft >= 0) {
      interval = setInterval(() => {
        const hoursLeft = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
        const minutesLeft = Math.floor((timeLeft / 1000 / 60) % 60);
        const secondsLeft = Math.floor((timeLeft / 1000) % 60);

        setHours(hoursLeft);
        setMinutes(minutesLeft);
        setSeconds(secondsLeft);
        setTimeLeft(timeLeft - 1000);

        if (timeLeft - 1000 === 0) {
          timeCompletedCallBack();
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeLeft, isTimeLimitEnabled, timeCompletedCallBack]);

  const timeString = React.useMemo(() => {
    if (hours === null || minutes === null || seconds === null) {
      return "";
    }

    const calculatedHours = hours >= 10 ? String(hours) : `0${hours}`;
    const calculatedMinutes = minutes >= 10 ? String(minutes) : `0${minutes}`;
    const calculatedSeconds = seconds >= 10 ? String(seconds) : `0${seconds}`;

    return `${calculatedHours} : ${calculatedMinutes} : ${calculatedSeconds}`;
  }, [hours, minutes, seconds]);

  return (
    <TimerContainer>
      <IconContainer>
        <TimerIcon />
      </IconContainer>
      <TextContainer>
        {timeString.length ? timeString : "loading..."}
      </TextContainer>
    </TimerContainer>
  );
}
