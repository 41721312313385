import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Payment from '../Payment';
import { useContext } from 'react';
import appContext from '../../appContext';
import { currency_symbols } from '../../Utilities/Constant';
import { useEffect } from 'react';
import './style.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function PaymentModal({ currency, setScrollDisable, goNext, goBack, mock }) {
    const { totalQuotationAmount, setCustomStyles } = useContext(appContext)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setCustomStyles(mock?.styles || {})
        handleOpen()
        setScrollDisable(true)
        return () => setScrollDisable(false)
    }, [])

    return (
        <div className='modal-container'>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="mui-modal"
            >
                <Box sx={style}>
                    <Payment paymentId={mock.paymentId} actualAmount={mock.amount || totalQuotationAmount} actualSymbol={currency_symbols(mock.currency || currency)} actualCurrency={mock.currency || currency} goNext={goNext} goBack={goBack} />
                </Box>
            </Modal>
        </div>
    );
}