import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './style.scss'
import { getParamsValue } from '../../Utilities/helperFunction';

function TextareaComponent({ setScrollDisable, name, maintainValues, setMaintainValues, setValue, placeholder }) {
    const [content, setContent] = useState("")
    useEffect(() => {
        if (!maintainValues[name]) {
            const inputVal = getParamsValue(name)
            setValue(name, inputVal);
            setMaintainValues({ ...maintainValues, [`${name}`]: inputVal })
        }
    }, [])

    const handleChange = (e) => {
        setContent(e.target.value)
        setValue(name, e?.target?.value);
        setMaintainValues({ ...maintainValues, [`${name}`]: e?.target?.value })
    }
    return (
        <div className='textarea-container'
            onMouseEnter={() => setScrollDisable(true)}
            onMouseLeave={() => setScrollDisable(false)}>
            <TextField
                placeholder={placeholder}
                InputLabelProps={{ shrink: false }}
                multiline
                minRows={4}
                variant="outlined"
                onChange={handleChange}
                value={maintainValues[name]}
            />
        </div>
    )
}

export default TextareaComponent