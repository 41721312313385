import React from "react";
import appContext from "../../appContext";
import clockIcon from "../../Assets/clock-icon.png";

import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { TimeEstimateContainer, TimeTakenContainer } from "./Styled";
import "./style.scss";

function LandingPage() {
  const navigate = useNavigate();
  const { allContentData, landingPage, validId, timeEstimate } =
    useContext(appContext);

  useEffect(() => {
    if (landingPage !== null && !landingPage) {
      navigate(`/form${window.location.search}`);
    } else if (!validId) {
      navigate("/notfound");
    }
  }, [landingPage, navigate, validId]);

  const getTimePhrase = (count, phrase) => {
    if (count === 0) {
      return "";
    } else if (count === 1) {
      return `${count} ${phrase}`;
    } else {
      return `${count} ${phrase}s`;
    }
  };

  const getApproxTime = useCallback(() => {
    const hours = Math.floor(timeEstimate / 3600);
    const minutes = Math.floor((timeEstimate % 3600) / 60);
    const seconds = timeEstimate % 60;

    if (hours) {
      const exactHours = getTimePhrase(hours, "hour");
      const exactMinutes = getTimePhrase(minutes, "minute");
      const exactSeconds = getTimePhrase(seconds, "second");

      return `${exactHours} ${exactMinutes} ${exactSeconds}`;
    }

    if (minutes) {
      const exactMinutes = getTimePhrase(minutes, "minute");
      const exactSeconds = getTimePhrase(seconds, "second");

      return `${exactMinutes} ${exactSeconds}`;
    }

    return getTimePhrase(seconds, "second");
  }, [timeEstimate]);

  const renderShowTimeComponent = useMemo(() => {
    if (timeEstimate) {
      return (
        <TimeEstimateContainer>
          <img src={clockIcon} alt="clock icon" />
          <TimeTakenContainer>{`Takes ${getApproxTime()}`}</TimeTakenContainer>
        </TimeEstimateContainer>
      );
    }

    return null;
  }, [timeEstimate, getApproxTime]);

  const renderHtmlOrCustomComponent = useMemo(() => {
    if (allContentData?.style?.landingPageContentType === "html") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: allContentData?.formContent || "",
          }}
        />
      );
    }

    if (allContentData?.style?.landingPageContentType === "custom") {
      return (
        <div className="landing-page-custom-text-container">
          {allContentData?.formContent}
        </div>
      );
    }

    return null;
  }, [allContentData]);

  const getIsHtmlFromTemplate = () => {
    return allContentData?.formContent?.includes("DOCTYPE");
  };

  const onStartClick = () => {
    navigate(`/form${window.location.search}`);
  };

  return (
    <React.Fragment>
      <div className="landing-container">
        {renderHtmlOrCustomComponent}
        <div
          className="time-and-start-button"
          style={{
            backgroundColor: getIsHtmlFromTemplate()
              ? allContentData?.style?.landingPageContainerContentColour
              : "unset",
          }}
        >
          {renderShowTimeComponent}
          <button
            className="open-form-button button_styles"
            onClick={onStartClick}
          >
            {allContentData?.style?.landingPageButtonText}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LandingPage;
